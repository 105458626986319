
.btns-boxL{
    align-content: center;
    margin: 1% 15%;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    margin-left: 12%;
    padding: 1% 4%;
    padding-top: 2%;
    box-shadow: 1px 2px 4px 1px grey;
    border-radius: 2px;
}
.btn-sub-boxL:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.language-label{
    position: relative;
    font-style: bold;
    /* font-size:200px; */
    margin-top: 5%;
    width: 65%;
    text-align: center;
    padding: 20px 10px 10px 10px;
    margin-left: 17%;
}

.pan-flag{
    /* position: absolute; */
    /* width: 15%; */
    height: 20vh;
    top: 12%;
    left: -6%;
}

#btn-boxL > button{
    background: #ef4343;
    color: #fff;
    width: 85%;
    font-size: 100%;
    margin: 10px;
    padding: 3px 15px;
    border-radius: 25px;

}   
.btn-booksL{
    margin: 2% 15%;
    padding: 4%;
    margin-left: 12%;
    box-shadow: 1px 2px 6px 1px grey;
    border-radius: 2px;
}


/* media Queries */
@media screen and (max-width:1080px){
    .btns-boxL{
        align-content: center;
        margin: 2% 1%;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin-left: 2%;
        padding-top: 5%;
    }

    .language-label{
        font-style: bold;
        margin-top: 1%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 15%;
    }
    #pan-flag{
        position: absolute;
        width: 25%;
        height: 25%;
        top: 20%;
        left: 3%;
    }
 
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 150px;
        font-size: 20px;
        margin:20px 0px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   

    #filter-label{
        position: absolute;
        left: 5%;
        top: 30%;
    } 
    .btn-booksL{
        margin: 6% 1%;
        padding: 4%;
        margin-left: 4%;
        box-shadow: 1px grey;
    
    }
    .changeColor{
        margin-top: 5%;
    }
}
@media screen and (max-width:720px){
    .btns-boxL{
        align-content: center;
        margin: 2% 10%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-left: 5%;
    }

    .language-label{
        font-style: bold;
        margin-top: 10%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 20%;
    }
    #pan-flag{
       position: absolute;
        left: 30%;
        top: 10%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
    .btn-sub-boxL{
        display: grid;
        position: relative;
        width: 30%;
        padding: 20px;
    }
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 130px;
        font-size: 20px;
        margin:20px 10px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   
    #filter-label{
        position: absolute;
        left: 14%;
        top: 24%;
    } 
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
}
@media screen and (max-width:650px){
    .btns-boxL{
        align-content: center;
        margin: 2% 10%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-left: 4%;
        padding: 5% 10%;
        box-shadow: 1px grey;
    }
  
    .language-label{
        font-style: bold;
        margin-top: 35%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 15%;
    }
    #pan-flag{
        position: absolute;
        left: 30%;
        top: 1%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
  
    #btn-boxL > button{
        background: #ef4343;
        color: #fff;
        width: 130px;
        font-size: 20px;
        margin:20px 10px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   

    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
}
@media screen and (max-width:480px){
    .btns-boxL{
        align-content: center;
        margin: 5% 15%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-left: 5%;
        padding: 4%;
        border-bottom: 1px grey;
        box-shadow: none;
    }

    .language-label{
        font-style: bold;
        margin-top: 15%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 12%;
    }
    .pan-flag{
        position: absolute;
        left: 30%;
        top: -15%;
        width: 35%;
        height: 60%;
        margin: 3%;

    }
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 130px;
        font-size: 18px;
        margin: 10px 20px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }
    #filter-label{
        position: absolute;
        left: 18%;
        top: 25%;
    } 
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }   
    .changeColor{
        font-size: 20px;
        margin-top: 25%;
        margin-bottom: -10%;
    }
}

@media screen and (max-width:360px) {
    .btns-boxL{
        text-align: center;
        margin: 0px 30px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding: 0px 100px ;
        padding-left: 0px;
        box-shadow: none;

    }
    #language-label{
        font-style: bold;
        margin-top: 20%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 12%;
    }
    #pan-flag{
        position: absolute;
        left: 30%;
        top: -10%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
  
    #btn-boxL > button{
        background: #ef4343;
        color: #fff;
        width: 125px;
        font-size: 16px;
        margin: 10px 5px;
        padding: 5px 5px;
        border-radius: 25px;
    
    }
    #filter-label{
        position: absolute;
        left: 14%;
        top: 25%;
    }   
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
    
}
