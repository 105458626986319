
.nav {
  transition: all 0.1s linear;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top:0;
  z-index: 2000;
  box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
  width: 100%;
}
.showMobile{
  display: none;
}

.Bsize{
 width: 150px;
 height: 35px;

}
.Fsize{
  font-size: 15px;
  margin-top: -15px;
}
.scrollNav {
  transition: all 0.5s ease-in;
  z-index: 2000;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #dddddd;
}
.styl {
  padding-top: 80px;
}
.btn--outline{
  margin-top: -25px;
}

.prem{
background-color: #ef4343;
background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);

  border-radius:20px;
}
/* Avator styling  */
.avator{
vertical-align: middle;
width: 35px;
height: 30px;
border-radius: 50%;
border-width: 2px;
border-color: gray;
border-style: outset;
}
#mobileNav{
position: fixed;
z-index: 999;
top: 0;
width: 100%;
}

/* Avator styling  */
.avator2{
  vertical-align: middle;
  width: 35px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-color: gray;
  border-style: outset;
  z-index: -999;
  }
  

a.nav-links-mobile.sign{
  color:#020202;
}
.fa-bars{
  color:#000000 ;

 
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  max-width: auto;
  
}


.navbar-logo {
   color: #ef4343; 
  justify-self: start;
  margin-left: 20px;

  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-item{
  font-size: 15px;
  margin-left: -20px;


}
.nav-item2{
  font-size: 15px;
  margin-left: -20px;
display: none;

}
.nav-user{
  font-size: 15px;
  margin-left: -20px;
  display: none;

}
.nav-userd{
  font-size: 17px;
  margin-right: 500px;
  position:absolute;
  background-color: #ef4343;
  background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
 

}
.nav-logout{
  font-size: 17px;
  right: 20px;
  position:absolute;
 color: #ef4343;
  color: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
 

}
a.nav-links{
  text-decoration: none;
  text-decoration-line:none;
  color: black;
}
.nav-links:hover  {
  text-decoration: none;
  color: #ef4343;
  cursor: pointer;

  
}

.nav-links:before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0%;
  background-color: #ef4343;
  background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
  border-radius: 12px;
  transition: all 0.4s ease;
}
.nav-links:hover:before{
  width: 80%;
}
.nav-links:before{
  left: 10%;
  transform: translateX(-50%);
}
.nav-links:before{
  width: 100%;
  bottom: -5px;
  opacity: 0;
}
.nav-links:hover:before{
  bottom: 0px;
  opacity: 1;
}
.nav-links:before{
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s ease;
}
.nav-links:hover:before{
  transform: scaleX(1);
  transform-origin: left;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: left;
  width: 60vw;
  justify-content: end;
  margin-right: 15rem;
  z-index: 2000;
}

.nav-links {
 color:#000000;
  font-weight:bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 135%;
}

.menu-icon {
  display: none;
}

.nav-links {
  text-decoration: none;
  transition: all 0.2s ease-out;
}
svg.sc-bdvvtL.cmlAWX.feather.feather-moon {
  margin-top: -6.5px;
  height: 25px;
  width:25px;

}

svg.sc-bdvvtL.cmlAWX.feather.feather-sun {
  margin-top: -6.5px;
  height: 25px;
  width:25px;
 
}


.nav-links-mobile {
  display: none;
  
}


.menu-icon {
  display: none;
}
/* tesla fixed the movement of the side menu wen screens are changed in size */


/* tesla fixed the movement of the side menu wen screens are changed in size */
@media screen and (max-width: 1080px) {
  .nav {
    width: 100%;
 
  
}

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: inline-table;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    position: absolute;
    top: 0%;
    
    padding-top: -20px;
    left: -100%;
  }

  .nav-menu.active {
    background: #ffffff;
    color:#000000;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
   
  }
 
  /* padding of the mobile nav menu */
  .nav-links {
    text-align: center;
    padding: 8px;
    width: 100%;
    display: table;
    color: #000000; 

  }


  

  .navbar-logo {
    position: absolute;
    top: 0;
    margin-top: -15px;
    margin-right: 92%;
    transform: translate(25%, 50%);
  }
  .showMobile{
    display: flex;
  }
  .showDesktop{
    display: none;
  }

  
  .menu-icon {
    display: block;
    position: absolute;
    top: -0px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: -19px;
  }

  .fa-times {
     color: #fff; 
    font-size: 2rem;
  }

  .nav-links-mobile {
    display:ruby-base;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  .nav-user{
    font-size: 15px;
    margin-inline: 10;
    margin-left: -20px;
    display: inline-block;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .nav-logout{
display: none;
   
  
  }
  .nav-item2{
    font-size: 15px;
  display: flex;
  
  }
  .nav-item{
    font-size: 15px;
  display: flex;

  
  }
  
}



