/* Mother Land */
.mom-container {
    width: 100%;
    margin-bottom: 2%;
}
/* Mother Land Flag */
.country-flag{
    background-color:transparent;
}

/* country flag */
#flag{
    margin-left: 37%;
    width: 25%;
    height: 20vh;
    margin-top: 8%;
    margin-bottom: 2%;
}
#flag2{
    position: absolute;
    top: 0%;
    width: 100%;
    height: 45vh;
    opacity: 0.2;

}
/* country selector list */
.country-selector{
    position: absolute;
    top: 12%;
    width: 12%;
    left: 5%;
}

/* country history container */
.history{
    text-align: center;
    margin: 0% 2%;
    border-radius: 4px;
}
#name{
    text-align: center;
    font-weight: 700;
    padding: 0% 4%;
}
.history > #heading{
    text-align: left;
    font-weight: 500;
    margin-left: 5%;
}

.history #historytext{
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    color:inherit;
    text-align: justify;
    line-height: 25px;
    font-size:19px;
    padding: 1% 5%;    
}
.history #horizontal{
    margin-left: 5%;
    margin-top: 0.5%;
    background-color: grey;
    width: 90%;
    height: 1px;
}

#imgBtn{
    color: #9f1f4a;
    background-color: transparent;
    border: none;
    margin-top: -2%;
    margin-left: 80%;
    
}

/* updata button */
#update-btn{
    position: relative;
    color: #fff;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 15%;
}

#edit-bt{
    color: #9f1f4a;
    background-color: transparent;
    border: none;
}
/* edit text area */
.textedit-box{
    text-align: center;
    margin:5% 0;
    width: 100%;
    padding: 2%;
    border-radius: 4px;
}
.textarea{
    margin:0.5% 2%;
}
#text{
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 60vh;
    padding: 2%;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media screen 1080px */
@media screen and (max-width:1080px) {
/* country flag */
#flag{
    margin-left: 40%;
    width: 20%;
    height: 10vh;
    margin-bottom: 2%;
    opacity: 1;
}
#flag2{
    display: none;
}
/* country selector list */
.country-selector{
    position: absolute;
    width: 25%;
    height: 5%;
    top: 12%;
    left: 2%;
}

.history > #heading{
    text-align: left;
    font-weight: 500;
    margin-left: 5%;
    font-size: 25px;
}
    #name{
        font-size: 25px;
       text-align: center;
       font-weight: 600;
       margin-bottom: -5%;
    }
    .history{
        margin: 2% 5%;
        text-align: center;
    }
   
    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 30px;
        font-size: 19px;
        padding: 2% 4%;
        border-radius: 8px;
        
    }
    .history > h1{
        font-size: 25px;
        width: 90%;
        margin-left: 5%;
        padding: 2% 4%;
    }
 /* updata button */
#update-btn{
    position: relative;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 5%;
}   
    /* edit button */
    #imgBtn{
        margin-left: 0%;
    }

    /* edit text area */
.textedit-box{
    text-align: center;
    margin:10% 0;
    width: 100%;
    height: 70vh;
    padding: 2%;
    border-radius: 4px;
}
    .textarea{
        width: 90%;
        margin:1% 5%;
        
    }
    #text{
        font-size: 17px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
    
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media screen 720px */
@media screen and (max-width:720px) {

    #flag{
        margin-left: 38%;
        width: 25%;
        height: 15%;
        margin-top: 5%;
        margin-bottom: 2%;
        padding: 10px 20px;
        opacity: 1;
    }
    #flag2{
        display: none;
    }
    /* country selector menu */
    .country-selector{
        position: absolute;
        width: 25%;
        top: 10%;
        left: 2%;
    }

     /* history title & paragraph */
     .history{
         margin-top:5% ;
         margin: 2% 5%;
         /* box-shadow: 1px 2px 6px 1px rgb(83, 82, 82); */
     }
     
    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 25px;
        font-size: 19px;
        padding: 2% 5%;
    }

    .text-field{
        margin: 5% 0%;
    }
    /*edit button  */
    #imgBtn{
        margin-left: 0%;
    }
    /* update button */
  
    #text{
        font-size: 20px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
}
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Media Screens */
@media screen and (max-width:650px) {
     /* all css  */
     #flag{
        margin-left: 38%;
        width: 25%;
        height: 15%;
        margin-top: 5%;
        margin-bottom: 2%;
        padding: 10px 20px;
        opacity: 1;
    }
    #flag2{
        display: none;
    }
    /* country selector menu */
    .country-selector{
        position: absolute;
        width: 25%;
        top: 10%;
        left: 2%;
    }
  
     /* history title & paragraph */
     .history{
         margin-top:5% ;
         margin: 2% 5%;
     }

    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 25px;
        font-size: 18px;
        padding: 2% 5%;
        border-radius: 8px;   
    }

    .text-field{
        margin: 5% 0%;
    }

    /* edit button */
    #imgBtn{
        text-align: center;
    }
   
    #text{
        font-size: 15px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
   
}
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media Screen 480px */
@media screen and (max-width:480px) {
         /* all css  */
         #flag{
            margin-left: 36%;
            width: 30%;
            height: 15%;
            margin-top: 5%;
            margin-bottom: 2%;
            padding: 10px 20px;
            opacity: 1;
        }
        #flag2{
            display: none;
        }

        /* country selector menu */
        .country-selector{
            position: absolute;
            width: 25%;
            top: 12%;
            left: 5%;
        }

        .history > #heading{
            text-align: center;
            font-weight: 500;
            margin-left: 5%;
            font-size: 25px;
        }
        .history{ 
            margin-top: 5%;
            text-align: center;
        }
        .history #historytext{
            background-color: transparent;
            text-align: justify;
            line-height: 25px;
            font-size: 17px;
            padding: 2% 4%;
            border-radius: 8px;   
        }
       
       
        .text-field{
            margin: 5% 0%;
        }

        /* edit button */
        #imgBtn{
            margin-left: 0%;
            text-align: center;
        }
      
        #text{       
            font-size: 15px;
            width: 100%;
            height: 60vh;
            padding: 2%;
        }
}
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
@media screen and (max-width:360px) {
   /* country flag */
   #flag{
    margin-left: 36%;
    width: 30%;
    height: 15%;
    margin-top: 5%;
    margin-bottom: 2%;
    padding: 10px 20px;
    opacity: 1;
}
#flag2{
    display: none;
}

/* country selector menu */
.country-selector{
    position: absolute;
    width: 25%;
    top: 12%;
    left: 4%;
}
/* updata button */
#update-btn{
    position: relative;
    color: #fff;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 5%;
}   

.history{
    margin-top: 10%;
    text-align: center;
    text-align: justify;
    border-radius: 4px;
}

.history #historytext{
    background-color: transparent;
    line-height: 25px;
    word-spacing: 1px;
    font-size: 17px;
    padding: 1% 4%;    
}
    .textarea{
        width: fit-content;
    }
    .text-field{
        margin: 5% 0%;
    }
    #edit-btn{
        margin-left: 5%;
        margin-bottom: 2%;
        width:30%;
        padding: 1%;
        font-size: 15px;
    }
    #imgBtn{
        color: #9f1f4a;
        background-color: transparent;
        border: none;
        margin-top: -10%;
        margin-left: 30%;
        
    }
  
    #text{
        font-size: 20px;
        margin:0 5%;
        width: 100%;
        height: 70vh;
        padding: 30px;
    }

}