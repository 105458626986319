#top{
    background-color: #ef4343;
    padding: 10% 0%;
    width: 100%;
    margin-top: -10%;
    text-align: center;
    color: #ffff;
}
.P-body{
    /* color: black; */
    text-align: justify;
}
ol li:hover{
    cursor: pointer;
    color: #ef4343;
}
/* all lists tags */
#P-list{
    font-family:Arial, Helvetica, sans-serif;
    line-height: 25px;
    display: list-item;
    text-align: left;
    /* color: inherit; */
}
.P_para{
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 35px;
}
/* bold tags */
b{
    margin: 0%;
}
/* italic tags */
i{
    margin: 0;
}
/* paragraph tags */
p{
    line-height: 45px;
    font-size: 20px;
}
h2{
    padding: 2% 0%;
}
/* form  */
.P-form {
  margin: 5% 0%;
}
#point{
    font-family:Arial, Helvetica, sans-serif;
}
.P-form  > input  {
    width: 80%;
    background-color: honeydew;
    padding: 2%;
     }
    #top-btn{
        background-color:   #ef4343; 
        color:#fff;
        padding:1% 4%;
        position: fixed;
        bottom:2%;
        right:5%;
        font-size:15px;
        cursor:pointer;
        border-radius: 2px;
        border-color: #fff;
    }
    /* footer */
    .P-footer {
      
        display: grid;
        background-color: #ef4343;
      

    }
    .P-footer > #link{
       
        width: 90%;
        padding-left: 5%;
        margin-top:2%;
        margin-bottom: 2%;
        text-decoration: none;

    }
/* ===================================================================================================== */
    /* Media Screens */
    @media screen and (width:1080px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            /* font-size: 15px;  */
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
    }
    @media screen and (width:720px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 15px;
        }
        ul li{
            font-size: 15px;
        }
        ol li{
            font-size: 15px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
        
    }
    @media screen and (width:650px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 14px;
        }
        ul li{
            font-size: 16px;
        }
        ol li{
            font-size: 16px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
    }
    /* =================================================================================================== */
    @media screen and (width:480px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 14px;
        }
        ul li{
            font-size: 14px;
        }
        ol li{
            font-size: 14px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 10%;
        }
        .P-footer {
        display: grid;
        background-color:  #ef4343;

        }
        .P-footer > Link{
            margin-left: 5%;
        }
        
    }