.signup{
    margin-top: -15px;
     font-weight: bold;
      font-size: 22px;
       border-color: #EF4343;
        background: #EF4343; 
        height: 50px;
         width:393px;
          border-radius: 50px;
           margin-left: 25px;
}
  
  
  @media (max-width:991px){
    .img10{display:none;}
    .signup{
        width:330px;
        margin-left: 45px;
    }
 
  }
  
  

.headf{
    font-size: 40px;
}
.fotgotcontent{margin-top:-400px;
     margin-left: 700px;}
.hrf{
    width: 400px;
}
     #forgotor{
        margin-left: 200px;
     }

     #emailF{
        width: 400px;
     }

     .logf{
        margin-left: 100px;
     }
     #forgotbut{
        margin-left: 100px;
     }
@media (max-width:1210px){
    .Forgotimg{
        display: none;
    }
    .fotgotcontent{
        margin-left: 100px;
         margin-top:20px;
    }

    #forgotor{
        margin-left: 200px;
     }
   
    }

    @media (max-width:529px){
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 200px;
         }
       
        }

        
    @media (max-width:436px){
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 200px;
         }

   
    }

    @media (max-width:429px){
        .headf{font-size: 32px;}
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 170px;
         }

   #emailF{
    width: 345px;
   }

   #forgotbut{
    margin-left: 70px;
 }

 .logf{
    margin-left: 70px;
 }


 .hrf{
    width: 345px;
}
   
    }
 
  

    
        
    @media (max-width:350px){
        .headf{font-size: 26px;}
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 130px;
         }

   #emailF{
    width: 270px;
   }

   #forgotbut{
    margin-left: 40px;
 }

 .logf{
    margin-left: 40px;
 }


 .hrf{
    width: 270px;
}
   
    }
 
  

.footer-container {
  width: 100vw;
  justify-content: center;
  align-items: center; 
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  
}


.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 2px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 18px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 8px;
  margin-bottom: 16px;
  border: 1px solid black;
}

.footer-links {

  display: flex;
  justify-content: center;
  margin-left: 22px;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h5 {
  margin-bottom: 16px;
}

.footer-email-form h5 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 2px;
}

.social-media-wrap {
  display:flex;
  padding: 30px;
  justify-content: space-between;
  align-items:flex-start;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
#sub-btn{
  background-color: rgb(19, 230, 11);
  border: 3px solid #fff;
  color: #fff;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 5px;
  margin: 5px;
  border-radius: 12px;
  width: 100px;
}
.tele{
  border:none;
  border-radius: 18px;
  width: 225px;

}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

:root {
  --primary: #fff;
}
.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #fff;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  
  border: 1px solid #fff;
  
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium,
.btn--large {
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
} 

.backgb {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: -1;
  overflow-x: hidden;


}

 video {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: -1;
} 

.hero-container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.hero-container > h1 {
  color: #ef4343;
  font-size: 100px;
  margin-top: -100px;
  text-align: center;
}

.herobutton{
  background-color: #ffff;
  width: 140px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  border: 2px solid #ffff;
  margin:0 auto 60px;
  float: inline-end;
  padding:9px 0;
  clear:left;
  
  
}
.herobutton:hover{
 text-decoration: none;
 
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}
.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 650px) {
  .hero-container > h1 {
    font-size: 40px;
    text-align: center;
  }
  .hero-container > p {
    font-size: 25px;
    text-align: center;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}
.typewriter {
  font-family: Courier, monospace;
display: inline-block;
}

.typewriter-text {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
 -webkit-animation: typing 5s steps(30), blink .75s step-end infinite;
         animation: typing 5s steps(30), blink .75s step-end infinite;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-right: 4px solid #fff;
  box-sizing: border-box;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: forward;
          animation-direction: forward;
}

@-webkit-keyframes typing {
  from { 
      width: 0% 
  }
  to { 
      width: 100% 
  }
}

@keyframes typing {
  from { 
      width: 0% 
  }
  to { 
      width: 100% 
  }
}

@-webkit-keyframes blink {
  from, to { 
      border-color: transparent 
  }
  50% { 
      border-color: #fff; 
  }
}

@keyframes blink {
  from, to { 
      border-color: transparent 
  }
  50% { 
      border-color: #fff; 
  }
}

/*Cards-web*/
*{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.cards {
  text-decoration: none;
  text-align: center;
  list-style-type:none;
  align-self: center;
} 
.movebutton{
margin-left: 40%;
}
.herobuttonB{
  background: #ef4343;
  color: #fff;
  width: 130px;
  height: 40px;
  text-align: center;
  border-radius: 25px;
  border: 2px solid #ffff;
  margin:0 auto 60px;
  display: inline-flex;
  font-size: 13px;
  padding:9px 10px;
  box-shadow: 1px 2px 6px 1px grey;

  
}
.herobuttonB:hover{
 text-decoration: none;
 
}
.cards-box{
  
  box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
  padding: 40px;
  margin-top: 20px;
  width: 300x;
}
.userW{
  margin-left: 4%;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}
.premb{
  display: none;
}
@media screen and (max-width: 1024px) {
.premb{
  margin-top: 60px;
  display: flex;
  margin-left: 36%;
  background-color: #c7e9fb;
  background-image: #ef4343;  
  border-radius:20px;
}
}
.fontStyle{
  font-family: "Times New Roman", Times, serif;
 
 
}
h1{
  color: #ef4343;
  padding-top: 0px;
 
}
 li{
  display: inline-block;
  position: relative;
  text-align: center;


}

 ul {
  text-align: center;
	}

.cards__item__img{
  box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
  transition: 0.3s;
  max-width: 200px;
  border-radius: 8px;
  transition: all 0.2s linear;
  z-index: 999;
}
.cards__item__imgad{
  box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
  transition: 0.3s;
  max-width: 200px;
  border-radius: 8px;
  margin-right: 50%;
  margin-top: -9%;
  transition: all 0.2s linear;
  z-index: 999;
}
.cards__itemad {
  margin-top: -6%;
  margin-left: -10%;
  width: 120%;

}
.boxcoad{
  height: 300px;
  background-color: transparent;
  box-shadow: 1px 2px 6px 1px rgb(83, 82, 82);
}
.cards__item{
  padding: 100px;

}

label{
  color:#020202;
}
 /*check this out by nick t*/
.cards{
  color:#020202;
}
.cards__item__img:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.data-category{
  -webkit-text-decoration-style:none;
          text-decoration-style:none;
  margin: 0px 20px 0px 20px;
  border-radius: 18px;
  background-color: rgb(44, 37, 37);
  color: #ef4343;
  padding: 5px;
  margin-top: 10px;
}
.cards__item__info{
  justify-content: center;
  align-items: center;
 padding: 20px;
}
.cards__item__info .data-category:hover{
  text-decoration:none;
   background-color: #ef4343;
   color: ivory;
   -webkit-transform:scale(1.1) ;
           transform:scale(1.1) ;
}

.cards__wrapper{
  align-content: center;
  display: block;
}
.AllPage{
  width: 100vw;
  overflow-x: hidden;
}


/*Cards*/
@media only screen and (max-width: 650px) {

   .cards {
    text-decoration: none;
    text-align: center;
    list-style-type:none;
    align-self: center;
    background: #fff;
  } 

  .movebutton{
    margin-left: 8%;
    }
   h1{
    padding-top: 0px;
    font-family:-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
  }
  
  .tab {
    padding: 30px 50px 30px 50px;
    position:static; 
    width: 1000px;
    border-radius: 10px;
    box-shadow: 0 8px 15px 0 #242222;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tab td{
    padding:5px ;
    border-style: none;
    text-decoration: none;
  }
  
  .cards__item__img{
    box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
    transition: 0.3s;
    max-width: 200px;
    border-radius: 8px;
    transition: all 0.2s linear;
  }
  .cards__item{
    padding: 5px;
  }
  .cards__item__img:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .data-category{
    -webkit-text-decoration-style:none;
            text-decoration-style:none;
    margin: 0px 20px 0px 20px;
    border-radius: 18px;
    background-color: rgb(44, 37, 37);
    color: #ef4343;
    padding: 5px;
    margin-top: 10px;
  }
  .cards__item__info .data-category:hover{
    text-decoration:none;
     background-color: #ef4343;
     color: ivory;
     -webkit-transform:scale(1.1) ;
             transform:scale(1.1) ;
  }
  
  .cards__wrapper{
    align-content: center;
  }
}

@media only screen and (max-width: 1024px) {

  .cards__itemad {
    margin-top: -95%;
  }
}

.phonead{
   margin-left: -70%;
}
.phonefeature{
    width: 70%;
    margin-left: 30%;
    margin-top: -40%;
    height: 500px;
}
.iconB{
    font-size: 50px;
}
.item-list{
    display: inline-block;
    padding:40px;
}
@media screen and (max-width: 1080px) {
    .phonead{
        margin-left: 0%;
     }
     .phonefeature{
        width: 100%;
        margin-left: 0%;
        height: 80%;
        margin-top: 10%;
    }
}

.btns-boxL{
    align-content: center;
    margin: 1% 15%;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    margin-left: 12%;
    padding: 1% 4%;
    padding-top: 2%;
    box-shadow: 1px 2px 4px 1px grey;
    border-radius: 2px;
}
.btn-sub-boxL:hover{
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.language-label{
    position: relative;
    font-style: bold;
    /* font-size:200px; */
    margin-top: 5%;
    width: 65%;
    text-align: center;
    padding: 20px 10px 10px 10px;
    margin-left: 17%;
}

.pan-flag{
    /* position: absolute; */
    /* width: 15%; */
    height: 20vh;
    top: 12%;
    left: -6%;
}

#btn-boxL > button{
    background: #ef4343;
    color: #fff;
    width: 85%;
    font-size: 100%;
    margin: 10px;
    padding: 3px 15px;
    border-radius: 25px;

}   
.btn-booksL{
    margin: 2% 15%;
    padding: 4%;
    margin-left: 12%;
    box-shadow: 1px 2px 6px 1px grey;
    border-radius: 2px;
}


/* media Queries */
@media screen and (max-width:1080px){
    .btns-boxL{
        align-content: center;
        margin: 2% 1%;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin-left: 2%;
        padding-top: 5%;
    }

    .language-label{
        font-style: bold;
        margin-top: 1%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 15%;
    }
    #pan-flag{
        position: absolute;
        width: 25%;
        height: 25%;
        top: 20%;
        left: 3%;
    }
 
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 150px;
        font-size: 20px;
        margin:20px 0px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   

    #filter-label{
        position: absolute;
        left: 5%;
        top: 30%;
    } 
    .btn-booksL{
        margin: 6% 1%;
        padding: 4%;
        margin-left: 4%;
        box-shadow: 1px grey;
    
    }
    .changeColor{
        margin-top: 5%;
    }
}
@media screen and (max-width:720px){
    .btns-boxL{
        align-content: center;
        margin: 2% 10%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-left: 5%;
    }

    .language-label{
        font-style: bold;
        margin-top: 10%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 20%;
    }
    #pan-flag{
       position: absolute;
        left: 30%;
        top: 10%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
    .btn-sub-boxL{
        display: grid;
        position: relative;
        width: 30%;
        padding: 20px;
    }
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 130px;
        font-size: 20px;
        margin:20px 10px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   
    #filter-label{
        position: absolute;
        left: 14%;
        top: 24%;
    } 
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
}
@media screen and (max-width:650px){
    .btns-boxL{
        align-content: center;
        margin: 2% 10%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-left: 4%;
        padding: 5% 10%;
        box-shadow: 1px grey;
    }
  
    .language-label{
        font-style: bold;
        margin-top: 35%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 15%;
    }
    #pan-flag{
        position: absolute;
        left: 30%;
        top: 1%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
  
    #btn-boxL > button{
        background: #ef4343;
        color: #fff;
        width: 130px;
        font-size: 20px;
        margin:20px 10px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }   

    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
}
@media screen and (max-width:480px){
    .btns-boxL{
        align-content: center;
        margin: 5% 15%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-left: 5%;
        padding: 4%;
        border-bottom: 1px grey;
        box-shadow: none;
    }

    .language-label{
        font-style: bold;
        margin-top: 15%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 12%;
    }
    .pan-flag{
        position: absolute;
        left: 30%;
        top: -15%;
        width: 35%;
        height: 60%;
        margin: 3%;

    }
    #btn-boxL > button{
        background:#ef4343;
        color: #fff;
        width: 130px;
        font-size: 18px;
        margin: 10px 20px;
        padding: 2px 10px;
        border-radius: 25px;
    
    }
    #filter-label{
        position: absolute;
        left: 18%;
        top: 25%;
    } 
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }   
    .changeColor{
        font-size: 20px;
        margin-top: 25%;
        margin-bottom: -10%;
    }
}

@media screen and (max-width:360px) {
    .btns-boxL{
        text-align: center;
        margin: 0px 30px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding: 0px 100px ;
        padding-left: 0px;
        box-shadow: none;

    }
    #language-label{
        font-style: bold;
        margin-top: 20%;
        text-align: center;
        width: 70%;
        padding: 20px 10px;
        margin-left: 12%;
    }
    #pan-flag{
        position: absolute;
        left: 30%;
        top: -10%;
        width: 25%;
        height: 20%;
        margin: 3%;

    }
  
    #btn-boxL > button{
        background: #ef4343;
        color: #fff;
        width: 125px;
        font-size: 16px;
        margin: 10px 5px;
        padding: 5px 5px;
        border-radius: 25px;
    
    }
    #filter-label{
        position: absolute;
        left: 14%;
        top: 25%;
    }   
    .btn-booksL{
        margin: 2% 15%;
        padding: 4%;
        margin-left: 12%;
        box-shadow: none;
    
    }
    
}

/* Content container  */
.item-content{
    margin: 10% 2%;
    display:grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 15px;
    align-content: center;
    margin-left: 1%;
}
/* item box */
.item-content-box{
    width: 80%;
}
.content-img{
    width: 90%;
    margin:auto 5%;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 12px 14px 0px rgb(46, 43, 43);
}
.item-content-data{
    
    width: 120%;
    margin-bottom: 30px;
}
.btn-container{
    width: 100%;
}
.btn-container > button{
    background: #ef4343;
    width: 300px;
    padding: 10px 0;
    font-weight: 700;
}

/* Media Screen 1080px rbs */
@media screen and (max-width:1080px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: 10%;
    }
    .item-content-data{
    
        width: 200px;
        margin-bottom: 30px;
    }
    
}
/* Media Screen 720px  */
@media screen and (max-width:720px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: -10%;
    }
    .content-img{
        width: 80%;
        margin:auto 5%;
        border-radius: 8px;
        margin-bottom: 20px;
        box-shadow: 0px 12px 14px 0px rgb(46, 43, 43);
    }
    .item-content-data{
    
        width: 200px;
        margin-bottom: 30px;

    }
    
}
/* Media Screen 650 */
@media screen and (max-width:650px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: -7%;
    }
    .item-content-data{
    
        width: 250px;
        margin-bottom: 30px;

    }
    
}
/* Media Screen 480px */
@media screen and (max-width:480px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        align-content: center;
        margin-left: 10%;
    }
    
}
/* Media Screen 360px */
@media screen and (max-width:360px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        margin-top: 4%;
        align-content: center;
        margin-left: 6%;
    }
    
}
.writerform{
    width: 100%;
}
.container{
    margin-top: 50px;
}
.HideMe{
    display: none;

}

.DisplayMe{
    display: block;

}
.buttonMe {
    background:  #ef4343;  
   border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
     cursor: pointer;
     border-radius: 11px;
  }
  .buttonMe:hover {

    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

html,body{
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
body {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #3e3f5e;
    font-size: 14px;
	letter-spacing: 0.1px;
 
}
p {
    
    font-size: 14px;
    line-height: 24px;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: 0;
    font-weight: 300;
}
/*span{
    color: #adafca;
}*/
h1, h2, h3, h4, h5, h6{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 0;
    color: #3e3f5e;
}
ul, ol{
    margin-top: 0;
}
a,
a:hover,
a:focus,
a:active {
    outline: none;
	color: inherit;
    -webkit-text-decoration: none;
    -ms-text-decoration: none;
    -o-text-decoration: none;
    text-decoration: none;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: none;
}
.image {
    height: auto;
    max-width: 100%;
}
ins{background: transparent;}
.overlap {
    position: relative;
    margin-top: -82px;
    z-index: 2;
}
.overlap-2{
	margin-top: -150px;
}
.theme-layout {
    float: left;
    position: relative;
    width: 100%;
}
.theme-layout.boxed {
    background: none repeat scroll 0 0 #e6e6e6;
    float: none;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 1230px;
    -ms-box-shadow: 0 0 6px #747474;
    -o-box-shadow: 0 0 6px #747474;
    box-shadow: 0 0 6px #747474;
}
.col-1-5{
    width: 20%;
	max-width: 20%;
	flex: 0 0 20%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
.remove-ext20 {
    margin-bottom: -20px;
}
section {
    float: left;
    position: relative;
    width: 100%;
}
.gap {
    float: left;
    padding: 80px 0;
    position: relative;
    width: 100%;
}
.gap2 {
    padding: 40px 0;
}
.gap2.no-gap{
	padding: 0;
}
.gap2.no-top{
	padding-top: 0;
}
.gap2.no-bottom{
	padding-bottom: 0;
}
.gap.no-gap {
    padding: 0;
}
.gap.no-top {
    padding-top: 0px;
}
.gap.no-bottom {
    padding-bottom: 0px;
}
.remove-ext-30 {
    margin-bottom: -30px;
}
.remove-ext-40 {
    margin-bottom: -40px;
}
.remove-ext-50 {
    margin-bottom: -50px;
}
.merged {
    margin: 0px;
    padding: 0;
}
.merged > div {
    padding: 0;
}
.row.merged > div {
    padding: 0;
}
.merged.row {
    margin: 0;
}
.merged.row > div {
    padding: 0;
}
.row.merged20 {
    margin: 0 -11px;
}
.row.merged20 > div {
    padding: 0 10px;
}

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  -ms-background-size: cover;
  background-size: cover;

  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.parallax-fixed {
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
}
.parallax {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}
.blackish:before,
.whitish:before,
.bluesh:before,
.purple:before,
.pattern:before,
.orange:before,
.mate-black:before,
.pinkish:before{
    background: rgba(0,0,0,.99);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.gradient:before{
    background: linear-gradient(145deg,#321575 0%,#ff057c 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.pinkish:before{
	background: #ff7c7c;
}
.pattern:before {
    
    opacity: 0.45;
}
.mate-black:before{
	background: rgba(5, 10, 40, 0.99) none repeat scroll 0 0;
}
.whitish:before {
    background: rgba(255, 255, 255, .9);
}
.bluesh:before {
    background: #3ca9fc;
}
.purple:before {
    background: #9e72fa;
}
.orange:before {
    background: #fa6342;
}
.exthigh-opacity:before {
    -webkit-opacity: .98;
    -moz-opacity: .98;
    -ms-opacity: .98;
    -o-opacity: .98;
    opacity: .98;
}
.high-opacity:before {
    -webkit-opacity: .95;
    -moz-opacity: .95;
    -ms-opacity: .95;
    -o-opacity: .95;
    opacity: .95;
}
.medium-opacity:before {
    -webkit-opacity: .85;
    -moz-opacity: .85;
    -ms-opacity: .85;
    -o-opacity: .85;
    opacity: .85;
}
.low-opacity:before {
    -webkit-opacity: .7;
    -moz-opacity: .7;
    -ms-opacity: .7;
    -o-opacity: .7;
    opacity: .7;
}
.ext-low-opacity::before {
    -webkit-opacity: .5;
    -moz-opacity: .5;
    -ms-opacity: .5;
    -o-opacity: .5;
    opacity: .5;
}
.help-bg {
    background: #f8f9fb none repeat scroll 0 0;
}
.gray-bg {
    float: left;
    width: 100%;
    background: #edf2f6;
}
.bg-valvot{
	background-color: #8c6ad2 !important;
}
.bg-purple{
	background: #8c6ad2;
}
.bg-blue{
	background: #3ca9fc;
}
.bg-red{
	background: #e44a3c;
}
.bg-green{
    background: #38bff1;
}
[type="submit"],
button,
html [type="button"] {
    cursor: pointer;
}
textarea {
    resize: none;
}

.display-table {
	display: table;
	width: 100%;
	height: 100%;
}
.display-table-cell {
	display: table-cell;
	vertical-align: middle;
}
/*carousel nave style*/
ul.owl-carousel {
    padding-left: 0;
    list-style: none;
    float: left;
    width: 100%;
}

#html5-watermark {
    display: none !important;
}
iframe#html5boxiframevideo {
    height: 400px;
}
iframe {
    width: 100%;
}
/*--- responsive header ----*/
.responsive-header {
    background: #f5f5f5 none repeat scroll 0 0;
    border-bottom: 1px solid #e1e8ed;
    display: none;
    line-height: 40px;
    padding: 5px 15px;
    position: relative;
    width: 100%;
	z-index: 9999999;
}
.right-compact .sidemenu {
    float: right;
    line-height: 44px;
}
.logo.res {
    display: inline-block;
    vertical-align: middle;
    width: 40%;
}
.res-search {
    float: right;
    margin-right: 30px;
    padding-top: 3px;
	cursor: pointer;
}
.user-avatar.mobile {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    position: unset;
    -webkit-transform: translate(0px);
            transform: translate(0px);
    vertical-align: middle;
    width: 30%;
}
.user-avatar.mobile > a img {
    border: 1px solid #e0e0e0;
    display: inline-block;
    max-width: 45px;
    padding: 3px;
    vertical-align: middle;
    width: 45px;
	border-radius: 100%;
}
.user-avatar.mobile > .name {
    display: inline-block;
    padding-bottom: 0;
    vertical-align: text-top;
    width: 77%;
	text-align: left;
}
.right-compact {
    float: right;
    text-align: right;
    width: 28%;
}

.user-avatar.mobile > .name h4 {
    color: #1f273f;
    font-size: 12px;
    line-height: 11px;
    margin: 0;
}
.user-avatar.mobile > .name span {
    color: #5f677f;
    font-size: 12px;
    line-height: 0;
    margin: 0;
}
.res-logo > img {
    max-width: 90px;
}
.res-search > span {
    color: #82828e;
    font-size: 16px;
    position: relative;
}
.restop-search {
    background: #00071f none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s linear 0s;
}
.restop-search.active{
	width: 100%;
	opacity: 1;
	visibility: visible;
}
.restop-search > span {
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9;
}
.restop-search > form {
    display: inline-block;
    width: 100%;
}

.restop-search input {
    border: medium none;
    border-radius: 0 40px 40px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: 100%;
    padding: 7.6px 15px;
    width: 90%;
}
/*======================/
***** Home page 1 
======================*/
/*-- page loader ----*/
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	
}
#topcontrol {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    transition: all 0.2s linear 0s;
    width: 40px;
    z-index: 99;
}
#topcontrol:hover {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
#topcontrol:active {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.62) inset
}	
/*-- main title ---*/
.title {
    display: inline-block;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
}
.title > h1 {
    color: #1f273f;
    display: inline-block;
    font-size: 40px;
    margin-bottom: 0;
    width: 100%;
	font-weight: 500;
	position: relative;
}
.title > p {
    display: inline-block;
    margin: 0 auto;
    max-width: 70%;
    width: 70%;
}
/*--- footer ---*/
footer .gap {
    padding: 70px 0;
}
footer .blackish::before {
    z-index: -1;
}
footer {
    float: left;
    position: relative;
    width: 100%;
	background: #deebf3;
	border-top: 1px solid #9ab2c1;
}
footer .widget-title{
    margin-bottom: 30px;
}
.web-info {
    display: inline-block;
    width: 100%;
}
.web-info .logo {
    margin-bottom: 30px;
}
.web-info .logo img {
    max-width: 100px;
}
.web-info .logo span {
    font-size: 30px;
}
.web-info > a {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
}
.web-info > p {
    display: inline-block;
    width: 100%;
	color: #3e3f5e;
}
.contact-little {
    display: inline-block;
    width: 100%;
}
.contact-little > span {
    display: inline-block;
    margin-bottom: 7px;
    width: 100%;
}
.contact-little > span i {
    margin-right: 10px;
}
.static, .static > div {
    position: static;
}
.sidebar {
    margin: 0 auto;
    width: 100%;
	float: left;
}
.news-lettr {
    display: inline-block;
    position: relative;
    width: 100%;
}
.newsletter {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}
.newsletter > input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    border-radius: 30px;
    display: inline-block;
    font-size: 14px;
    padding: 9px 15px;
    width: 100%;
}
.newsletter > button {
    background: red none repeat scroll 0 0;
    border: medium none;
    border-radius: 100%;
    color: #fff;
    font-size: 20px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 39px;
}
.news-lettr > p {
    display: inline-block;
    font-size: 12px;
    line-height: normal;
    line-height: initial;
    margin-bottom: 10px;
    width: 100%;
}
.news-lettr > a {
    margin-right: 20px;
}
.news-lettr > h5 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 12px;
    width: 100%;
}
.widget {
    display: inline-block;
    position: relative;
    width: 100%;
}
.widget-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    vertical-align: middle;
    width: 100%;
	position: relative;
}
.widget-title > h4 {
    display: inline-block;
    font-size: 17px;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    width: 100%;
	font-weight: 700;
}
.widget-title > h4::before {
    bottom: -4px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 40px;
}
.widget-title > h4::after {
    bottom: -8px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 30px;
}
.widget .main-btn {
    font-size: 13px;
    padding: 7px 15px;
    text-transform: capitalize;
}
.quick-links {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
}
.quick-links > li {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 8px;
    padding-left: 15px;
    position: relative;
    width: 100%;
}
.quick-links > li:last-child{
	margin-bottom: 0;
}
.quick-links > li::before {
    content: "\e8d5";
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
	font-size: 10px;
}
.quick-links > li a {
    color: inherit;
    font-size: 13px;
    text-transform: capitalize;
}
.quick-links > li a > i {
    margin-right: 10px;
}
.bottombar {
    background: #d4e1e5 none repeat scroll 0 0;
    float: left;
    padding: 20px 15px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.bottom-mockup {
    margin-bottom: 0;
    width: 100%;
}
.bottom-mockup > img {
    width: 100%;
}
/*--- blog post ---*/
.blog-grid {
    border: 1px solid #eee;
    border-radius: 8px;
    display: inline-block;
    padding: 10px;
    width: 100%;
}
.postby {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 20px;
    padding-left: 0;
    width: 100%;
}
.postby > li {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
}
.postby > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.postby > li figure img {
    border: 1px solid #ccc;
    border-radius: 100%;
    max-width: 30px;
}
.postby > li:last-child {
    margin-right: 0;
}
.postby > li span {
    color: #535165;
    font-size: 12px;
    font-weight: 500;
}
.postby > li > i {
    color: #535165;
    margin-right: 3px;
}
a.date {
    border-radius: 5px;
    color: #fff;
    float: left;
    height: 60px;
    margin-right: 10px;
    padding: 8px;
    text-align: center;
    vertical-align: top;
    width: 50px;
}
.blog-grid .blog-title {
    display: inline-block;
    font-size: 22px;
    margin-bottom: 0;
    width: 80%;
}
.blog-grid .blog-title > a {
    color: #1f273f;
    display: inline-block;
    transition: all 0.2s linear 0s;
    width: 100%;
	font-size: 20px;
}
.blog-grid > figure {
    display: inline-block;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
}
.blog-grid > figure img {
    border-radius: 6px;
    transition: all 0.3s linear 0s;
}
.blog-grid:hover > figure img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.sponsors-logo li {
    border-bottom: 1px solid #ccc;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.funfact-counter {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.funfact-counter > i {
    background: rgba(8, 141, 205, 0.6) none repeat scroll 0 0;
    border: 2px solid;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 30px;
    height: 70px;
    line-height: 70px;
    width: 70px;
}
.counter {
    display: inline-block;
    font-size: 34px;
    font-weight: 500;
    line-height: normal;
    line-height: initial;
    margin-top: 10px;
    width: 100%;
}
.funfact-counter > em {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
	color: #82828e;
}
.countdonw-box {
    display: inline-block;
    width: 100%;
}
/*--- blog list style page --*/
.blog-posts {
    display: inline-block;
    margin-bottom: 50px;
    width: 100%;
}
.main-wraper .blog-posts:nth-last-child(2) {
    margin-bottom: 0;
}
.blog-posts > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
	max-width: 340px;
}
.blog-post-meta {
    display: inline-block;
    max-width: 50%;
    padding-left: 15px;
    vertical-align: middle;
}
.blog-post-meta > span {
    color: blue;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 10px;
    text-transform: capitalize;
	margin-top: 5px;
}
.blog-post-meta > h4 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    width: 100%;
}
.blog-post-meta > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 6px;
    padding-left: 0;
    width: 100%;
}
.blog-post-meta > ul > li {
    color: #92929e;
    display: inline-block;
    margin-right: 20px;
}
.blog-post-meta > ul > li a {
    display: inline-block;
    font-size: 13px;
    padding-left: 5px;
}
.blog-post-meta > a.button {
    float: right;
	border: 1px solid;
}
.blog-post-meta > a.button:hover{
	background: #deebf3;
}
.main-wraper .uk-pagination {
    margin: 0;
}
.blog-posts > figure img {
    border-radius: 6px;
    width: 100%;
}
/*--- feature area ---*/
.feature-meta {
    display: inline-block;
    padding: 180px 0 60px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
}
.feature-meta > h1 {
    color: #fff;
    display: inline-block;
    font-size: 55px;
    font-weight: 500;
    max-width: 60%;
    width: 100%;
	margin-bottom: 20px;
}
.feature-meta h1 span {
    border-bottom: 2px solid;
    display: inline-block;
    line-height: 50px;
}
.feature-meta > h3 {
    color: #fff;
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
}
.feature-meta .main-btn {
    padding: 13px 45px;
}
.main-btn {
    background: rgba(8,141,205,.6);
    border: 2px solid transparent;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 10px 26px;
	transition: all 0.2s linear 0s;
}
.main-btn:hover {
	background: #055f8b;
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
	color: #fff;
}
[data-ripple] > .ripple {
    border-radius: 35px !important;
}
header {
    display: inline-block;
    width: 100%;
	z-index: 999;
	position: relative;
}
header.transparent .topbar{
	background: transparent;
	border-bottom: 0;
}
.transparent .topbar > ul > li a {
    color: #eee;
}
.transparent .topbar > ul > li a.join-butn {
    border: 1px solid;
    border-radius: 48px;
    line-height: normal;
    line-height: initial;
    padding: 8px 20px;
    vertical-align: middle;
}
.topbar {
    border-bottom: 1px solid #e1e8ed;
    display: inline-block;
    width: 100%;
	background: #f5f5f5;
	padding: 10px 15px;
}
.transparent  .topbar > .logo img {
    max-width: 100px;
}
.transparent  .topbar > .logo span {
    color: #fff;
}
.transparent .searches form.active input {
    background: #fff none repeat scroll 0 0;
    color: #3e3f5e;
}
.logo {
    display: inline-block;
    vertical-align: middle;
}
.logo > img {
    max-width: 40px;
	vertical-align: middle;
	display: inline-block;
}
.logo > span {
    color: #1d3554;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 25px;
    font-weight: 700;
    padding-left: 5px;
    vertical-align: middle;
}
.topbar > ul {
    float: right;
    line-height: 50px;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
    vertical-align: middle;
}
.topbar > ul > li {
    display: inline-block;
    margin: 0 15px;
    vertical-align: middle;
	position: relative;
}
.topbar > ul > li > a {
    display: inline-block;
    text-transform: capitalize;
}
.topbar > ul > li > a img {
    width: 20px;
}
.topbar.is_stuck{
	z-index: 999999;
	padding-top: 0;
	padding-bottom: 0;
}
.topbar.is_stuck .user-avatar{
    top: 6px;
}
.topbar ul.web-elements > li > .user-dp{
	display: block;
}
.user-dp {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #088dcd;
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    line-height: initial;
    padding: 3px 10px 3px 4px;
    vertical-align: middle;
}
.web-elements .user-dp:hover {
    background: #e3f0f8 none repeat scroll 0 0;
}
.user-dp > a > img {
    border-radius: 100%;
    max-width: 30px;
}
.user-dp .name {
    display: inline-block;
    vertical-align: middle;
    width: auto;
}
.user-dp .name > h4 {
    color: #3e3f5e;
    display: inline-block;
    font-size: 13px;
    margin-top: 0;
    width: auto;
}
.user-avatar.timeline > img{
    max-width: 40px;
    padding: 1px;
}
.topbar.is_stuck .user-avatar > img{
	max-width: 42px;
}
.topbar.is_stuck .searches{
    margin-top: 4px;
}
.user-avatar.timeline{
    top: 10px;
	width: 20%;
}
.topbar.is_stuck .logo > img {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.user-avatar.timeline .name {
    color: inherit;
    display: inline-block;
    padding-bottom: 0;
    padding-left: 0;
    text-align: left;
    vertical-align: middle;
    width: 78%;
}
.user-avatar.timeline > img {
    display: inline-block;
    vertical-align: middle;
}
.user-avatar.timeline .name > h4 {
    font-size: 13px;
    margin-top: 0;
    color: inherit;
    line-height: 18px;
	font-weight: 500;
}
.user-avatar.timeline .name > span {
    font-size: 12px;
	color: #7d7e9e;
}
.searches {
    display: inline-block;
    margin-left: 30px;
    vertical-align: middle;
    width: 400px;
	margin-top: 5px;
}
.searches > form {
    display: inline-block;
    position: relative;
    width: 100%;
}
.searches input {
    border: 1px solid #e1e8ed;
    border-radius: 30px;
    padding: 10px 20px 10px 43px;
    width: 100%;
	transition: 0.1s ease 0s;
}
.searches > form button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    color: inherit;
    font-size: 18px;
    left: 10px;
    position: absolute;
    top: 8px;
}
.searches input:focus,
.searches > form.active > input{
	border: 0 none;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 9px 10px rgba(0, 0, 0, 0.2);
}
.cancel-search {
    background: #f6f6f6 none repeat scroll 0 0;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    opacity: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 7px;
    visibility: hidden;
    width: 30px;
	transition: all 0.2s linear 0s;
}
.cancel-search:hover {
    background: #e6e6e6 none repeat scroll 0 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.searches > form.active .cancel-search {
    opacity: 1;
    visibility: visible;
}
.searches > form.active button {
    opacity: 0;
    visibility: hidden;
}
.searches > form.active input {
    padding-left: 15px;
}
.recent-search {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    left: 0;
    padding: 10px;
    position: absolute;
    top: 98%;
    width: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.1s ease 0s;
}
.recent-search.active{
	opacity: 1;
	visibility: visible;
}
.recent-searches {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.so-history {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
    width: 100%;
}
.so-history > li {
    background: #fff none repeat scroll 0 0;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
    position: relative;
    transition: all 0.2s linear 0s;
    vertical-align: middle;
    width: 100%;
}

.trash {
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
	color: #b2b2be;
}

.so-history > li:hover {
    background: #e4eef4 none repeat scroll 0 0;
}
.so-history > li:last-child {
    margin-bottom: 0;
}
.transparent .searches input {
    background: rgba(5, 10, 40, 0.7) none repeat scroll 0 0;
    color: #fff;
	border-color: #050a28;
}
/*--- home4 parallex section ---*/
.welcome-parallax {
    display: inline-block;
    text-align: center;
    width: 100%;
    z-index: 2;
    position: relative;
}
.welcome-parallax > h2 {
    color: #fff;
    display: inline-block;
    font-size: 44px;
    font-weight: 300;
    text-transform: uppercase;
    width: 100%;
}
.welcome-parallax > span {
    color: #fff;
    display: inline-block;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
}
.welcome-parallax .new-btn {
    color: #fff;
    font-weight: 500;
    margin-top: 30px;
    padding: 18px 50px;
}
.welcome-parallax > i {
    display: inline-block;
    margin-bottom: 25px;
}
.welcome-parallax .main-btn {
    padding: 14px 32px;
}
/*--- newsletter section ---*/
.newsletter-sec {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 30px 60px;
    width: 100%;
}
.newsletter-sec > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.newsletter-sec > form {
    float: right;
    margin-top: 30px;
    position: relative;
    text-align: right;
    width: 60%;
}
.newsletter-sec > .leter-meta {
    display: inline-block;
    padding-left: 20px;
    vertical-align: bottom;
}
.leter-meta > span {
    font-weight: 500;
    text-transform: capitalize;
}
.leter-meta > h3 {
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
}
.newsletter-sec form input {
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 15px 30px;
    width: 70%;
	background: #f5f5f5;
}
.newsletter-sec form .main-btn {
    font-size: 30px;
    padding: 1px 20px;
    position: absolute;
    right: 0;
    top: 1px;
}

.verticle-center {
    display: table;
    height: 100%;
    width: 100%;
}
.measure {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
.measure > i {
    display: inline-block;
    font-size: 50px;
}
.measure > h2 {
    display: block;
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 30px;
    max-width: 90%;
}
.measure > p {
    display: inline-block;
    font-size: 18px;
    max-width: 80%;
    width: 100%;
	margin-bottom: 0;
}
.measure.right {
    padding-left: 40px;
}
.measure.right a {
    border: 1px solid #088dcd;
    border-radius: 30px;
    display: inline-block;
    margin-bottom: 8px;
	margin-right: 4px;
    padding: 5px 15px;
	transition: all 0.2s linear 0s;
}
.measure.right a:hover{
	background: rgba(8, 141, 205, 0.6) none repeat scroll 0 0;
	color: #fff;
}
/*--- timeline page ---*/
.user-avatar {
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 15px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}
.user-avatar > img {
    border: 1px solid #e0e0e0;
    border-radius: 100%;
    max-width: 130px;
    padding: 8px;
}
.topbar ul.web-elements > li > a {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #088dcd;
    border-radius: 100%;
    height: 37px;
    line-height: 37px;
    text-align: center;
    width: 37px;
}
.topbar ul.web-elements > li {
    margin: 0 5px;
	position: relative;
}
.topbar ul.web-elements > li > a > i {
    display: inline-block;
    font-size: 16px;
    line-height: normal;
    line-height: initial;
    vertical-align: text-bottom;
}
.topbar ul.web-elements > li > span {
    background: #ff7373 none repeat scroll 0 0;
    color: #fff;
	height: 8px;
	width: 8px;
	border-radius: 100%;
	position: absolute;
	top: 8px;
	right: 1px;
}
.topbar ul.web-elements > li:hover .dropdown{
	opacity: 1;
	visibility: visible;
}
.dropdown {
    background: #fff none repeat scroll 0 0;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: inline-block;
    line-height: normal;
    line-height: initial;
    list-style: outside none none;
    margin-bottom: 0;
    padding: 15px 15px 10px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 340px;
    z-index: 9;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s linear 0s;
}
.dropdown > li {
    display: inline-block;
    vertical-align: top;
    width: 31.6%;
    margin-right: 4px;
    margin-bottom: 8px;
}
.dropdown > li:nth-child(3n) {
    margin-right: 0;
}
.dropdown > li:last-child{
	margin-bottom: 0
}
.dropdown > li > a {
    border-radius: 5px;
    color: #1f273f;
    display: inline-block;
    font-size: 12px;
    padding: 10px 6px;
    text-align: center;
    width: 100%;
}
.dropdown > li > a:hover{
	background: #deebf3;
}
.dropdown > li > a.logout {
    background: #ff7373;
    border-color: #ff2727;
    color: #fff;
}
.dropdown > li > a i {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
}
.dropdown > li.logout {
    vertical-align: middle;
    width: 100%;
}
.dropdown > li.logout > a {
    background: #deebf3 none repeat scroll 0 0;
    display: inline-block;
    padding: 7px 0;
}
.dropdown > li.logout > a i {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.dropdown > li.logout > a:hover{
	background: #ff7373;
    color: #fff;
}
.dropdown > li.logout > a:hover i{
	color: #fff;
}
.no-pad > ul, .no-pad > .folowerz {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.stars {
    display: flex;
    left: 70px;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    position: absolute;
    top: 40px;
    width: 30%;
	z-index: 2;
}
.folowerz {
    color: #fff;
    font-weight: 500;
    position: absolute;
    right: 70px;
    top: 40px;
	z-index: 2;
}
.name {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 100%;
	z-index: 2;
	position: relative;
}
.name > h4 {
    font-weight: 500;
    margin-bottom: 0;
    margin-top: -10px;

	color: #fff;
}
.stars > li {
    color: #fec42d;
    font-size: 16px;
}
/*--- user post ---*/
.main-wraper, aside .widget {
   
    border: 1px solid #e1e8ed;
    border-radius: 5px;
    display: block;
    margin-bottom: 30px;
    padding: 15px 20px 20px;
    position: relative;
    width: 100%;
    z-index: 9;
}
.friend-info {
    display: inline-block;
    position: relative;
    width: 100%;
}
.friend-info > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 40px;
    margin-top: 0;
	position: relative;
}
.friend-info > figure > em {
    bottom: -3px;
    font-style: normal;
    position: absolute;
    right: -4px;
}
.friend-info > figure img{
	border-radius: 100%;
	border: 1px solid #cacaca;
}
.friend-info > figure > i {
    border: 2px solid #fec42d;
    border-radius: 100%;
    color: #fec42d;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    width: 40px;
}
.friend-name {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    width: 91.3%;
}
.friend-name > ins em {
    font-style: normal;
    text-decoration: underline;
}
.more {
    float: right;
    position: relative;
}
.more-post-optns::before {
    background: #e1ebf6 none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 28px;
    left: 50%;
    position: absolute;
    top: 51%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    transition: all 0.2s linear 0s;
    width: 28px;
    z-index: 1;
}
.more-post-optns {
    cursor: pointer;
    display: inline-block;
    position: relative;
}
.more-post-optns > i {
    position: relative;
    z-index: 2;
	font-size: 18px;
}
.more-post-optns > ul {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    opacity: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 100%;
    -webkit-transform: translate(5px);
            transform: translate(5px);
    transition: all 0.09s linear 0s;
    visibility: hidden;
    width: 220px;
    z-index: 9;
}
.more-post-optns > ul > li {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 7px;
    width: 100%;
	font-weight: 500;
	color: #535165;
}
.more-post-optns > ul > li:last-child{margin-bottom: 0;}
.more-post-optns > ul > li i {
    color: #92929e;
    display: inline-block;
    font-size: 14px;
    margin-right: 8px;
    transition: all 0.1s linear 0s;
    vertical-align: middle;
}
.more-post-optns > ul > li span {
    color: #92929e;
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    padding-left: 23px;
    width: 100%;
}
.more-post-optns:hover::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}
.more-post-optns:hover > ul {
    opacity: 1;
    right: -1px;
    top: 100%;
    -webkit-transform: translate(0);
            transform: translate(0);
    visibility: visible;
}
.reply {
    float: right;
    font-size: 13px;
    margin-top: 19px;
}
.friend-name > ins {
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    width: 90%;
	background: none;
}
.friend-name > ins > a {
    font-size: 14px;
    font-weight: 500;
}
.friend-name > span {
    color: #999;
    float: left;
    font-size: 12px;
    text-transform: capitalize;
    width: 90%;
}
.post-meta {
    float: left;
    margin-top: 15px;
    position: relative;
    width: 100%;
}
.post-meta iframe {
    margin-bottom: 15px;
	width: 100%;
}
.post-meta > em {
    display: inline-block;
    font-size: 13px;
    font-style: normal;
    width: 100%;
}
.post-meta > em > a {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.post-meta > figure > span {
    color: #92929e;
    font-size: 11px;
    text-transform: capitalize;
}
.post-meta > figure > a > img{
	width: 100%;
}
.post-meta > h4 {
    display: inline-block;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
}
.we-video-info {
    border-top: 1px solid #ede9e9;
    float: left;
    padding: 8px 0 0;
    position: relative;
    width: 100%;
    z-index: 0;
}
.we-video-info > ul {
    float: left;
    line-height: 27px;
    margin-bottom: 0;
    margin-top: 13px;
    padding-left: 5px;
    width: 66%;
}
.we-video-info > ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 35px;
    vertical-align: middle;
}
.we-video-info > ul li span {
    font-size: 16px;
    font-weight: 400;
    position: relative;
}
.we-video-info > ul li span i {
    font-size: 18px;
	color: #82828e;
}
.we-video-info > ul li span ins {
    font-size: 11px;
    left: 20px;
    position: absolute;
    text-decoration: none;
    top: -5px;
    background: none;
    line-height: normal;
    line-height: initial;
}
.post-meta > figure {
    display: inline-block;
    margin: 0;
    position: relative;
    width: 100%;
}
.img-bunch {
    float: left;
    position: relative;
    width: 100%;
}
.img-bunch > .row {
    margin: 0;
}
.img-bunch > .row > div {
    padding: 0;
}
.img-bunch figure {
    float: left;
    margin: 0;
    padding: 1px;
    position: relative;
    width: 100%;
}
.img-bunch figure > a > img {
    width: 100%;
}
.img-bunch figure > a {
    display: inline-block;
    width: 100%;
}
.more-photos::before {
    background: rgba(8, 141, 205, 0.8) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}
.more-photos > span {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	font-size: 30px;
	color: #fff;
}
.post-title {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 0;
    width: 100%;
}
/*--- event widget ---*/
.rec-events {
    border-radius: 5px;
    color: #fff;
    margin: 0 auto 20px;
    overflow: hidden;
    padding: 15px 10px;
    position: relative;
    text-align: center;
    width: 100%;
}
.rec-events:last-child{margin-bottom: 0;}
.rec-events > i {
    display: inline-block;
    font-size: 40px;
    margin-bottom: 10px;
}
.bg-purple {
    background: #8c6ad2 none repeat scroll 0 0;
}
.rec-events > h6 {
    margin-bottom: 0;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
}
.rec-events > img {
    opacity: 0.4;
    position: absolute;
    right: -30px;
    top: 10px;
}
.see-all {
    float: right;
    font-size: 11px;
    margin-top: 2px;
}
/*--- following ---*/
.followers {
    max-height: 260px;
    position: relative;
}
.sidebar .widget > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}
.sidebar .widget li {
    display: inline-block;
    margin-bottom: 14px;
    position: relative;
    width: 100%;
}
.sidebar .widget > ul > li:last-child{margin-bottom: 0;}
.sidebar .widget li i{margin-right: 5px;}
.followers > li figure {
    display: inline-block;
    margin-bottom: 0;
    max-width: 40px;
    min-width: 40px;
    vertical-align: middle;
    width: 40px;
}
.followers > li figure img{
	border-radius: 100%;
}
.friend-meta {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    width: 79%;
}
.friend-meta > h4 {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    width: 60%;
}
.friend-meta > a {
    float: right;
    font-size: 12px;
    margin-top: 3px;
    position: relative;
}
.friend-meta span {
    color: #9b9b9b;
    display: inline-block;
    font-size: 11px;
    width: 100%;
}
.friend-meta h4 a {
    font-weight: 500;
}
/*--- popup ---*/
.popup-wraper,
.post-new-popup,
.wraper-invite,
.new-question-popup,
.createroom-popup,
.popup-add-card{
    background: rgba(248, 248, 248, 0.85) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.25s linear 0s;
    visibility: hidden;
    width: 100%;
    z-index: 999999;
}
.popup-wraper.active,
.post-new-popup.active,
.wraper-invite.active,
.new-question-popup.active,
.createroom-popup.active,
.popup-add-card.active{
    opacity: 1;
    visibility: visible;
}
.popup::after {
    border-bottom: 8px solid;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 1;
    position: absolute;
    width: 100%;
}
.popup {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    left: 50%;
    padding: 20px;
    position: absolute;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
	transition: all 0.2s linear 0.25s;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.popup-wraper.active .popup,
.post-new-popup.active .popup,
.wraper-invite.active .popup,
.new-question-popup .popup,
.createroom-popup .popup{
	top: 50%;
}
.popup-closed {
    cursor: pointer;
    font-size: 15px;
    position: absolute;
    right: 18px;
    top: 15px;
    z-index: 9;
}
.popup-closed > i {
    line-height: normal;
    line-height: initial;
}
.popup-closed::before {
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    transition: all 0.2s linear 0s;
    width: 30px;
}
.popup-closed:hover::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
	background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
    opacity: 1;
    visibility: visible;
}
.popup-meta {
    display: inline-block;
    width: 100%;
}
.popup-head {
    border-bottom: 1px solid #dedede;
    display: inline-block;
    padding-bottom: 10px;
    width: 100%;
	text-align: center;
}
.popup-head > h5 {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
}
.popup-head > h5 i {
    display: inline-block;
    font-size: 20px;
	vertical-align: inherit;
}
.send-message {
    display: inline-block;
    margin-top: 15px;
    position: relative;
    width: 100%;
}
.c-form {
    display: inline-block;
    position: relative;
    width: 100%;
}
.c-form > div input, .c-form > div textarea, .c-form > input, .c-form > textarea {
    background: #f8fafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 7px;
    color: #535165;
    font-size: 13px;
    margin-bottom: 20px;
    padding: 12px 20px;
    width: 100%;
}
.c-form input[type="checkbox"],
.c-form input[type="radio"]{
    margin-right: 5px;
    width: auto;
}
.c-form > label {
    margin-left: 5px;
    max-width: 75%;
    vertical-align: top;
	color: #7f879f;
}
.checkbox {
  width: 100%;
  margin: 8px auto;
  position: relative;
  display: block;
}
.checkbox label {
  cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0;
    min-height: 29px;
    padding-left: 25px;
    position: relative;
}
.checkbox label span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  margin: 0;
  width: 18px;
  height: 18px;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 100%;
  border: 2px solid #34C37A;
}
.checkbox label:after {
  border-bottom: 2px solid #34c37a;
    border-left: 2px solid #34c37a;
    content: "";
    display: block;
    height: 5px;
    left: 5px;
    position: absolute;
    top: 12px;
    -webkit-transform: rotate(-45deg) scale(0);
            transform: rotate(-45deg) scale(0);
    transition: -webkit-transform 0.25s ease 0s;
    transition: transform 0.25s ease 0s;
    transition: transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
    width: 9px;
}
.checkbox input[type="checkbox"] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
.checkbox input[type="checkbox"]:checked ~ label:before {
  border: 2px solid #34C37A;
}
.checkbox input[type="checkbox"]:checked ~ label:after {
  -webkit-transform: rotate(-45deg) scale(1);
          transform: rotate(-45deg) scale(1);
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: 0;
}
.send-message form .main-btn{float: right;}
.uploadimage {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
.uploadimage > i {
    font-size: 16px;
    position: relative;
    top: 1px;
}
.fileContainer {
    display: inline-block;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.fileContainer [type="file"] {
    cursor: pointer;
    display: block;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 100%;
}
/*--- side slide ---*/
.side-slide {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #e1e8ed;
    border-radius: 8px 0 0 8px;
    display: inline-block;
    height: 100vh;
    padding: 20px;
    position: fixed;
    right: -100%;
    top: 73px;
    width: 300px;
    z-index: 999;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s linear 0s;
}
.side-slide.active {
	visibility: visible;
	opacity: 1;
	right: 0;
}
.nav.nav-tabs.slide-btns li a {
    background: #e5e5e5 none repeat scroll 0 0;
    border-radius: 5px 5px 0 0;
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
    padding: 5px 10px;
}
.nav.nav-tabs.slide-btns {
    border: 0 none;
    line-height: 30px;
    margin: 0 0 20px;
}
.nav.nav-tabs.slide-btns li a.active {
    color: #fff;
}
.tab-content .tab-pane h4 {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
	display: inline-block;
	vertical-align: middle;
}
.post-detail {
    display: inline-block;
    width: 100%;
}
.post-detail > h4 {
    margin-bottom: 20px;
    width: 100%;
}
.post-detail > figure img {
    width: 100%;
	border-radius: 5px;
}
.pic-gallery img {
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
}
.pic-gallery .col-lg-6 > img {
    margin-bottom: 0;
}
.tab-content .tab-pane h4 i{
	color: #999;
	margin-right: 5px;
}
.tab-content .tab-pane > a {
    border: 1px solid;
    border-radius: 100%;
    float: right;
    height: 30px;
    line-height: 30px;
    margin-top: -5px;
    text-align: center;
    width: 30px;
}
/*--- mesg area ---*/
.new-messages,
.notificationz{
    border-top: 1px solid #eee;
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 30px;
    margin-top: 10px;
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
}
.new-messages > li,
.notificationz > li{
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.new-messages li figure,
.notificationz > li figure{
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    width: 50px;
}
.new-messages li figure img,
.notificationz > li figure img{
    border: 2px solid;
    border-radius: 100%;
}
.mesg-info {
    display: inline-block;
    font-size: 13px;
    padding-left: 8px;
    vertical-align: middle;
    width: 200px;
}
.mesg-info > span {
    display: inline-block;
    font-weight: 500;
    width: 100%;
}
.tab-content .tab-pane > a.main-btn {
    border-radius: 5px;
    color: #fff;
    height: auto;
    line-height: normal;
    line-height: initial;
    padding: 8px 10px;
    width: 100%;
	text-transform: uppercase;
}
/*--- login page ---*/
.authtication {
    float: left;
    height: 100vh;
    padding: 50px;
    position: relative;
    width: 45%;
}
.welcome-note {
    display: table-cell;
    z-index: 3;
    position: relative;
	vertical-align: middle;
}
ul.welcome-caro{margin-top: 60px;}
ul.welcome-caro li.welcome-box {
    display: inline-block;
    text-align: center;
    width: 100%;
}
ul.welcome-caro li.welcome-box figure {
    display: inline-block;
    width: 100%;
}
ul.welcome-caro li.welcome-box figure img {
    border-radius: 10px;
    width: 100%;
}
.welcome-caro .owl-dot.active {
    background: #fff none repeat scroll 0 0;
    border-color: #fff;
}
.welcome-box > h4 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
}
.welcome-box > p {
    color: #eee;
    display: inline-block;
    font-size: 16px;
    width: 100%;
}
.welcome-note > h1 {
    color: #fff;
    display: inline-block;
    font-size: 43px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
}
.welcome-note > p {
    color: #fff;
    font-size: 17px;
    line-height: 28px;
}
/*--- login form --*/
.auth-login {
    background: #fff none repeat scroll 0 0;
    float: left;
    height: 100vh;
    padding: 50px;
    width: 55%;
	position: relative;
	overflow: hidden;
}
.login-form {
    display: table-cell;
    padding: 0 100px;
    vertical-align: middle;
}
.login-form > h4,
.signup-form > h4{
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
}
.signup-form > h4{
	margin-bottom: 10px;
}
.login-form form button {
    float: right;
}
.mockup {
    left: 0;
    position: absolute;
    top: 0;
}
.mockup.right {
    left: auto;
    right: -10px;
}
.mockup.right-bottom {
    bottom: 0;
    left: auto;
    right: -10px;
    top: auto;
}
.mockup.left-bottom {
    bottom: 0;
    top: auto;
}
.mockup.left-center {
    left: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.mockup.right-center {
    left: auto;
    right: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.auth-login .logo {
    left: 50px;
    position: absolute;
    top: 50px;
}
/*--- suggested friends caro ---*/
.suggested-caro {
    list-style: outside none none;
    margin: 20px 0 0;
    padding-left: 0;
}
.suggested-caro li{
    display: inline-block;
    text-align: center;
    width: 100%;
}
.friendz {
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
	display: inline-block;
    text-align: center;
    width: 100%;
}
.suggested-caro li figure img,
.friendz figure img{
    border: 2px solid #088dcd;
    border-radius: 10px;
	max-width: 80px;
}
.suggested-caro li span,
.friendz span{
    display: inline-block;
    font-weight: 500;
    width: 100%;
}
.suggested-caro li ins,
.friendz ins{
    color: #088dcd;
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    width: 100%;
}
.suggested-caro li > a,
.friendz > a{
    background: #088dcd none repeat scroll 0 0;
    border-radius: 16px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    margin-top: 10px;
    padding: 5px 10px;
}
.suggested-caro li figure img,
.friendz figure img{
    display: inline-block !important;
    width: auto !important;
}
/*page loader spiner*/
.page-loader {
    position: fixed;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #088dcd;
    display: block;
}
.page-loader .fa {
    font-size: 2.5rem;
}
.page-loader,
.page-loader.visible {
    transition: 0.6s;
    opacity: 1;
    visibility: visible;
}
.page-loader.hidden {
    visibility: hidden;
    opacity: 0;
}
.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 40px;
  width: 80px;
  box-sizing: border-box;
}
.loader .loader-item {
  position: relative;
  float: left;
  height: 40px;
  width: 4px;
  margin: 0 2px;
  background-color: #ffffff;
}
.loader .loader-item:nth-child(1) {
  -webkit-animation: loader-item-1 2s linear infinite;
          animation: loader-item-1 2s linear infinite;
}
.loader .loader-item:nth-child(2) {
  -webkit-animation: loader-item-2 2s linear infinite;
          animation: loader-item-2 2s linear infinite;
}
.loader .loader-item:nth-child(3) {
  -webkit-animation: loader-item-3 2s linear infinite;
          animation: loader-item-3 2s linear infinite;
}
.loader .loader-item:nth-child(4) {
  -webkit-animation: loader-item-4 2s linear infinite;
          animation: loader-item-4 2s linear infinite;
}
.loader .loader-item:nth-child(5) {
  -webkit-animation: loader-item-5 2s linear infinite;
          animation: loader-item-5 2s linear infinite;
}
.loader .loader-item:nth-child(6) {
  -webkit-animation: loader-item-6 2s linear infinite;
          animation: loader-item-6 2s linear infinite;
}
.loader .loader-item:nth-child(7) {
  -webkit-animation: loader-item-7 2s linear infinite;
          animation: loader-item-7 2s linear infinite;
}
.loader .loader-item:nth-child(8) {
  -webkit-animation: loader-item-8 2s linear infinite;
          animation: loader-item-8 2s linear infinite;
}
.loader .loader-item:nth-child(9) {
  -webkit-animation: loader-item-9 2s linear infinite;
          animation: loader-item-9 2s linear infinite;
}
.loader .loader-item:nth-child(10) {
  -webkit-animation: loader-item-10 2s linear infinite;
          animation: loader-item-10 2s linear infinite;
}
.loader:after {
  content: 'SOCIMO....';
  font-size: 16px;
  font-family:  'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -32px;
  margin: auto;
}
@-webkit-keyframes loader-item-1 {
  1% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  11% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  21% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-1 {
  1% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  11% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  21% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-2 {
  7% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  17% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  27% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-2 {
  7% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  17% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  27% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-3 {
  13% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  23% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  33% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-3 {
  13% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  23% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  33% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-4 {
  19% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  29% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  39% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-4 {
  19% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  29% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  39% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-5 {
  25% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  35% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  45% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-5 {
  25% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  35% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  45% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-6 {
  31% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  41% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  51% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-6 {
  31% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  41% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  51% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-7 {
  37% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  47% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  57% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-7 {
  37% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  47% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  57% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-8 {
  43% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  53% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  63% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-8 {
  43% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  53% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  63% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-9 {
  49% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  59% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  69% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-9 {
  49% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  59% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  69% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes loader-item-10 {
  55% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  65% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  75% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes loader-item-10 {
  55% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  65% {
    -webkit-transform: scaleY(1.4);
            transform: scaleY(1.4);
  }
  75% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
/*--- ask question widget ---*/
.ask-question {
    display: inline-block;
    width: 100%;
}
.ask-question > i {
    color: #088dcd;
    display: inline-block;
    font-size: 30px;
    vertical-align: top;
}
.ask-question > h6 {
    display: inline-block;
    font-size: 13px;
    padding-left: 10px;
    vertical-align: top;
    width: 83%;
}
.ask-question > a {
    border: 1px solid #088dcd;
    border-radius: 30px;
    color: #088dcd;
    display: inline-block;
    font-size: 14px;
    margin-top: 20px;
    padding: 7px 10px;
    text-align: center;
    width: 100%;
    transition: all 0.2s linear 0s;
}
.ask-question > a:hover{
	color: #fff;
}
/*--- signup form ---*/
.signup-form {
    display: table-cell;
    vertical-align: middle;
	position: relative;
	padding: 0 40px;
}
.c-form h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0 20px;
}
.signup-form > h4 i, .login-form > h4 i {
    color: #3ca9fc;
}
.signup-form .c-form button {
    display: block;
    width: 100%;
}
.signup-form .c-form label {
    margin-bottom: 12px;
}
.gender {
    display: inline-block;
    margin-bottom: 25px;
    width: 100%;
}
.gender > label {
    margin-right: 20px;
}
/*--- load more ---*/
.loadmore {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.loadmore > a {
    color: #fff;
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-weight: 500;
	border-radius: 30px;
	max-width: 50%;
	border: 1px solid transparent;
	transition: all 0.2s linear 0s;
}
.loadmore > a:hover {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #088dcd;
    color: #088dcd;
}
/*--- new post popup ---*/
.post-new .uploadimage {
    border: 1px solid;
    border-radius: 5px;
    font-weight: 500;
    line-height: normal;
    line-height: initial;
    margin-bottom: 20px;
    padding: 6px 10px;
    text-align: center;
    width: 100%;
}
.post-new .uploadimage .fileContainer {
    margin-bottom: 0;
}
.post-new .c-form .chosen-container {
    margin-bottom: 20px;
    width: 100% !important;
}
.post-new .c-form .chosen-container .chosen-single {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e4e4e4;
    color: #535165;
    height: 45px;
    line-height: 45px;
    padding-left: 15px;
	box-shadow: none;
}
.post-new .chosen-container-single .chosen-single div {
    top: 10px;
}
.post-new .c-form .main-btn {
    border-radius: 5px;
    padding: 6px;
    width: 100%;
	margin-bottom: 20px;
}
.post-new .c-form textarea, .post-new .c-form input {
    background: #fff none repeat scroll 0 0;
}
.post-new .c-form {
    margin-top: 20px;
}
.post-new .fileContainer [type="file"] {
    margin: 0;
    padding: 0;
}
/*--- post detail page ---*/
.top-area {
    display: inline-block;
    position: relative;
    width: 100%;
}
.post-subject {
    display: inline-block;
    padding-top: 20px;
    position: relative;
    width: 100%;
    z-index: 3;
    padding: 60px 0;
}
.tagz {
    background: #c5e8e5 none repeat scroll 0 0;
    border-radius: 30px;
    color: #007478;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 20px;
    padding: 3px 12px;
    text-transform: capitalize;
}
.post-subject > h4 {
    color: #fff;
    display: block;
    max-width: 70%;
    width: 100%;
    font-size: 22px;
}
.sharing-options {
    display: inline-block;
    list-style: outside none none;
    margin-top: 25px;
    padding-left: 0;
    width: 100%;
    padding-right: 0;
}
.sharing-options > li {
    display: inline-block;
    font-size: 12px;
    margin-right: 12px;
	color: rgba(255,255,255,.8);
}
.sharing-options > li a {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border: 2px solid #088dcd;
    border-radius: 100%;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    width: 40px;
	color: #088dcd;
	transition: all 0.2s linear 0s;
}
a.download-btn {
    background: #fff;
    border: 3px solid #088dcd;
    border-radius: 30px;
    color: #088dcd;
    font-size: 14px;
    font-weight: 500;
    padding: 9px 25px;
    position: absolute;
    right: 0;
    top: 30px;
	z-index: 2;
}
/*--- post analytics widget ---*/
.widget-analytics > li > span {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #66bef5;
    border-radius: 20px;
    color: #088dcd;
    float: right;
    font-size: 12px;
    padding: 1px 10px;
}
/*--- detail tab section ---*/
.top-area .post-subject {
    padding-bottom: 0;
}
.post-detail-btn {
    border-bottom: 0 none;
	margin-top: 50px;
	position: relative;
	z-index: 2;
	margin-left: 16px;
}
.post-detail-btn li.nav-item a {
    display: inline-block;
    padding: 5px 15px;
    position: relative;
	color: #eee;
    font-weight: 500;
}
.post-detail-btn li.nav-item {
    margin-right: 30px;
	position: relative;
}
.post-detail-btn li.nav-item:last-child{
	margin-right: 0;
}

.post-subject ul.post-detail-btn {
    margin-left: 0;
}
.nav-item > span {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    font-size: 10px;
    padding: 0 5px;
    position: absolute;
    right: -5px;
    top: -4px;
}
.post-detail-btn li.nav-item a.active {
    background: #fff;
	color: #088dcd;
	border-radius: 13px 13px 0 0;
}
.post-detail-btn li.nav-item a.active::before {
	border-bottom: 23px solid #ffffff;
    border-left: 16px solid rgba(0, 0, 0, 0);
    border-top: 12px solid rgba(0, 0, 0, 0);
    content: "";
    height: 0;
    left: -15px;
    position: absolute;
    top: -4px;
    width: 0;
}
.post-detail-btn li.nav-item a.active::after {
	border-left: 16px solid #ffffff;
    border-top: 23px solid rgba(0, 0, 0, 0);
    content: "";
    height: 0;
    position: absolute;
    right: -15px;
    top: 8px;
    width: 0;
}
.tab-content {
    margin-bottom: 0px;
}
.tab-pane .user-post .friend-name {
    width: 91%;
}
/*--- tags ul ---*/
.tags-title {
    display: inline-block;
    font-weight: 500;
    vertical-align: top;
}
.tags {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 30px;
    padding-left: 20px;
    vertical-align: top;
}
.tags > li {
    display: inline-block;
    vertical-align: middle;
}
.tags > li a {
	background: #c5e8e5;
	color: #007478;
    border: 1px solid #40b4b8;
    border-radius: 30px;
    display: inline-block;
    font-size: 13px;
    margin: 0 2px;
    padding: 3px 17px;
    text-transform: capitalize;
}
/*--- comment area ---*/
.comment-area {
    display: inline-block;
    width: 100%;
}
.comment-title {
    display: inline-block;
    font-size: 28px;
    font-weight: 800;
    line-height: normal;
    line-height: initial;
    margin-bottom: 20px;
    text-transform: capitalize;
    width: 100%;
}
.comments {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0px;
    padding-left: 0;
    width: 100%;
}
.comment-box {
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    display: inline-block;
    padding: 20px;
}
.comments > li {
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}
.comments > li:last-child {
    margin-bottom: 0;
}
.commenter-photo {
    display: table-cell;
    vertical-align: top;
}
.commenter-meta {
    display: table-cell;
    padding-left: 15px;
    position: relative;
    vertical-align: middle;
}
.commenter-photo > img {
    border: 2px solid ;
    border-radius: 100%;
    padding: 3px;
    width: auto;
}
.comment-titles h6 {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    vertical-align: top;
}
.comment-titles > span {
    color: #92929e;
    font-size: 11px;
    margin-left: 6px;
    margin-right: 22px;
    text-transform: uppercase;
}
.comment-titles a.reply {
    border-radius: 30px;
    display: inline-block;
    font-size: 13px;
    padding: 2px 18px;
    text-transform: capitalize;
    vertical-align: middle;
	margin-top: 0;
	color: #fff;
}
.comment-titles a.reply:hover {
    background: #000 none repeat scroll 0 0;
    color: #fff;
}
.commenter-meta > p {
    color: #666;
    float: left;
    font-size: 13px;
    font-weight: normal;
    line-height: 24px;
    padding-top: 10px;
    width: 90%;
	margin-bottom: 0;
}
.comments > li > ul {
    float: left;
    list-style: outside none none;
    margin-top: 17px;
    padding-left: 60px;
    width: 100%;
}
.comment-form {
    float: left;
    margin-top: 65px;
    width: 100%;
}
/*--- charts sparkline ---*/
.quick-all {
	background: #f9f9f9;
    border: 1px solid #ede9e9;
    border-radius: 5px;
    float: left;
    margin-bottom: 30px;
    position: relative;
    text-align: left;
    width: 100%;
}
.sparkline {
    display: inline-block;
    vertical-align: middle;
}
.spark-meta {
    display: inline-block;
    padding: 0 40px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}
.quick-all .spark-meta > h4 {
    float: right;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 3px;
}
.quick-all .spark-meta > p {
    float: left;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}
/*--- about section ---*/
.main-title {
    border-bottom: 1px solid #eaeaea;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 10px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
}
.main-title > i{color: #92929e;}
.main-title > a {
    float: right;
    font-size: 11px;
    margin-top: 4px;
	text-decoration: underline;
}
.main-title > span {
    color: #898989;
    font-size: 14px;
}
.main-title::before {
    background: red none repeat scroll 0 0;
    content: "";
    height: 70%;
    left: -20px;
    position: absolute;
    top: 0;
    width: 3px;
}
.exp-col {
    border-bottom: 1px dashed #dadada;
    display: inline-block;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
}
.exp-meta {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
}
.exp-meta > h5 {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: capitalize;
    width: 100%;
	color: #4f576f;
}
.exp-meta > p {
    display: inline-block;
    font-size: 13px;
    width: 100%;
}
.exp-meta > ins {
    color: #5a6381;
    padding-left: 10px;
}
.main-wraper .exp-col:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.top-area .download-btn {
    right: 60px;
    top: 80px;
}
.post-subject .download-btn{
	right: 0;
}
/*--- researches images --*/
.row.merged-10 > div {
    padding: 0 10px;
}
.row.merged-10 {
    margin-left: -10px;
    margin-right: -10px;
}
/*--- language area ---*/
.lang {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
}
.lang > h6 {
    display: inline-block;
    font-weight: 500;
    width: 100%;
}
.dis-n-exp {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.dis-n-exp > h6 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}
.dis-n-exp > span {
    border: 1px solid #ccc;
    border-radius: 30px;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 7px 15px;
    text-transform: capitalize;
}
/*--- message box ---*/
.message-box {
    display: inline-block;
    position: relative;
    width: 100%;
}
.message-header {
    max-width: 687px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 12px;
}
.useravatar {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 85px;
    margin-bottom: 8px;
}
.useravatar > img {
    border: 1px solid #aaaaaa;
    border-radius: 100%;
    display: inline-block;
    padding: 2px;
    max-width: 40px;
}
.useravatar > span {
    display: block;
    font-size: 13px;
    font-weight: 700;
    margin-top: 4px;
    width: 100%;
}
.status::before {
    border-radius: 100%;
    content: "";
    height: 10px;
    left: 20px;
    position: absolute;
    top: 0;
    width: 10px;
}
.status.online::before {
    background: #7fba00 none repeat scroll 0 0;
}
.status.away::before {
    background: #ffd300 none repeat scroll 0 0;
}
.status.offline::before {
    background: #bcbcbc none repeat scroll 0 0;
}
.useravatar.active > span {
    font-weight: 500;
}
ul.chatting-area {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    max-height: 322px;
    overflow-x: hidden;
    padding: 40px 15px 10px 0;
    position: relative;
    width: 100%;
}
ul.chatting-area li {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
ul.chatting-area li > figure {
    display: inline-block;
    margin-bottom: 0;
    max-width: 32px;
    min-width: 32px;
    vertical-align: top;
    width: 32px;
}
ul.chatting-area li > figure img{
	border-radius: 100%; 
	border: 1px solid;
	padding: 2px;
}
ul.chatting-area li.me {
    text-align: right;
}
ul.chatting-area > li.me > figure {
    float: right;
}
ul.chatting-area > li.me p {
    margin-left: 0;
    margin-right: 10px;
    text-align: left;
}
ul.chatting-area > li.you p {
    background: rgba(255, 255, 255, 0.65) none repeat scroll 0 0;
}
ul.chatting-area > li p {
    background: rgba(39, 170, 225, 0.1) none repeat scroll 0 0;
    color: #434343;
    display: inline-block;
    font-size: 14px;
    line-height: normal;
    line-height: initial;
    margin-bottom: 0;
    margin-left: 10px;
    padding: 5px 8px;
    position: relative;
    vertical-align: top;
    max-width: 55%;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0,0,0,.2);
}
.message-text-container > form {
    display: inline-block;
    position: relative;
    width: 94%;
}
.message-text-container textarea {
    border: 1px solid #eaeaea;
    border-radius: 50px;
    box-sizing: content-box;
    color: #7f7f7f;
    display: inline-block;
    line-height: 50px;
    overflow: hidden;
    padding-left: 46px;
    resize: none;
    vertical-align: middle;
    width: 84%;
	padding-right: 50px;
}
.message-text-container form button {
    border-radius: 100%;
    height: 40px;
    padding: 0;
    position: absolute;
    right: 13px;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 40px;
	border: none;
	color: #fff;
	font-size: 20px;
}
.emojie {
    left: 13px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.emojie > img {
    border-radius: 100%;
    cursor: pointer;
    max-width: 24px;
}
.smiles-bunch::before {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid #fff;
    bottom: -7px;
    content: "";
    left: 18px;
    position: absolute;
    width: auto;
}
.smiles-bunch.active {
    display: block;
}
.smiles-bunch {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    display: none;
    font-size: 13px;
    padding: 5px;
    position: absolute;
    left: 0;
    text-align: center;
    top: -70px;
    width: 220px;
	box-shadow: 2px 1px 9px rgba(0,0,0,.2);
}
.smiles-bunch > i img {
    max-width: 25px;
	transition: all 0.2s linear 0s;
}
.smiles-bunch > i {
    display: inline-block;
    margin: 3px;
	cursor: pointer;
}
.smiles-bunch > i:hover img{
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
.chat-header {
    background: #efefef none repeat scroll 0 0;
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    position: relative;
    width: 100%;
}
.chat-header > h6 {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 0;
    padding-left: 20px;
    text-transform: capitalize;
}
.corss {
    float: right;
    width: auto;
}
.chat-header .status::before {
    top: 18px;
}
.corss > span {
    color: red;
    cursor: pointer;
    font-size: 15px;
    margin-left: 10px;
}
.date {
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px dotted #dedede;
    display: inline-block;
    padding: 4px 0;
    text-align: center;
    width: 100%;
	color: #f0506e;
	font-size: 11.5px;
}
.more-attachments.active{
	color: #fff;
}
.attach-options .closed {
    color: #333;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 10px;
    transition: all 0.2s linear 0s;
}
.attach-options .closed:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.more-attachments {
    background: #fff none repeat scroll 0 0;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    line-height: 36px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 36px;
	box-shadow:  0 1px 3px rgba(0,0,0,.2);
	transition: all 0.4s linear 0s;
}
.more-attachments.active {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.attach-options {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 10px;
    bottom: 56px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    left: 0px;
    opacity: 0;
    padding: 25px;
    position: absolute;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: all 0.3s ease-in-out 0s;
    visibility: hidden;
    width: 300px;
}
.attach-options > a {
    border-bottom: 1px solid #eaeaea;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.attach-options > a i {
    color: red;
    font-size: 17px;
    margin-right: 10px;
}
.attach-options > a:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.attach-options > a:hover {
    color: red;
}
.attach-options.active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
}
/*--- notification page ---*/
.notification-box > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
}
.notification-box {
    display: inline-block;
    width: 100%;
}
.notification-box > ul > li {
    border-bottom: 1px solid #e1e8ed;
    display: inline-block;
    margin-bottom: 15px;
	padding-bottom: 15px;
    width: 100%;
	position: relative;
}
.notification-box > ul li figure {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    max-width: 45px;
    min-width: 45px;
    vertical-align: middle;
}
.notification-box > ul > li figure img {
    border: 1px solid #cacaca;
    border-radius: 100%;
    padding: 1px;
}
.notifi-meta {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    vertical-align: middle;
    width: 86%;
}
.notifi-meta > p {
    color: #1f273f;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.notifi-meta > span {
    font-size: 12px;
	color: #92929e;
}
.notifi-meta > span > i {
    font-size: 13px;
}
.notification-box > ul > li:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.notification-box > ul li > i.del {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    opacity: 0;
    position: absolute;
    right: 30px;
    top: 0;
    transition: all 0.2s linear 0s;
    vertical-align: top;
    visibility: hidden;
}
.notification-box > ul li:hover > i.del {
    opacity: 1;
    visibility: visible;
}
.more-options {
    display: inline-block;
    float:right;
    position: relative;
}
.more-options > i {
    color: #999 !important;
    font-size: 16px;
}
/*--- university page ---*/
.university-tag {
    display: inline-block;
    position: relative;
    width: 100%;
}
.university-tag > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.university-tag > figure img {
    border: 2px solid #fff;
    border-radius: 100%;
    display: inline-block;
    padding: 6px;
	max-width: 120px;
}
.uni-name {
    display: inline-block;
    padding-left: 20px;
    vertical-align: middle;
}
.uni-name > h4 {
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
	font-size: 25px;
}
.university-tag .sharing-options {
    position: absolute;
    right: 6px;
    top: 0;
    width: auto;
}
.uni-name > span {
    color: #fff;
    font-weight: 500;
}
/*--- university info sec ---*/
.uni-info {
    display: inline-block;
    width: 100%;
}
.uni-info > ul {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 15px;
    width: 100%;
}
.uni-info > ul > li {
    border-bottom: 1px solid #eaeaea;
    float: left;
    margin-bottom: 10px;
    padding-bottom: 10px;
    width: 50%;
}
.uni-info > ul > li p {
    margin-bottom: 0;
}
/*--- department info  sec ---*/
.dept-info {
    display: inline-block;
    width: 100%;
}
.dept-info > ul {
    display: inline-block;
    margin: 0;
    padding-left: 15px;
    width: 100%;
}
.dept-info > ul > li {
    border-bottom: 1px solid #eaeaea;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 100%;
}
.dept-info > ul > li:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.dept-info > ul > li h6 {
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
	font-weight: 400;
}
.dept-info > ul > li span {
    color: #8e8e8e;
}
.dept-info > ul > li span i {
    font-style: normal;
    font-weight: 500;
}
/*--- university members --*/
.members {
    border: 1px solid #eaeaea;
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 10px;
}
.members > figure img {
    width: 100%;
    border-radius: 5px;
}
.members > span {
    font-weight: 500;
}
.members ins {
    color: #999;
    display: inline-block;
    font-size: 11px;
    line-height: 15px;
    text-decoration: none;
    width: 100%;
    margin-bottom: 10px;
}
.members > a {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0;
    width: 100%;
    border: 1px solid transparent;
    transition: all 0.2s linear 0s;
}
.members > a:hover {
    background: transparent;
}
a.invite {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border: 2px solid #088dcd;
    border-radius: 30px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 20px;
    position: absolute;
    right: 23px;
    top: 75px;
    transition: all 0.3s linear 0s;
}
a.invite:hover,
.sharing-options > li a:hover {
	box-shadow: -1px 3px 8px rgba(0,0,0,.4);
}
/*--- invite popup ---*/
.invitation-meta {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.invitation-meta > p {
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin-bottom: 30px;
}
/*--- question answer page ---*/
.question-title {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 22px;
    vertical-align: top;
    width: 95%;
}
.question-meta {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.question-meta > p {
    margin-bottom: 30px;
}
.question-meta .tags {
    padding-left: 0;
    width: 100%;
}
.question-meta .main-btn {
    padding: 6px 15px;
    text-transform: capitalize;
}
.anser .friend-info{
	border-bottom: 1px solid #eaeaea;
    display: inline-block;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}
.anser {
    display: inline-block;
    margin-top: 40px;
    padding-left: 50px;
    position: relative;
    width: 100%;
}
.anser > i {
    color: #82b440;
    font-size: 40px;
    left: 0;
    position: absolute;
    top: 0;
}
/*--- question search form ---*/
.search-question {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
}
.search-question > form {
    display: inline-block;
    position: relative;
    width: auto;
}
.search-question > form input {
    border: 1px solid #eaeaea;
    border-radius: 30px;
    display: inline-block;
    padding: 12px 20px;
    width: 400px;
}
.search-question > form button {
    border: medium none;
    border-radius: 100%;
    color: #fff;
    line-height: 40px;
    position: absolute;
    right: 6px;
    top: 4px;
    width: 40px;
}
/*--- pagination ---*/
.load {
    display: inline-block;
    margin-top: 30px;
    text-align: center;
    width: 100%;
}
.pagination {
    display: block;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}
.pagination > li {
    display: inline-block;
    margin: 0 2px;
}
.pagination > li > a {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #c9d6de;
    border-radius: 100%;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    transition: all 0.2s linear 0s;
    width: 35px;
}
.pagination > li > a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.pagination > li > a.active {
    background: rgba(8, 141, 205, 0.6) none repeat scroll 0 0;
    border-color: #088dcd;
    color: #fff;
}
/*--- research avatar ---*/
.research-avatar {
    border: 1px solid #e0e0e0;
    display: inline-block;
    width: 100%;
}
/*--- related questions ---*/
.related-qst {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
}
.related-qst > li {
    display: inline-block;
    margin-bottom: 13px;
    width: 100%;
}
.related-qst > li > a {
    display: inline-block;
    padding-left: 25px;
    position: relative;
    transition: all 0.2s linear 0s;
}
.related-qst > li > a:hover {
    color: red;
}
.related-qst > li > a::before {
    content: "\f000";
    font-family:  'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    left: 0;
    position: absolute;
    top: 0;
}
/*--- books page ----*/
.popular-book {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.popular-book > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 80px;
}
.book-about {
    display: inline-block;
    padding-left: 7px;
    vertical-align: middle;
    width: 60%;
}
.popular-book > figure img {
    border-radius: 4px;
}
.book-about > h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}
.book-about > span {
    font-size: 12px;
}
.book-about > a {
    float: right;
    font-size: 16px;
	color: #b2b2be;
}
.popular-book:last-child {
    margin-bottom: 0;
}
.category {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 22px;
    margin-top: 10px;
    padding-left: 0;
    width: 100%;
}
.category > li {
    display: inline-block;
    margin-right: 6px;
    vertical-align: middle;
}
.category > li a {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #c9d6de;
    border-radius: 30px;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 18px;
    transition: all 0.2s linear 0s;
}
.category > li a.active {
    background: #088dcd none repeat scroll 0 0;
    border-color: #088dcd;
    color: #fff;
}
.category > li a.active:hover{
	color: #fff;
}
.book-post {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
	position: relative;
}
.owl-item .book-post{margin-bottom: 0;}
.book-post > figure {
    margin-bottom: 6px;
}
.book-post > figure > a {
    display: inline-block;
    overflow: hidden;
    width: 100%;
}
.book-post > figure > a img {
    border-radius: 6px;
    transition: all 0.2s ease-in-out 0s;
}
.book-post > a {
    display: inline-block;
    font-weight: 500;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.book-post:hover > figure > a img {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}
/*--- book detail page ---*/
.prod-detail {
    display: inline-block;
    position: relative;
    width: 100%;
}
.prod-detail ul.stars {
    position: unset;
}
.prod-detail > h4 {
    display: inline-block;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 100%;
}
.prod-detail > span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
}
.prod-detail > span > i {
    color: #82b440;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.prod-detail > ul.item-info {
    display: inline-block;
    padding-left: 20px;
    width: 100%;
}
.prod-detail > ul.item-info > li {
    display: inline-block;
    margin-bottom: 8px;
    vertical-align: middle;
    width: 49%;
	font-size: 13px;
}
.prod-detail > ul.item-info > li span {
    font-size: 13px;
    font-weight: 500;
}
.sale-button {
    display: inline-block;
    width: 100%;
}
.main-btn.purchase-btn {
    background: #82b440 none repeat scroll 0 0;
    border-color: #528410;
	font-size: 14px;
}
.sale-button > a {
    margin-right: 10px;
}
.full-book {
    display: inline-block;
    width: 100%;
}
.full-book > figure {
    display: inline-block;
    position: relative;
    width: 100%;
}
.full-book > figure > span {
    background: #fec42d none repeat scroll 0 0;
    color: #ffffff;
    font-size: 12px;
    left: -5px;
    padding: 2px 14px;
    position: absolute;
    top: 5px;
}
.prod-stat {
    display: inline-block;
    width: 100%;
}
.prod-stat > ul {
    display: inline-block;
    list-style: outside none none;
    padding-left: 0;
    width: 100%;
}
.prod-stat > ul > li {
    display: inline-block;
    font-size: 12px;
    width: 100%;
}
.add-comment {
    display: inline-block;
    position: relative;
    width: 100%;
	margin-top: 30px;
}
.add-comment .stars {
    float: left;
    margin-left: 14px;
    position: unset;
    vertical-align: middle;
    width: auto;
}
.add-comment > span {
    float: left;
    font-weight: 500;
}
.add-comment .c-form {
    margin-top: 10px;
}
.add-comment .c-form input, .add-comment .c-form textarea{
	background: #fff;
}
.comment-area.product .comment-box {
    border: 0 none;
    margin-bottom: 10px;
    padding: 0;
}
.comment-area.product .commenter-photo > img {
    max-width: 45px;
}
.comment-titles > ins {
    background: #fec42d none repeat scroll 0 0;
    border-radius: 30px;
    color: #ffffff;
    float: right;
    padding: 2px 13px;
    text-decoration: none;
}
.comment-area.product {
    display: inline-block;
    width: 100%;
}
.comment-area.product .comment-title {
    font-size: 22px;
    margin-bottom: 30px;
}
.book-description {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.book-description > p {
    margin-bottom: 0;
}
/*--- development component ---*/
.post-subject > h1 {
    color: #ffffff;
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
    font-size: 30px;
}
.post-subject > p {
    color: #eeeeee;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
    max-width: 50%;
}
.responsive-tab .uk-list {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 30px;
}
.responsive-tab .uk-list > li{
	margin-top: 12px;
	display: inline-block;
	width: 100%;
}
.responsive-tab .uk-list > li:first-child{
	margin-top: 0;
}
.responsive-tab .uk-list > li > a {
    background: #fafafa none repeat scroll 0 0;
    border-radius: 9px;
    display: inline-block;
    font-weight: 500;
    padding: 8px 15px;
    text-transform: capitalize;
    vertical-align: middle;
    width: 100%;
}
.responsive-tab .uk-list > li > a.active {
    background: #088dcd;
    color: #ffffff;
}
#components-nav{
	margin-bottom: 0;
	padding: 10px 5px;
}
#components-nav .uk-list.docs-list {
    font-size: 14px;
    text-transform: capitalize;
}
#components-nav h2 {
    font-size: 26px;
    font-weight: 500;
}
.uk-slider-items > li h1 {
    text-shadow: 0px 3px 2px rgba(0,0,0,.2);
}
#components-nav h1,
#components-nav h2,
#components-nav h3,
#components-nav h4,
#components-nav h5,
#components-nav h6{
    margin-bottom: 10px;
}
.button.Markup-botton {
    background: #dde9ff none repeat scroll 0 0;
    border: medium none;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    padding: 7px 10px 7px 43px;
    position: relative;
    margin: 15px 0;
    color: #273444;
    font-weight: 500;
}
.Markup-botton::before {
    background: #77a4f9 none repeat scroll 0 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    content: '\ede6';
    position: absolute;
    top: 0;
    left: 0;
    width: 33px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 13px;
    border-radius: 3px 0 0 3px;
    color: #fff;
}
.uk-accordion-title{
    font-size: 14px;
    font-weight: 500;
}
.uk-card-default {
    display: inline-block;
    margin-bottom: 15px !important;
    margin-top: 15px;
    width: 100%;
}
.uk-accordion {
    display: inline-block;
    margin: 14px 0;
    width: 100%;
}
.uk-subnav.uk-subnav-pill {
    margin-bottom: 20px;
}
.uk-subnav-pill > li > a{
    border-radius: 5px;
    font-size: 13px;
}
.uk-alert-primary p{
    color: #1e87f0;
}
.uk-alert-success p{
    color: #32d296;
}
.uk-alert-danger p{
    color: #f0506e;
}
[class~=bg-gradient-primary] {
    background: linear-gradient(80deg,#78a6fc,#3576ef 100%) !important;
}
.bg-gradient-success {
    background: linear-gradient(80deg,#4cd964 0,#50d94c 100%) !important;
}
[class~=bg-gradient-danger] {
    background: linear-gradient(80deg,#ff3b30 0,#ff304e 100%) !important;
}
.uk-light p{color: #fff;}
.uk-button-default {
    border-radius: 6px;
    padding: 4px 15px;
    font-size: 14px;
    font-weight: 500;
}
.uk-lightbox,
.uk-modal,
.uk-offcanvas{
    z-index: 99999999;
}
.uk-lightbox iframe{height: 100%;}
.uk-notification-message{
    background: #fff;
    box-shadow: 0 0px 10px rgba(0,0,0,.2);
    font-size: 14px;
    font-weight: 500;
}
.uk-notification{
    z-index: 999999;
}
.uk-offcanvas-bar > p{
    color: rgba(255,255,255,.7);
}
.side-nav-title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}
.side-nav > ul > li {
    margin-bottom: 10px;
    text-transform: capitalize;
}

/*--- development elements ---*/
.button,
a.button{
    background: #088dcd;
    border: medium none;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    font-size: 13.5px;
    font-weight: 400;
    padding: 8px 18px;
    text-transform: capitalize;
    transition: all 0.2s linear 0s;
}
.button.primary.circle,
a.button.primary.circle{
    border-radius: 30px;
}
.button.small,
a.button.small{
    padding: 3px 10px;
}
.button.large,
a.button.large{
    padding: 10px 25px;
}
.button.xlarge,
a.button.xlarge{
    font-size: 14px;
    padding: 15px 30px;
}
.button.secondary,
a.button.secondary{
    background: #77838f none repeat scroll 0 0;
}
.button.disabled,
a.button.disabled{
    background: #a7b3bf none repeat scroll 0 0;
    cursor: auto;
}
.button.danger {
    background: #ff5630;
}
.button.success {
    background: #62d76b none repeat scroll 0 0;
}
.button.info {
    background: #00dffc none repeat scroll 0 0;
}
.button.grey {
    background: gray none repeat scroll 0 0;
}
.button.white {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ececec;
    color: #333;
}
.button.dark {
    background: #273444;
}
.button.light {
    background: #f4f8ff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    color: #444;
}
.button.outline-primary{
    color: #088dcd;
    border: 1px solid #088dcd;
    background: transparent;
}
.button.outline-secondary{
    color: #77838f;
    border: 1px solid #77838f;
    background: transparent;
}
.button.outline-success{
    color: #62d76b;
    border: 1px solid #62d76b;
    background: transparent;
}
.button.outline-info{
    color: #00dffc;
    border: 1px solid #00dffc;
    background: transparent;
}
.button.outline-danger{
    color: #ff5630;
    border: 1px solid #ff5630;
    background: transparent;
}
.button.outline-light{
    color: #999;
    border: 1px solid #eaeaea;
    background: transparent;
}
.button.outline-dark{
    color: #273444;
    border: 1px solid #273444;
    background: transparent;
}
.button.soft-primary{
    background: #e8f0fe;
    color: #088dcd;
}
.button.soft-secondary{
    background: #ececf1;
    color: #76797d;
}
.button.soft-success{
    background: #d7f0e5;
    color: #36b37e;
}
.button.soft-danger{
    background: #ffddd6;
    color: #ff5630;
}
.button.soft-light{
    background: #ffddd6;
    color: #333;
}
.button.soft-dark{
    background: #d4d6da;
    color: #273444;
}
.button.soft-info{
    background: #ccf1f7;
    color: #00b8d9;
}
.uk-card-media-right{float: right;}
.uk-card-media-left{float: left;}
.billing > form .chosen-container-single .chosen-single div b {
    
}

.chosen-container-active.chosen-with-drop .chosen-single div b{
    background-position: -15px 10px;
}
.chosen-container-single .chosen-drop{
	border: 1px solid #e5e5e5;
    box-shadow: none;
}
.billing > form .chosen-container-single .chosen-single {
    background: #fff none repeat scroll 0 0;
    border-color: #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    height: 46px;
    line-height: 46px;
	padding-left: 12px;
}
.uk-input, .uk-select, .uk-textarea {
    border-radius: 5px;
    font-size: 14px;
    height: auto;
    padding: 12px 15px;
	max-width: 100%;
    width: 100%;
    background: #fff;
    color: #62626e;
    border: 1px solid #e5e5e5;
    transition: .2s ease-in-out;
    transition-property: color,background-color,border;
}
.chosen-container-single .chosen-single span{
	color: #62626e;
}
.uk-margin .chosen-container{width: 100% !important;}
.uk-form-small:not(textarea):not([multiple]):not([size]){
    font-size: 10px;
}
input[type=checkbox]{
    border-radius: 4px;
}
.uk-background-muted {
    background-color: #ececec;
}
.uk-list-striped>:nth-of-type(odd) {
    background: #F8F0F8;
}
.uk-table th{
    font-weight: 500;
    color: #333;
}
.bg-blue{
    background: #088dcd;
}
.pd-20 {
    padding: 20px;
}


/*--- course page ---*/
.course {
    background: #fff none repeat scroll 0 0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
	margin-bottom: 30px;
	box-shadow: -7.5pt 10px 10.5pt -3.75pt rgba(25,42,70,.13);
}
.course > figure {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    width: 100%;
}
.course > figure img {
    border-radius: 7px 7px 0 0;
    width: 100%;
}
.course > figure > i {
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: -1.6px;
}
.course > figure > em {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 12px;
    font-style: normal;
    left: -5px;
    padding: 3px 10px;
    position: absolute;
    text-transform: capitalize;
    top: 20px;
}
.course > figure .rate-result {
    background: #fec42d none repeat scroll 0 0;
    border-radius: 30px;
    bottom: 15px;
    color: #fff;
    font-size: 11px;
    left: 15px;
    padding: 2px 10px;
    position: absolute;
}
.course-meta {
    display: inline-block;
    width: 100%;
	padding: 20px;
}
.post-by {
    display: inline-block;
    width: 80%;
}
.post-by > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.post-by > figure img {
    border: 1px solid #cacaca;
    border-radius: 100%;
    max-width: 30px;
}
.course-cat {
    display: inline-block;
    line-height: 15px;
    padding-left: 5px;
    vertical-align: middle;
    width: 82%;
}
.course-cat > span {
    color: #82828e;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    text-transform: capitalize;
    width: 100%;
}
.course-cat > a {
    border-right: 1px solid;
    display: inline-block;
    font-size: 11px;
    line-height: 10px;
    margin-right: 3px;
    padding-right: 5px;
}
.course-cat > a:last-child {
    border: 0 none;
}
.prise {
    float: right;
}
.prise > span {
    color: #92929e;
    font-size: 18px;
    font-weight: 500;
}
.course:hover .prise > span {
    color: #62626e;
}  
.course-title {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 10px;
    width: 100%;
}
.course-info {
    display: inline-block;
    width: 100%;
}
.lecturez {
    color: #82828e;
    float: left;
    font-size: 12px;
}
.time {
    color: #82828e;
    float: right;
    font-size: 12px;
}
/*--- course category ---*/
.categ-card {
    background: #fff none repeat scroll 0 0;
    border-radius: 10px;
    box-shadow: -7.5pt 10px 10.5pt -3.75pt rgba(25, 42, 70, 0.13);
    display: inline-block;
    padding: 15px;
    text-align: center;
    width: 100%;
}
.categ-card > i {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}
.categ-card > div {
    display: inline-block;
    width: 100%;
}
.categ-card > div > p {
    font-size: 12px;
    margin-bottom: 0;
    text-align: left;
}
.categ-card > div > p span {
    float: right;
}
.categ-card > div > h6 {
    font-weight: 500;
}
/* Skills Section */
.bar {
  margin: 0 auto 8px;
    width: 100%;
}
.skill {
  font-size: .8em;
  text-transform: uppercase;
  margin-bottom: 0.25em;
}
.speech-bubble {
  font-size: 0.75em;
  line-height: 2em;
  position: absolute;
  top: -2.7em;
  text-align: center;
  min-width: 3em;
  border-radius: 0.3em;
  display: none;
}
.html,
.photo,
.php {
  left: calc(75% - 1.5em);
}
.jquery {
  left: calc(25% - 1.5em);
}
.sketch,
.illustrator {
  left: calc(90% - 1.5em);
}
.indesign {
  left: calc(85% - 1.5em);
}
.speech-bubble:after {
  border: 0.5em solid transparent;
  content: '';
  margin-left: -0.5em;
  position: absolute;
  top: 100%;
  left: 50%;
}
.bar-outer {
  background: #e9e9e9 none repeat scroll 0 0;
    border-radius: 3px;
    height: 12px;
    position: relative;
}
.bar-inner {
  width: 0;
  height: 100%;
  border-radius: 3px;
}
.ht, .html {
  background: #f89d94;
}
.html:after {
  border-top-color: #f89d94;
}
.jq, .jquery {
  background: #f89d94;
}
.jquery:after {
  border-top-color: #f89d94;
}
.sk, .sketch {
  background: #f89d94;
}
.sketch:after {
  border-top-color: #f89d94;
}
.ph, .photo {
  background: #f89d94;
}
.photo:after {
  border-top-color: #f89d94;
}
.il, .illustrator {
  background: #f89d94;
}
.illustrator:after {
  border-top-color: #f89d94;
}
.in, .indesign {
  background: #f89d94;
}
.indesign:after {
  border-top-color: #f89d94;
}
.inner-column {
    display: inline-block;
    margin-top: 4px;
    text-align: center;
    width: 100%;
}
.total-rating {
    font-size: 32px;
    font-weight: 500;
}
.rating {
    display: inline-block;
    width: 100%;
}
.rating > span {
    color: #fec42d;
    font-size: 14px;
}
.inner-column > span {
    color: #72727e;
    display: inline-block;
    font-size: 13px;
    margin-top: 5px;
    width: 100%;
}
.rating > i {
    font-size: 11px;
    font-style: normal;
}
.skills {
    display: inline-block;
    margin-top: 8px;
    width: 100%;
}
.course-details {
    display: inline-block;
    width: 100%;
}
.rating-stars {
    list-style: outside none none;
    margin-bottom: 10px;
    padding-left: 0;
}
.rating-stars > li {
    color: #fec42d;
    display: inline-block;
}
.rating-stars > li > span {
    background: #fec42d none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 0;
    padding: 2px 15px;
}
.course-details > h4 {
    display: inline-block;
    font-size: 30px;
    margin-bottom: 20px;
    width: 74%;
}
.course-price {
    float: right;
    font-size: 26px;
    font-weight: 500;
}
.course-price > del {
    color: #92929e;
    font-size: 14px;
}
.create-by {
    display: inline-block;
    width: 80%;
}
.create-by > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.create-by > figure > img {
    border: 1px solid #dadada;
    border-radius: 100%;
}
.create-by > div span {
    color: #525252;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}
.create-by > div em {
    font-size: 11px;
    font-style: normal;
}
.course-details > a {
    background: #fff;
    border-radius: 30px;
    color: #fff;
    float: right;
    font-weight: 500;
    padding: 5px 20px;
	border: 1px solid;
}
.create-by > div {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
}
.statistic {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    margin-top: 30px;
    padding-left: 0;
    width: 100%;
}
.statistic > li {
    color: #82828e;
    display: inline-block;
    font-size: 12px;
    margin-right: 30px;
    vertical-align: middle;
}
.statistic > li i {
    font-size: 14px;
	color: #a2a2ae;
}
.course-video {
    display: inline-block;
    position: relative;
    width: 100%;
	text-align: center;
	margin-bottom: 30px;
}
.course-video figure {
    display: inline-block;
    position: relative;
    width: 100%;
}
.course-video figure img {
    border-radius: 10px;
    width: 100%;
}
.course-video figure .play-btn {
     background: rgba(8, 141, 205, 0.7) none repeat scroll 0 0;
    border: 1px solid;
    border-radius: 100%;
    color: #fff;
    font-size: 30px;
    left: 50%;
    line-height: 60px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 60px;
}
.course-video figure .play-btn > i {
    padding-left: 6px;
}
.course-video > span {
    display: inline-block;
    font-size: 12px;
    margin-top: 15px;
    width: 100%;
}
a.wish-btn {
    background: #ffd550 none repeat scroll 0 0;
    border: 2px solid #ffa500;
    border-radius: 30px;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    padding: 9px 13px;
    vertical-align: inherit;
    color: #333;
}
.incldes {
    display: inline-block;
    width: 100%;
}
.incldes > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
    width: 100%;
}
.incldes > ul > li {
    display: inline-block;
    margin-bottom: 15px;
    width: 49%;
}
.incldes > ul > li > i {
    margin-right: 5px;
}
.incldes > ul > li > span {
    color: #72727e;
    font-weight: 500;
}
.desc-course{
	display: inline-block;
	width: 100%;
	margin-bottom: 30px;
}
.video-lecture span {
    float: right;
}
.video-lecture > li {
    border-bottom: 1px solid #dadada;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
}
.video-lecture {
    display: inline-block;
    list-style: outside none none;
    padding-left: 0;
    width: 100%;
}
.video-lecture > li > i {
    margin-right: 10px;
}
.video-lecture > li > a:hover {
    color: red;
}
.video-lecture > li:last-child {
    margin-bottom: 0;
}

/*--- index section ---*/
.info-sec {
    background: #f5f5f5;
    border-radius: 6px;
    display: inline-block;
    padding: 20px;
    width: 100%;
}
.info-sec > i {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: top;
    width: 40px;
}
.info-sec > div {
    display: inline-block;
    padding-left: 15px;
    width: 85%;
}
.info-sec > div h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    display: inline-block;
    width: 100%;
}
.info-sec > div p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 8px;
}
/*--- students review section index page ---*/
.review-item {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.student-review .owl-dots {
    margin-top: 20px;
}
.review-item > figure {
    display: inline-block;
    margin-bottom: 8px;
    width: 70px;
}
.review-item > figure img {
    border: 1px solid red;
    border-radius: 100%;
    padding: 4px;
}
.review-item > h6 {
    display: inline-block;
    font-weight: 700;
    width: 100%;
}
.review-item > span {
    color: red;
    font-size: 11px;
}
.review-item > p {
    display: inline-block;
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0;
    margin-top: 10px;
    width: 100%;
}
/*--- invoice page ---*/
.invoice {
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    margin: 20px 0;
    width: 100%;
}
.invoice-head {
    background: #088dcd none repeat scroll 0 0;
    border-bottom: 3px solid #205d7f;
    display: inline-block;
    padding: 10px 20px;
    width: 100%;
}
.invoice-head figure {
    float: left;
    margin-bottom: 0;
}
.invoice-head > h6 {
    color: #fff;
    float: right;
    font-size: 20px;
    font-weight: 500;
    margin-top: 8px;
}
.invoice-head figure img {
    max-width: 100px;
}
.invoice-meta {
    display: inline-block;
    padding: 30px;
    width: 100%;
}
.invoice-item {
    display: inline-block;
    padding: 0 30px;
    width: 100%;
}
.invoice-footer {
    display: inline-block;
    font-size: 13px;
    padding: 0 30px 40px;
    text-align: center;
    width: 100%;
}
.invoice-footer > h5 {
    font-size: 13px;
    font-weight: 500;
}
.invoice-footer > a {
    color: red;
}
.invoice-footer > h6 {
    color: #92929e;
    font-size: 13px;
}
.item-tble {
    background: #f5f5f5 none repeat scroll 0 0;
    border-radius: 10px;
    font-size: 13px;
    margin-bottom: 40px;
}
.item-tble thead tr th {
    border-top: 0 none;
}
.user-total {
    font-weight: 500;
    text-align: center;
}
.user-total p {
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 0;
}
.user-total .totalinv2 > span {
    color: red;
    font-size: 20px;
}
.item-tble tbody td:last-child {
    text-align: center;
}
.item-tble tbody td:nth-child(2) {
    text-align: center;
}
.item-tble thead th {
    text-align: center;
}
.create-date {
    display: inline-block;
    width: 100%;
	margin-bottom: 30px;
}
.print-share {
    float: right;
    text-align: center;
    width: 100px;
}
.create-date > ul {
    float: left;
}
.print-share > a {
    background: #5c5c5c none repeat scroll 0 0;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 9px;
    padding: 4px 20px;
    width: 100%;
}
.create-date > ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.create-date > ul li {
    color: #92929e;
    font-size: 13px;
    margin-bottom: 7px;
}
.create-date > ul li span {
    color: #1f273f;
    display: inline-block;
    font-weight: 500;
    width: 80px;
}
.invoice-tofrom {
    display: inline-block;
    width: 100%;
}
.invoice-tofrom span {
    font-size: 15px;
    font-weight: 500;
}
.invoice-tofrom ul {
    display: inline-block;
    font-size: 13px;
    list-style: outside none none;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 0;
    width: 100%;
}
.invoice-tofrom ul li {
    color: #82828e;
    margin-bottom: 4px;
}
/*--- forget password ---*/
.forget-password .logo {
    margin-bottom: 30px;
}
.forget-password .logo img{
	max-width: 100px;
}
.forget-password .logo span{
	font-size: 30px;
}
.forget-password {
    display: inline-block;
    margin: 50px 0 20px;
    text-align: center;
    width: 100%;
}
.pass-form {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 30px;
    width: 100%;
}
.pass-form h4 {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
}
.pass-form form {
    display: inline-block;
    position: relative;
    width: 100%;
}
.pass-form form input {
    border: 1px solid #e8e8e8;
    border-radius: 30px;
    margin-bottom: 20px;
    padding: 14px 20px;
    width: 100%;
}
.pass-form form button {
    float: right;
}
.pass-form form label {
    color: #b2b2be;
    position: absolute;
    right: 20px;
    top: 15px;
}
.pass-form > span {
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    width: 100%;
}
.pass-form > span a {
    color: red;
}
/*--- circle animated */
.error-page,
.coming-page{
    background: rgba(0, 0, 0, 0) linear-gradient(135deg, #088dcd 0%, #088dcd 100%) repeat scroll 0 0;
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;
	overflow: hidden;
}
.error-page{
    height: 100vh;
}
#container-inside {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    margin: 0px auto;
    padding: 0px auto;
    overflow: visible;
	position: absolute;
	top: 0;
	left: 0;
}
#circle-small {
    -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
    animation: circle-small-scale 3s ease-in-out infinite alternate;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
            animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    position: absolute;
    bottom: -150px;
    left: -150px;
    background: #fff;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0.4;
}
#circle-medium {
    -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
    animation: circle-small-scale 3s ease-in-out infinite alternate;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
            animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    position: absolute;
    bottom: -200px;
    left: -200px;
    background: #fff;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    opacity: 0.3;
}
#circle-large {
    -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
    animation: circle-small-scale 3s ease-in-out infinite alternate;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
            animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
    position: absolute;
    bottom: -400px;
    left: -400px;
    background: #fff;
    width: 900px;
    height: 900px;
    border-radius: 50%;
    opacity: 0.2;
}
#circle-xlarge {
    -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
    animation: circle-small-scale 3s ease-in-out infinite alternate;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
            animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
    position: absolute;
    bottom: -500px;
    left: -460px;
    background: #fff;
    width: 1200px;
    height: 1200px;
    border-radius: 50%;
    opacity: 0.1;
}
#circle-xxlarge {
    -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
    animation: circle-small-scale 3s ease-in-out infinite alternate;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
            animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
    position: absolute;
    bottom: -600px;
    left: -550px;
    background: #fff;
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    opacity: 0.05;
}
@-webkit-keyframes circle-small-scale {
    0% {
        -webkit-transform: scale(1);
		transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.1);
		transform: scale(1.1);
    }
}
@keyframes circle-small-scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}
/*--- error page 404 ---*/
.error-page .logo.top-left {
    left: 50px;
    position: absolute;
    top: 50px;
    z-index: 9;
}
.error-data {
    position: absolute;
    right: 10%;
    text-align: right;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    z-index: 2;
}
.error-data > h1 {
    color: #fff;
    display: inline-block;
    font-size: 60px;
    font-weight: 500;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    width: 100%;
}
.error-data > a {
    background: #fff none repeat scroll 0 0;
    border-radius: 30px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    color: inherit;
    display: inline-block;
    font-weight: 500;
    margin-top: 20px;
    padding: 13px 20px;
}
/*--- coming soon page ---*/
.comesoon-wraper {
    display: inline-block;
    padding: 50px 30px;
    position: relative;
    width: 100%;
    z-index: 9;
}
.top-left {
    display: inline-block;
}
.top-right {
    border: 1px solid;
    border-radius: 30px;
    color: #fff;
    float: right;
    font-size: 15px;
    margin-top: 3px;
    padding: 5px 14px;
}
.come-soon {
    display: block;
    margin: 112px auto;
    max-width: 51%;
    text-align: center;
    width: 100%;
}
.come-soon > h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
	text-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.6);
}
.come-soon > span {
    color: #fff;
    display: inline-block;
    width: 100%;
	font-size: 18px;
}
.come-soon > p {
    color: #d9d9d9;
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
    width: 100%;
}
.left-bottom {
    display: inline-block;
}
.left-bottom > span, .right-bottom > span {
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    text-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    width: 100%;
}
ul.countdown {
    display: block;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
}
.right-bottom {
    float: right;
    text-align: center;
}
.right-bottom > ul {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
}
.countdown > li {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 70px;
}
.countdown > li > span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    color: #fff;
	text-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.6);
}
.countdown > li > p {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0;
    width: 100%;
	text-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.6);
}
.right-bottom li {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}
.right-bottom li a {
    border: 1px solid;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 30px;
    line-height: 27px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 30px;
}
.right-bottom li a:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
.right-bottom > span {
    color: #fff;
}
/*--- checkout page ----*/
.light-bg {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ececec;
    border-radius: 6px;
}
.light-bg .uk-tab .uk-active a {
    border-color: red;
    color: red;
}
.light-bg .uk-tab > li a {
    display: inline-block;
    padding: 0 0 17px;
}
.light-bg .uk-tab > li a:hover {
    color: #333;
}
/*--- event circle progress bar ---*/
.progress__outer {
  position: relative;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 0 auto;
}
.progress__outer > canvas {
    position: relative;
    z-index: 3;
}
.progress__inner {
  position: absolute;
  border-radius: 999px;
  background: white;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 0;
}
#map-canvas {
    float: left;
    height: inherit;
    width: 100%;
}
.g-mapfull {
    margin-bottom: 30px;
}
/*--- setting page --*/
.seting-mode {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.seting-mode > label {
    float: right;
}
.seting-mode > i {
    color: #92929e;
    font-size: 16px;
    margin-right: 8px;
    vertical-align: middle;
}
/*--- Simple HTML/CSS switch ---*/
.switch {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 20px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}
.switch::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 17px;
    height: 17px;
    background: #fafafa !important;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    border: 0 !important;
    border-radius: 100% !important;
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: #72da67;
}
input:checked + .switch::before {
    left: 27px;
    background: #fff !important;
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}
/*--- thank you page ---*/
.thanks-purchase {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.thanks-purchase > h1 {
    color: #fff;
    font-size: 110px;
    font-weight: 500;
    letter-spacing: -1px;
    margin-bottom: 20px;
    text-shadow: 1px 10px 6px rgba(0, 0, 0, 0.2);
}
.thanks-purchase > span {
    color: #fff;
    display: block;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
    text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.2);
}
.thanks-purchase > p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    text-shadow: 1px 10px 6px rgba(0, 0, 0, 0.2);
}
.thanks-purchase > p span {
	color: #273444;
}
.thanks-purchase > p a {
    display: block;
    margin-top: 40px;
}
/*--- send feedback page ---*/
.form-group1 label {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 14px;
}
.image-upload-wrap {
	border: 1px solid #efefef;
	position: relative;
	background-color: #fff;
	border-radius: 10px;
}
.image-dropping,
.image-upload-wrap:hover {
	background-color: #fff;
	border: 1px solid #efefef;
}
.image-title-wrap {
	padding: 0 15px 15px 15px;
	color: #847577;
}
.drag-text {
	text-align: center;
	padding: 20px 0;
}
.drag-text h4 {
	font-size:16px;
	font-weight: 500;
	text-transform: none;
	color: #333;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	margin-bottom:5px;
	margin-top: 0;
}
.drag-text p{
	font-size:12px;
	font-weight: 400;
	text-transform: none;
	color: #686f7a;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	line-height:12px;
}
.drag-text i{
	font-size:40px;
	text-transform: none;
	color: #a1a1a1;
	margin-bottom:10px;
}
.file-upload-input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	outline: none;
	opacity: 0;
	cursor: pointer;
}
.feed-back-emojies {
    display: inline-block;
    width: 100%;
}
.feed-back-emojies > ul {
    display: inline-block;
    list-style: outside none none;
    padding-left: 0;
    text-align: center;
    width: 100%;
}
.feed-back-emojies > ul > li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
.feed-back-emojies > ul > li a {
    border: 1px solid #dedede;
    border-radius: 100%;
    display: inline-block;
    max-width: 50px;
    padding: 4px;
	transition: all 0.2s linear 0s;
}
.feed-back-emojies > ul li a.selected {
    border-width: 2px;
}
.feed-back-emojies > span {
    display: inline-block;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}
/*--- help page ---*/

.help-detail > p{
	line-height: 26px;
}
.help-detail > h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: capitalize;
}
.gallery .uk-card > img {
    border-radius: 10px;
    width: 100%;
}
.ol-list {
    display: inline-block;
    margin-bottom: 0;
    width: 100%;
}
.ol-list > li {
    margin-top: 10px;
}
.bottom-image {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    width: 100%;
}
.is-helpful {
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
}
.is-helpful > span {
    font-size: 14px;
    margin-right: 10px;
    text-transform: capitalize;
}
.is-helpful > a {
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 10px;
    padding: 10px 20px;
    text-align: center;
}
.is-helpful > em {
    float: right;
    font-size: 14px;
    font-style: normal;
    margin-top: 4px;
}
.share-article > span {
    display: inline-block;
    font-size: 15px;
    margin-bottom: 15px;
    text-transform: capitalize;
    width: 100%;
	font-weight: 500;
}
.share-article > a {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    line-height: 35px;
    margin: 0 2px;
    text-align: center;
    width: 35px;
}
.search-section {
    display: inline-block;
    padding: 30px 0;
    text-align: center;
    width: 100%;
}
.search-section > a {
    border: 1px solid;
    border-radius: 20px;
    font-size: 12px;
    left: -80px;
    padding: 6px 10px;
    position: absolute;
    top: -50px;
}
.search-section > h1 {
    display: inline-block;
    margin-bottom: 26px;
    text-transform: capitalize;
    width: 100%;
}
.search-section > form {
    display: inline-block;
    max-width: 50%;
    position: relative;
    width: 100%;
}
.search-section form input {
    border: 1px solid #dadada;
    border-radius: 30px;
    display: block;
    padding: 15px 50px 15px 30px;
    width: 100%;
}
.search-section form button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    color: #b2b2be;
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 10px;
}
.search-section > p {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 15px;
    width: 100%;
}
/*--- blog detail page ----*/
.blog-title {
    display: inline-block;
    width: 100%;
}
.blog-title > h2 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 30px;
    width: 100%;
}
.blog-details-meta {
    display: inline-block;
    position: relative;
    width: 100%;
}
.blog-details-meta > figure {
    display: inline-block;
    width: 100%;
}
.blog-details-meta > figure img {
    border-radius: 7px;
    display: inline-block;
    width: 100%;
}
.blog-details-meta > ul {
    display: inline-block;
    font-size: 13px;
    list-style: outside none none;
    padding-left: 0;
}
.blog-details-meta > ul li {
    color: #62626e;
    display: inline-block;
    margin-right: 30px;
	vertical-align: middle;
}
.blog-details-meta > ul li i {
    font-size: 15px;
    margin-right: 5px;
    vertical-align: text-bottom;
}
.blog-details-meta > h4 {
    font-weight: 500;
    margin-bottom: 20px;
}
.next-prev-posts {
    display: inline-block;
    width: 100%;
}
.next-prev-posts .prev {
    float: left;
}
.next-prev-posts .next {
    float: right;
    text-align: right;
}
.next-prev-posts a {
    display: inline-block;
    width: 100%;
}
.next-prev-posts .prev > a i {
    display: inline-block;
    font-size: 30px;
    transition: all 0.2s linear 0s;
    vertical-align: middle;
    width: 30px;
}
.next-prev-posts .prev > a .translate {
    display: inline-block;
    vertical-align: -moz-middle-with-baseline;
    width: 85%;
}
.translate > span {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
}
.translate > p {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 5px;
}
.next > a > i {
    float: right;
    font-size: 30px;
    margin-top: 15px;
    transition: all 0.3s linear 0s;
    width: 30px;
}
.next .translate {
    width: 89%;
}
.next-prev-posts {
    display: inline-block;
    margin-top: 60px;
    width: 100%;
}
.next:hover > a i {
    -webkit-transform: translate(5px);
            transform: translate(5px);
}
.prev:hover > a i {
    -webkit-transform: translate(-5px);
            transform: translate(-5px);
}
.tag-n-cat {
    border-top: 1px solid #e2e2e2;
    float: left;
    margin-top: 20px;
    padding: 20px 0;
    width: 100%;
}
.tag-n-cat .tags {
    display: inline-block;
    padding: 4px 0;
    width: 100%;
    margin-bottom: 0;
}
.tag-n-cat .tags > span {
    font-size: 15px;
    margin-right: 3px;
    font-weight: 500;
}
.tag-n-cat .tags > a {
    color: #92929e;
    display: inline-block;
    margin-right: 2px;
    padding-right: 4px;
    position: relative;
    text-transform: capitalize;
}
.tag-n-cat .tags > a::before {
    bottom: 0;
    content: ",";
    position: absolute;
    right: 0;
}
.tag-n-cat .tags > span i {
    margin-right: 4px;
}
/*--- event detail page ---*/
.event-schedule {
    display: inline-block;
    position: relative;
    width: 100%;
}
.event-schedule > h6 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: capitalize;
    width: 100%;
}
.event-schedule > span {
    color: #1f273f;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 20px;
    text-transform: capitalize;
    width: 100%;
}
.event-schedule > h5 {
    display: inline-block;
    margin-bottom: 40px;
    text-transform: capitalize;
    width: 100%;
	font-size: 16px;
}
.event-schedule > h5 > i {
    color: blue;
    display: inline-block;
    font-size: 30px;
    margin-right: 5px;
    vertical-align: sub;
	line-height: normal;
	line-height: initial;
}
.event-schedule > a {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    font-size: 13px;
    padding: 3px 23px;
    position: absolute;
    right: 0;
    top: 0;
}
.event-schedule > a.invite-co {
    border: 1px dashed #8a8a8a;
    color: inherit;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto 30px;
    padding: 7px 20px;
    position: relative;
    text-align: center;
    width: 80%;
}
.event-desc {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
}
.event-desc > h6 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 15px;
    width: 100%;
}
.event-desc em {
    font-style: normal;
}
.event-loc {
    display: inline-block;
    width: 100%;
}
.event-loc > span {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.event-loc > span i {
    font-size: 25px;
}
.event-loc > h6 {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 500;
}
.event-loc > strong {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
}
.event-loc > .main-btn{
    float: right;
}
/*--- event statics ---*/
.statics-event {
    display: inline-block;
    position: relative;
    width: 100%;
}
.statics-event > ol {
    display: inline-block;
    list-style: outside none none;
    margin-top: 10px;
    padding-left: 0;
    width: 100%;
    margin-bottom: 10px;
}
.sidebar .statics-event > ol li {
    border: 1px solid #cfcfcf;
    float: left;
    height: 80px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: -1px;
    padding: 5px;
    text-align: center;
    width: 32%;
}
.sidebar .statics-event > ol li span {
    display: inline-block;
    font-weight: 500;
    padding-top: 16px;
    width: 100%;
}
.sidebar .statics-event > ol li i {
    color: #92929e;
    font-size: 10px;
    font-style: normal;
    margin: 0;
}
.statics-event > h6 {
    display: inline-block;
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
.statics-event > a {
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    margin-top: 10px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}
.widget > span {
    display: inline-block;
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 6px;
    width: 100%;
	color: #5a6381;
}
.help-topic {
    display: inline-block;
    width: 100%;
    margin-bottom: 17px;
    margin-top: 17px;
}
.help-topic > span {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #c9d6de;
    border-radius: 100%;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    width: 50px;
    height: 50px;
}
.help-topic > span > i {
    font-size: 20px;
}
.help-topic > a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    vertical-align: middle;
}
/*--- question collaps accordion ---*/
.new-question-popup .post-new .c-form {
    padding-left: 0;
    width: 100%;
}
.card-header {
    border-bottom: 0 none;
    padding: 5px;
    position: relative;
}
.question-collaps .card {
    margin-bottom: 10px;
}
.card-header button.btn.collapsed::before {
    color: #72727e;
    content: "+";
    font-size: 28px;
    position: absolute;
    right: 20px;
    top: 0;
}
.btn.btn-link > span {
    color: #535165;
    font-size: 12px;
    position: absolute;
    right: 60px;
    top: 15px;
}
.card-header button.btn::after {
    color: #72727e;
    content: "-";
    font-size: 35px;
    position: absolute;
    right: 20px;
    top: -7px;
}
.card-header button.btn.collapsed::after {
    display: none;
}
.question-collaps .card-header button.btn {
    display: inline-block;
    text-align: left;
    text-decoration: none;
    width: 100%;
}
.question-collaps {
    display: inline-block;
    width: 100%;
}
.help-qst {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
}
.help-qst > li {
    color: #5a6381;
    display: inline-block;
    margin-bottom: 10px;
    width: 49%;
}
.widget .pop-articles > li a{
    font-size: 13px;
}
.widget .pop-articles > li:hover i {
    color: #1f273f;
}
.pop-articles > li i {
    font-size: 20px;
}
/*--- price plan page ---*/
.pplan-links {
    display: inline-block;
    width: 100%;
}
.pplan-links > li {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
    vertical-align: middle;
    width: 100%;
}
.pplan-links > li:last-child {
    margin-bottom: 0;
}
.pplan-links > li a i {
    font-size: 20px;
}
.pplan-links > li a i.icofont-paypal-alt {
    font-size: 40px;
    vertical-align: middle;
}
.table.table-striped th {
    border-top: 0 none;
    text-transform: uppercase;
    vertical-align: middle;
	color: #3e3f5e;
}
.table.table-striped th > ins {
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    display: inline-block;
    width: 100%;
	font-size: 18px;
	text-decoration: none;
}
.table.table-striped th > span {
    display: inline-block;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
}
.table.table-striped td h6 {
    display: inline-block;
    font-weight: 400;
	color: #6e6f8e;
	font-size: 15px;
}
.table.table-striped td i {
    display: inline-block;
    text-align: center;
    width: 100%;
    color: #5ed230;
}
.table.table-striped tbody tr td:first-child i {
    display: inline;
    display: initial;
    width: auto;
    color: #9cc1d9;
}
table.table-striped tbody tr td.not-included i {
    color: #929292;
}
table.table-striped tbody tr td {
    padding: 18px;
}
table.table-striped tbody tr td a.button {
    text-align: center;
    width: 100%;
}
table.table-striped tbody tr td a.button:hover {
    color: #fff;
}
.price-plan-wraper > h5 {
    background: #fff8d5 none repeat scroll 0 0;
    border-radius: 5px;
    color: #90823e;
    display: block;
    margin: 10px auto 0;
    max-width: 50%;
    padding: 10px;
    text-align: center;
    width: 100%;
	font-size: 15px;
}
.price-plan-wraper > h5 b{
    color:#0b325c;
}
.price-plan-wraper > h5 i {
    color: orange;
    font-size: 30px;
    vertical-align: sub;
}
/*--- setting page ---*/
#components-nav > div h2 {
    font-size: 19px;
    font-weight: 500;
	margin-bottom: 15px;
}
#components-nav > div ol li {
    color: #5a6381;
    font-size: 13px;
    margin-top: 10px;
}
.billing-percent-bar {
    display: inline-block;
    width: 100%;
}
.progress-bar-labels {
    display: inline-block;
    width: 100%;
}
.billing-percent-bar .progress-bar-labels > li {
    background: #deebf3 none repeat scroll 0 0;
    border-radius: 5px;
    display: inline-block;
    padding: 30px 10px;
    text-align: center;
    width: 90px;
    margin-right: 4px;
}
.progress-bar-labels > li div span {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}
.billing-percentages-progress-bar__percent.t5 {
    line-height: 0;
}
.basic_ptitle {
    display: inline-block;
    width: 100%;
}
.basic_ptitle > h4 {
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    width: 100%;
}
.cart-table {
    display: inline-block;
    width: 100%;
}
.cart-table > table > thead th {
    border: 0 none;
}
.cart-table tbody td > figure {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}
.cart-table tbody td > .item-meta {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
}
.cart-table tbody td > .edit-cart {
    color: #92929e;
    cursor: pointer;
    float: right;
	transition: all 0.2s linear 0s;
    margin-top: 30px;
}
.cart-table tbody td > .edit-cart:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.table td, .table th{
    vertical-align: middle;
}
.cart-table tbody td > figure img {
    border-radius: 5px;
}
.item-meta > h6 {
    color: inherit;
    font-weight: 500;
}
.item-meta > span {
    color: #72727e;
    font-size: 11px;
}
.cart-update {
    display: inline-block;
    text-align: right;
    width: 100%;
}
.cart-update > a {
    border: 1px solid;
    border-radius: 20px;
    color: orange;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
    padding: 9px 20px;
    text-transform: capitalize;
	transition: all 0.2s linear 0s;
}
.cart-update > a:hover{
	color: #fff;
}
.cart-box {
    display: inline-block;
    width: 100%;
}
.cart-box > span {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
}
.cart-box > ul {
    background: #edf2f6 none repeat scroll 0 0;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 30px;
    padding: 20px;
    width: 100%;
}
.cart-box > ul li {
    display: inline-block;
    margin-bottom: 4px;
    width: 100%;
}
.cart-box > ul li span {
    display: inline-block;
    font-weight: 500;
    width: 120px;
}
.cart-box > ul li i {
    font-style: normal;
}
.cart-box .main-btn {
    float: right;
    margin-top: 10px;
}
.cart-box > ul li:last-child {
    border-top: 1px dashed #cacaca;
    margin-top: 10px;
    padding-top: 10px;
}
.cart-box > ul li:last-child {
    font-size: 16px;
    font-weight: 500;
}
/*--- checkout page ---*/
.cart-summary > ul {
    display: inline-block;
    list-style: outside none none;
    padding-left: 0;
    width: 100%;
}
.cart-summary > ul li {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.cart-summary > ul li > div {
    display: inline-block;
    width: 80%;
}
.cart-summary > ul li > span {
    float: right;
}
.cart-summary > ul li > div h5 {
    font-size: 14px;
    font-weight: 500;
}
.cart-summary > ul li > div p {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 0;
    margin-top: 5px;
}
.cart-summary > ul li.grand-total > span {
    float: left;
    font-size: 18px;
    font-weight: 500;
}
.cart-summary > ul li.grand-total > i {
    color: red;
    float: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.cart-summary > ul li.grand-total {
    border-bottom: 0 none;
    margin-bottom: 0;
    margin-top: 20px;
    padding-bottom: 0;
}
.cart-summary .main-btn.purchase-btn {
    margin-top: 20px;
    text-align: center;
    width: 100%;
	font-size: 16px;
}
.cart-summary > a em {
    font-size: 11px;
    font-style: normal;
    text-decoration: underline;
    text-transform: uppercase;
}
.useful-info {
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}
.useful-info > ul {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
}
.useful-info > ul > li {
    display: inline-block;
    width: 100%;
	margin-bottom: 20px;
}
.useful-info > ul > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    width: 60px;
}
.info-tag {
    display: inline-block;
    max-width: 79%;
    padding-left: 10px;
    vertical-align: middle;
}
.info-tag > h4 {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    width: 100%;
}
.info-tag > p {
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
    width: 100%;
}
.useful-info > ul > li:last-child {
    margin-bottom: 0;
}
.courier-option {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
}
.courier-option > h4 {
    color: inherit;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
}
.courier-box {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 30px;
    padding: 10px;
    width: 100%;
}
.courier-box > input {
    margin-right: 10px;
}
.courier-box > p {
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 10px;
    max-width: 80%;
    padding-left: 27px;
}
.courier-box:last-child {
    margin-bottom: 0;
}
.courier-box > img {
    vertical-align: sub;
}
.payment-methods .uk-tab {
    margin-bottom: 35px;
    margin-right: 0;
}
.payment-methods .uk-tab > li {
    margin-right: 60px;
}
.light-bg .uk-tab > li a.active{
	border-bottom: 2px solid;
}
.set-address ul li > a {
    border-bottom-width: 4px;
    padding-bottom: 12px;
}
.credit-card {
    display: inline-block;
    width: 100%;
}
.credit-card > h6 {
    float: left;
}
.credit-card > figure {
    float: right;
    margin: 0;
}
.credit-card > form {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
    position: relative;
}
.credit-card > figure img {
    max-width: 115px;
}
.billing {
    display: inline-block;
    width: 100%;
}
.billing form .chosen-container {
    width: 100% !important;
}
.credit-card.billing .chosen-container{
	width: 100% !important;
}
.number-demo {
    position: absolute;
    right: 20px;
    top: 14px;
}
.uk-margin,
.uk-grid-margin {
    position: relative;
}
.payment-methods {
    display: inline-block;
    width: 100%;
}
.payment-methods > h4 {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
}
.paypal-card {
    display: inline-block;
    width: 100%;
}
.paypal-card > p {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 20px;
    max-width: 90%;
}
.paypal-card > figure {
    margin: 0;
}
.paypal-card > form {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.paypal-card > figure img {
    max-width: 130px;
}
/*--- setting page ---*/
.setting-card form fieldset em {
    font-size: 12px;
    font-style: normal;
    padding-left: 5px;
    color: #92929e
}
.setting-card form > fieldset > h6 {
    display: inline-block;
    padding-left: 10px;
    width: 100%;
}
.social-links {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.social-links > input {
    border: 1px solid #dfdfdf;
    border-radius: 30px;
    font-size: 13px;
    padding: 4px 15px;
}
.social-links > em {
    color: #92929e;
    display: inline-block;
    margin-top: 6px;
    width: 100%;
}
.seting-mode > span {
    font-weight: 500;
}
/*--- tooltip style ---*/
.incolor + .tooltip > .tooltip-inner {
	background-color: #1f273f;;
	font-size: 13px;
	border-radius: 12px;
	z-index: 999999999999999 !important;
	position: relative !important;
}
.tooltip-arrow, .tooltip.bottom{
	border-bottom-color: #1f273f!important;	
}
/*--- search result page ---*/
.Search-result > h4 {
    color: #fff;
    display: inline-block;
    font-size: 25px;
    font-weight: 500;
    width: 100%;
}
.images-post{
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
}
.images-post a img {
    border-radius: 6px;
	width: 100%;
}
.related-searches {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 20px;
    width: 100%;
}
.related-searches > li {
    list-style: outside none disc;
    margin-bottom: 5px;
    width: 100%;
}
.related-searches > li > a {
    transition: all 0.2s linear 0s;
}
.group-box {
    display: inline-block;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}
.group-box > figure {
    display: inline-block;
    width: 100%;
}
.group-box > a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
}
.group-box > figure img {
    border-radius: 100%;
    width: 120px;
}
.group-box > span {
    font-size: 12px;
    font-weight: 400;
	color: #92929e;
}
.group-box > button {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #66bef5;
    border-radius: 20px;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-top: 20px;
    padding: 4px 10px;
    text-transform: capitalize;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.group-box > button:hover{
	color: #fff;
}
.video-posts{
	display: inline-block;
    width: 100%;
	position: relative;
	margin-bottom: 20px;
}
.video-posts > img {
    display: inline-block;
    width: 100%;
	border-radius: 5px;
}
.video-posts a.play-btn {
    display: inline-block;
    width: 100%;
}
.video-posts a.play-btn {
    background: rgba(8, 141, 205, 0.7) none repeat scroll 0 0;
    border: 1px solid;
    border-radius: 100%;
    color: #fff;
    font-size: 25px;
    left: 50%;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50px;
}
.video-posts a.play-btn > i{
	padding-left: 6px;
}
/*--- create new post input ---*/
.filtr-tabs {
    display: inline-block;
    list-style: outside none none;
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
}
.filtr-tabs > li {
    display: inline-block;
    margin-right: 5px;
}
.filtr-tabs > li > a {
    background: #f4f4f4 none repeat scroll 0 0;
    border-radius: 6px;
    color: #088dcd;
    display: inline-block;
    padding: 7px 20px;
    font-size: 14px;
}
.filtr-tabs > li > a:hover {
    background: #ebebeb none repeat scroll 0 0;
}
.filtr-tabs > li > a.active {
    background: #088dcd none repeat scroll 0 0;
    color: #fff;
}

.new-post {
    display: inline-block;
    position: relative;
    width: 100%;
}
.new-title {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
}
.new-post > form {
    display: inline-block;
    width: 100%;
	position: relative;
}
.new-post > form input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    padding: 10px 20px 10px 50px;
    width: 100%;
}
.new-post > form > i {
    font-size: 16px;
    left: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.upload-media {
    display: inline-block;
    list-style: outside none none;
    margin: 20px 0 0;
    padding-left: 0;
    width: 100%;
}
.upload-media > li {
    background: transparent none repeat scroll 0 0;
    border-radius: 30px;
    display: inline-block;
    font-size: 12px;
    padding: 6px 20px;
    transition: all 0.2s linear 0s;
    vertical-align: middle;
    cursor: pointer;
}
.upload-media > li > a{
	display: inline-block;
	width: 100%;
}
.upload-media > li > a > i img {
    max-width: 20px;
}
.upload-media > li > a i {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
.upload-media > li > a > span {
    font-size: 12px;
    font-weight: 500;
    vertical-align: middle;
}
.upload-media > li:hover {
    background: #e7e7e7 none repeat scroll 0 0;
}
/*--- inquiry popup ---*/
.auto-popup {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
	display: none;
	transition: all 0.5s linear 0s;
}
.auto-popup.closed{
	opacity: 0;
	visibility: hidden;
}
.popup-innner {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    left: 50%;
    padding: 20px;
    position: absolute;
    top: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	width: 400px;
}
.popup-innner::before {
    background: red none repeat scroll 0 0;
    border-radius: 10px;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.popup-head > h4 {
    font-size: 18px;
    font-weight: 500;
}
.auto-popup .popup-meta > span {
    display: inline-block;
    font-weight: 500;
    margin-top: 15px;
    width: 100%;
	color: #62626e;
}
.inquiry-about {
    display: inline-block;
    margin-top: 20px;
    position: relative;
    width: 100%;
}
.inquiry-about > input[type="text"] {
    border-color: -moz-use-text-color -moz-use-text-color #dadada;
    border-style: none none solid;
    border-width: 0 0 1px;
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}
.inquiry-about > h5 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
}
.inquiry-about > label {
    color: #62626e;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 10px;
    vertical-align: middle;
    width: 100%;
}
.inquiry-about > label input {
    margin-right: 5px;
    vertical-align: middle;
}
.inquiry-about button {
    margin-top: 20px;
}
/*--- event calendar widget ---*/
.widget.e-calendar {
    padding-bottom: 0;
}
.jalendar .jalendar-container .jalendar-pages{
    background: none !important;
	padding: 0;
}
.jalendar .jalendar-container .jalendar-pages .days .day.this-month span{
    color: inherit;
    font-weight: 400;
    font-size: 12px;
}
.jalendar .jalendar-container .jalendar-pages > .header h1{
    color: #535165;
    text-shadow: none;
}
.jalendar .jalendar-container .jalendar-pages .add-event .events .events-list .event-single a,
.jalendar .jalendar-container .jalendar-pages > .header .nxt-m, .jalendar .jalendar-container .jalendar-pages > .header .prv-m{
    color: inherit;
}
.jalendar .jalendar-container .jalendar-pages .add-event .events .events-list .event-single{
    padding: 0;
    margin: 0;
}
.jalendar .jalendar-container .jalendar-pages .add-event .close-button {
    margin-bottom: -25px;
	position: unset;
	background-color: #757a95;
	padding: 8px 0;
}
.jalendar .jalendar-container {
    margin-top: -30px;
}
.jalendar .jalendar-container .jalendar-pages .days .day.have-event span::before {
    border-radius: 0;
    bottom: 2px;
    top: auto;
    content: "";
    display: inline-block;
    height: 3px;
    position: absolute;
    right: 0;
    width: 100%;
}
.jalendar .jalendar-container .jalendar-pages .total-bar{
    background: #7750f8;
    line-height: 15px;
    height: 18px;
    width: 18px;
}
.jalendar{
    margin-bottom: 0;
    margin-top: 20px;
    width: 100%;
}
.jalendar .jalendar-container .jalendar-pages .add-event.selected {
    background: #e6ebef none repeat scroll 0 0;
    color: #535165;
    margin-bottom: -5px;
}
.jalendar .jalendar-container .add-event.selected .events > h3 {
    color: #535165;
    font-size: 14px;
    margin-bottom: 10px;
}
.jalendar .jalendar-container .jalendar-pages .add-event .close-button{
    font-size: 13px;
}
.jalendar .jalendar-container .jalendar-pages .add-event .close-button:hover{
    background: #757a95;
}
.jalendar .jalendar-container .jalendar-pages .days .day.this-month.selected span{
	color: #088dcd !important;
}
.jalendar .jalendar-container .jalendar-pages .days .day.have-event span::before{
	background: #088dcd !important;
}
/*--- calendar style end ---*/
/*--- create post new widget ---*/
.widget p {
    font-size: 13px;
    line-height: 21px;
	color: #3e3f5e;
}
.bg-feature {
    display: inline-block;
    position: relative;
    width: 100%;
}
.bg-feature > img {
    border-radius: 5px;
    width: 100%;
}
.bg-feature > span {
    color: #fff;
    font-weight: 500;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
}
.bg-feature::before {
    background: #088dcd none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 5px;
}
.create-meta {
    display: inline-block;
    margin-top: 15px;
    width: 100%;
}
a.main-btn2, button.main-btn2 {
    border: 1px solid #088dcd;
    border-radius: 35px;
    color: #088dcd;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 10px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 100%;
}
a.main-btn2:hover, button.main-btn2:hover {
    background: #088dcd none repeat scroll 0 0;
    color: #fff;
}
.create-meta > p {
    font-size: 12px;
    padding: 0 10px;
}
/*--- complete profile widget ---*/
.widget .prof-complete {
    margin-top: 20px;
}
.widget .prof-complete > li a {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
}
.widget .prof-complete > li em {
    float: right;
    font-size: 11px;
    font-style: normal;
    margin-top: 5px;
}
/*--- socials widget ---*/
.widget .socials > li i {
    border: 1px solid;
    border-radius: 100%;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    width: 30px;
    transition: all 0.2s linear 0s;
}
.socials > li > a {
    display: inline-block;
    font-size: 12px;
    padding-left: 10px;
    vertical-align: middle;
}
.socials > li > a span {
    font-weight: 500;
}
.widget .socials > li:hover > i {
    color: #fff;
    border-color: transparent;
}
/*--- suggested groups ---*/
.suggestd > li a {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-right: 6px;
    vertical-align: middle;
}
.suggestd > li a img {
    border-radius: 100%;
}
.suggestd > li button.sug-like {
    border: medium none;
    border-radius: 5px;
    float: right;
    font-size: 16px;
    height: 28px;
    line-height: 28px;
    margin-top: 12px;
    width: 28px;
}
.suggestd > li button.sug-like i {
    margin: 0;
}
.suggestd > li button.sug-like .icofont-check-alt {
    display: none;
}
.suggestd > li button.sug-like.active .icofont-check-alt{
	display: block;
}
.suggestd > li button.sug-like.active .icofont-like{
	display: none;
}
/*--- profile introl ---*/
.widget .short-profile > li::before {
    color: rgba(8, 141, 205, 0.2);
    content: "";
    font-family: icofont;
    font-size: 50px;
    position: absolute;
    right: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 0;
}
.widget .short-profile > li.user-about::before {
	content: "\ed04";
}
.widget .short-profile > li.fav-game::before {
	content: "\ec5c";
}
.widget .short-profile > li.fav-music::before {
	content: "\ec6b";
}
.short-profile > li p {
    margin-bottom: 0;
}
.short-profile > li > span {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: capitalize;
    width: 100%;
}
/*--- activities widget ---*/
.activity-meta {
    display: inline-block;
    width: 100%;
}
.activity-meta > i {
    color: #999;
    float: left;
    font-size: 12px;
    width: 100%;
}
.activity-meta > span {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
}
.activity-meta > h6 {
    float: left;
    font-size: 13px;
    width: 100%;
}
.activity-meta > h6 a {
	font-weight: 500;
}
aside.sidebar .widget .activity-meta > i{
	color: #92929e;
	font-style: normal;
	font-size: 11px;
}
.activitiez > li::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 2px;
}
.activitiez > li {
    padding-left: 12px;
}
/*--- invite friends widget ---*/
.sidebar .invitepage > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 77%;
}
.sidebar .invitepage > li button {
    border-radius: 4px;
    display: inline-block;
    height: 22px;
    line-height: 22px;
    padding: 0;
    width: 45px;
}
.sidebar .invitepage > li button .icofont-check-alt {
    display: none;
}
.sidebar .invitepage > li button i {
    font-size: 12px;
    font-style: normal;
    margin-right: 0;
}
.sidebar .invitepage > li button {
    border: medium none;
}
.sidebar .invitepage > li button.active .icofont-check-alt {
    display: block;
    font-size: 17px;
}
.sidebar .invitepage > li button.active .invit {
    display: none;
}
.sidebar .invitepage > li figure img {
    border-radius: 100%;
    margin-right: 5px;
}
.sidebar .invitepage > li figure a {
    font-size: 13px;
    font-weight: 500;
}
/*--- featured universities ---*/
.widget .featured-comp > li {
    float: left;
    text-align: center;
    width: 25%;
}
.company-posts > li figure img {
    border-radius: 100%;
}
.widget .featured-comp > li a img {
    border-radius: 100%;
}
.widget .featured-comp > li:nth-last-child(1), 
.widget .featured-comp > li:nth-last-child(2), 
.widget .featured-comp > li:nth-last-child(3), 
.widget .featured-comp > li:nth-last-child(4) {
    margin-bottom: 0;
}
/*--- twitter feed ---*/
.sidebar .widget .twiter-feed > li > i {
    color: rgba(8, 141, 205, 0.2);
    font-size: 60px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.twiter-feed > li > div span {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 4px;
    width: 100%;
}
.twiter-feed > li > div p {
    color: #5a6381;
    margin-bottom: 5px;
}
.twiter-feed > li > div em {
    color: #92929e;
    font-size: 11px;
    font-style: normal;
	float: right;
}
/*--- university highlights ---*/
.company-posts > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
}
.position-meta {
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
    width: 77.5%;
}
.position-meta > h6 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
}
.position-meta > span {
    float: right;
    font-size: 12px;
    margin-top: -5px;
    text-transform: capitalize;
}
.position-meta > a {
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
	color: #92929e;
}
.company-posts > li {
    border-bottom: 1px dashed #cdcdcd;
    padding-bottom: 15px;
}
.company-posts > li:last-child {
    border-bottom: 0 none;
    padding-bottom: 0;
}
/*--- recent blog widget ---*/
.recent-links > li > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    width: 85px;
}
.recent-links > li figure img {
    border-radius: 5px;
}
.re-links-meta {
    display: inline-block;
    padding-left: 7px;
    vertical-align: middle;
    width: 59.5%;
}
.re-links-meta > h6 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
}
.re-links-meta > span {
    font-size: 11px;
    font-weight: 300;
    color: #92929e;
}
/*--- friend box ---*/
.friend-box {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ede9e9;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}
.friend-box > figure {
    margin: 0;
    position: relative;
}
.friend-box > figure img {
    border-radius: 4px;
    display: inline-block;
    width: 100%;
}
.friend-box > figure::before {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    border-radius: 4px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.friend-box > figure > span {
    bottom: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 10px;
	text-shadow: 0 2px 2px rgba(0,0,0,.7);
}
.frnd-meta {
    display: inline-block;
    padding: 0 15px 20px;
    position: relative;
    width: 100%;
}
.frnd-meta > img {
    border: 2px solid #fff;
    border-radius: 100%;
    display: inline-block;
    margin-top: -40px;
    position: relative;
    vertical-align: middle;
    z-index: 2;
}
.frnd-name {
    float: right;
    margin-bottom: 20px;
    margin-left: 110px;
    margin-top: -40px;
    text-align: left;
}
.frnd-name > a {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.frnd-name > span {
    display: inline-block;
    font-size: 12px;
	color: #92929e;
}
/*--- useful links widget ---*/
.widget.web-links ul li > i {
    font-size: 20px;
}
.widget.web-links ul li > a {
    font-size: 13px;
    text-transform: capitalize;
}
.widget.web-links ul li:hover > i{
	color: inherit;
}
.widget.web-links > p {
    display: inline-block;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}
/*--- tags widget ---*/
.pit-tags {
    display: inline-block;
    margin-top: 5px;
    width: 100%;
}
.pit-tags > span {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 5px;
    width: 100%;
}
.pit-tags > a {
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    display: inline-block;
    font-size: 10.2px;
    margin-bottom: 5px;
    padding: 2px 8px;
    transition: all 0.2s linear 0s;
}
.pit-tags > a:hover {
    color: #fff;
}
/*--- ad widget ---*/
.advertisment-box {
    display: inline-block;
    width: 100%;
	margin-bottom: 30px;
}
.advertisment-box > figure {
    display: inline-block;
    margin-top: 0;
    width: 100%;
	margin-bottom: 0;
}
.advertisment-box > figure a {
    display: inline-block;
    width: 100%;
}
.advertisment-box > h4 {
    color: #8f97af;
    font-size: 10px;
    margin-bottom: 7px;
    text-transform: uppercase;
}
/*--- carousel menu ---*/
.sidemenu {
    display: inline-block;
    line-height: 57px;
    vertical-align: middle;
}
.sidemenu > i{
	cursor: pointer;
	position: relative;
}
.sidemenu > i::before,
.res-search > span:before {
    background: #deebf3 none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 40px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 40px;
    z-index: -1;
}
.sidemenu > i:hover::before,
.res-search > span:hover:before {
    opacity: 1;
    -webkit-transform: translate(-50%, -47%) scale(1);
            transform: translate(-50%, -47%) scale(1);
    visibility: visible;
}

.white-bg{
	background: #fff;
	position: relative;
	z-index: 99;
}
.menu-caro {
    display: block;
    line-height: 30px;
    width: 100%;
	border-bottom: 1px solid #f5f5f5;
}
.menu-caro .stars {
    position: unset;
    width: 100%;
	display: inline-block;
}
.menu-caro .stars > li {
	display: inline-block;
	line-height: normal;
	line-height: initial;
}
.menu-caro .user-inf{
	display: inline-block;
    line-height: 17px;
    margin-top: 14px;
    text-align: right;
    width: 100%;
}
.menu-caro .folowerz {
    color: inherit;
    position: unset;
}
.link-item {
    text-align: center;
    vertical-align: middle;
}
.link-item > a > i {
    display: inline-block;
    font-size: 22px;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    transition: all 0.3s ease-in-out 0s
}
.link-item > a:hover i {
    opacity: 0;
    -webkit-transform: translate(0px);
            transform: translate(0px);
    visibility: hidden;
	vertical-align: middle;
}
.link-item > a:hover p {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
.link-item > a {
    color: #adafca;
    display: block;
    font-size: 15px;
    position: relative;
	border-bottom: 3px solid transparent;
}
.link-item > a p {
    color: #3d3d49;
    font-size: 14px;
	font-weight: 500;
    margin: 0;
    text-transform: capitalize;
    -webkit-transform: translateY(22px);
            transform: translateY(22px);
    transition: all 0.3s ease-in-out 0s;
}
.link-item > a:hover,
.link-item > a.active{
    border-bottom: 3px solid;
}
.stat-tools > .box a > i {
    color: #7e7f9e;
    font-size: 16px;
    vertical-align: middle;
	margin-right: 3px;
}
.stat-tools > a.comment-to > i {
    color: #7e7f9e;
    font-size: 16px;
    vertical-align: middle;
	margin-right: 3px;
}
.stat-tools > a.share-to > i {
    color: #7e7f9e;
    font-size: 16px;
    vertical-align: middle;
	margin-right: 3px;
}
/*--- like icons emojies ---*/
.stat-tools {
    display: inline-block;
    margin-top: 20px;
    text-align: left;
    width: 100%;
}
.stat-tools > a, .stat-tools > .box a {
    background: #eae9ee;
    border-radius: 4px;
    color: #82828e;
    display: inline-block;
    font-size: 13px;
    padding: 5px 20px;
    vertical-align: middle;
    transition: all 0.2s linear 0s;
}
.stat-tools > a:hover,
.stat-tools > a.active {
    color: #7d7f9a;
    background: #e5e4e9;
}
/*--- like hover emojies ---*/
.Like {
  position: relative;
  display: inline-block;
}
.Like__link {
  cursor: pointer;
}
.box {
    display: inline-block;
    vertical-align: middle;
}
.box > .Like > a:hover {
    background: #e5e4e9 none repeat scroll 0 0;
    color: #7d7f9a;
}    
.Emojis {
	background: #fff;
  position: absolute;
  left: 16px;
  bottom: 37px;
  -webkit-transform: translate3d(-6%, 0, 0);
          transform: translate3d(-6%, 0, 0);
  display: flex;
  border-radius: 40px;
  transition: .3s .3s;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
}
.Emojis:hover, .Like__link.js-hover ~ .Emojis, .Like__link:hover ~ .Emojis {
  transition-delay: 0s;
  opacity: 1;
  visibility: visible;
}
.Emoji {
  position: relative;
  width: 3rem;
  height: 3rem;
  color: #ffd972;
  cursor: pointer;
	border-radius: 100%;
  transition-delay: .6s, .3s;
  transition-duration: 0s, .3s;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0) scale(0.3);
          transform: translate3d(0, 100%, 0) scale(0.3);
}
.Emojis:hover .Emoji, .Like__link.js-hover + .Emojis .Emoji, .Like__link:hover + .Emojis .Emoji {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
}
.Emoji .icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	border-radius: 100%;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
}
.Emoji:hover .icon {
  -webkit-transform: scale(0.75) translate3d(0, -5%, 0);
          transform: scale(0.75) translate3d(0, -5%, 0);
}
.Emojis:hover .Emoji:nth-of-type(1), .Like__link:hover + .Emojis .Emoji:nth-of-type(1) {
  transition-duration: 0.2s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 1);
}
.Emojis:hover .Emoji:nth-of-type(2), .Like__link:hover + .Emojis .Emoji:nth-of-type(2) {
  transition-duration: 0.3s;
  transition-delay: 0.02s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 1.2);
}
.Emojis:hover .Emoji:nth-of-type(3), .Like__link:hover + .Emojis .Emoji:nth-of-type(3) {
  transition-duration: 0.4s;
  transition-delay: 0.04s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 1.4);
}
.Emojis:hover .Emoji:nth-of-type(4), .Like__link:hover + .Emojis .Emoji:nth-of-type(4) {
  transition-duration: 0.5s;
  transition-delay: 0.06s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 1.6);
}
.Emojis:hover .Emoji:nth-of-type(5), .Like__link:hover + .Emojis .Emoji:nth-of-type(5) {
  transition-duration: 0.6s;
  transition-delay: 0.08s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 1.8);
}
.Emojis:hover .Emoji:nth-of-type(6), .Like__link:hover + .Emojis .Emoji:nth-of-type(6) {
  transition-duration: 0.7s;
  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0.6, 0.6, 0.5, 2);
}
.Emoji--like {
  /* color: $c-blue; */
}
.Emoji--love {
  /* color: $c-red; */
}
.icon {
  background-image: url("https://giphy.com/gifs/xT9DPr2QnsfTFoykFO");
  background-repeat: no-repeat;
}
.icon--like {
  background-position: -6px -6px;
}
.icon--heart {
  background-position: -68px -4px;
}
.icon--haha {
  background-position: -129px -6px;
}
.icon--wow {
  background-position: -191px -6px;
}
.icon--sad {
  background-position: -252px -5px;
}
.icon--angry {
  background-position: -314px -6px;
}
.emoji-state {
    float: right;
    margin-top: -2px;
    width: auto;
}
.emoji-state > .popover_wrapper{
	display: inline-block;
	vertical-align: text-top;
}
.emoji-state > .popover_wrapper > a img {
    border: 2px solid #fff;
    border-radius: 100%;
    float: left;
    margin-left: -8px;
    max-width: 26px;
	cursor: pointer;
}
.emoji-state > p {
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    margin-bottom: 0;
    vertical-align: sub;
	color: #3e3f5e;
}
.namelist {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.namelist > li {
    color: #82828e;
    float: left;
    font-size: 11px;
    line-height: 16px;
    width: 100%
}
.namelist > li span{
	font-weight: 600;
	color: #3e3f5e;
}
/*--- share popup ---*/
.share-wraper {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 999999;
    transition: all 0.2s linear 0.1s;
}
.share-wraper.active{
  opacity: 1;
  visibility: visible;
}
.share-options {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    left: 50%;
    opacity: 0;
    padding: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    visibility: hidden;
    width: 400px;
    transition: all 0.3s linear 0.1s;
}
.share-wraper.active .share-options{
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.share-options > h5 {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 18px;
    width: 100%;
	text-align: center;
}
.share-options > h5 i {
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
}
.share-options > form {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.share-options > form > textarea {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    color: inherit;
    display: inline-block;
    font-family: inherit;
    font-size: 13px;
    padding: 15px;
    width: 100%;
}
.share-options > ul {
    display: inline-block;
    list-style: outside none none;
    width: 100%;
    margin: 0 0 20px;
	padding-left: 0;
}
.share-options > ul > li {
    display: inline-block;
    margin-right: 4px;
}
.share-options > ul > li a {
    background: #eaeaea none repeat scroll 0 0;
    border-radius: 30px;
    display: inline-block;
    font-size: 11px;
    padding: 7px 15px;
    text-transform: capitalize;
    transition: all 0.2s linear 0s;
}
.share-options > ul > li a:hover,
.share-options > ul > li a.active{
  color: #fff;
}
.share-options > .main-btn{
	padding: 6px 20px;
	width: 100%;
}
.social-media, .friends-to {
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 100%;
}
.social-media > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    width: 100%;
	padding-left: 0;
}
.social-media > ul li {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;
}
.social-media > ul li a {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    line-height: 33px;
    margin-bottom: 5px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 33px;
}
.social-media > ul li a:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
a.main-btn, .main-btn{
    font-size: 13px;
    font-weight: 500;
    transition: all 0.2s linear 0s;
}
a.main-btn:hover, .main-btn:hover, a.main-btn2:hover, .main-btn2:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.close-btn {
    cursor: pointer;
    float: right;
    font-size: 22px;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 99;
}
.new-comment {
    display: none;
    margin-top: 15px;
    position: relative;
    width: 100%;
}
.new-comment > form {
    display: inline-block;
    width: 100%;
	position: relative;
}
.new-comment form input {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    color: inherit;
    display: inline-block;
    padding: 15px;
    width: 100%;
}
.new-comment form button {
    background: transparent none repeat scroll 0 0;
    border: 0 none;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 17px;
}
.new-comment form button i {
    color: #92929e;
    font-size: 20px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.follow-men {
    border-bottom: 1px solid #e2e2ee;
    display: inline-block;
    margin-bottom: 5px;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
}
.follow-men:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
}
.follow-men > figure {
    display: inline-block;
    vertical-align: middle;
}
.follow-men > figure img {
    border: 2px solid #ccc;
    border-radius: 100%;
    max-width: 48px;
}
.follow-meta {
    display: inline-block;
    padding-left: 8px;
    vertical-align: middle;
}
.follow-men > a {
    border-radius: 30px;
    color: #fff;
    float: right;
    font-size: 12px;
    margin-top: 10px;
    padding: 6px 12px;
}
.follow-meta > a {
    font-size: 12px;
}
.follow-meta > h5 {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 0;
    width: 100%;
}
.follow-meta > span {
    color: #92929e;
    font-size: 11px;
}
.blog-news {
    display: inline-block;
    margin-bottom: 11px;
    width: 100%;
}
/*--- Your group widget ---*/
.ak-groups > li figure {
    display: inline-block;
    margin-bottom: 0;
    max-width: 60px;
    vertical-align: middle;
}
.widget .ak-groups > li {
    border-bottom: 1px dashed #c2c2ce;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.widget .ak-groups > li:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.ak-groups > li figure img {
    border-radius: 100%;
}
.your-grp {
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
	max-width: 70%;
}
.your-grp > h5 {
    font-size: 13px;
    font-weight: 500;
}
.your-grp > a {
    color: #92929e;
    display: inline-block;
    font-size: 12px;
    width: 100%;
}
.your-grp > a > span {
    border: 1px solid;
    border-radius: 4px;
    font-size: 10px;
    padding: 1px 3px;
	color: #f0506e;
}
.your-grp > a.promote {
    text-decoration: underline;
}
/*--- sponsored widget ---*/
.widget .sponsors-ad {
    margin-top: 10px;
}
.widget .sponsors-ad > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.widget .sponsors-ad > li .sponsor-meta {
    display: inline-block;
    padding-left: 8px;
    vertical-align: middle;
    width: 60%;
}
.widget .sponsors-ad > li .sponsor-meta h5 {
    font-size: 14px;
    font-weight: 500;
}
.widget .sponsors-ad > li .sponsor-meta > a {
    color: #92929e;
    font-size: 12px;
}
.widget .sponsors-ad > li figure img {
    border-radius: 4px;
}
/*--- premium post --*/
.post-meta > figure.premium-post img {
    max-width: 150px;
}
.post-meta > figure.premium-post {
    margin-top: 10px;
	width: auto;
	vertical-align: middle;
}
.post-meta .premium {
    display: inline-block;
    max-width: 69%;
    padding-left: 15px;
    vertical-align: middle;
}
.premium .post-title {
    font-size: 15px;
}
.premium > p {
    font-size: 13px;
    line-height: 22px;
}
.vid-course > h5 {
    display: inline-block;
    font-weight: 500;
    line-height: 19px;
    width: 100%;
    font-size: 15px;
}
.premium-course > li figure {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}
.premium-course > li figure img {
    border-radius: 5px;
}
.premium-course > li figure .tag {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    border-radius: 0 20px 20px 0;
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 2px 10px;
    position: absolute;
    top: 10px;
}
.vid-course .price {
    float: right;
    font-weight: 500;
}
/*--- recommended group style ---*/
.rec-group {
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}
.rec-group > span {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    font-size: 11px;
    left: 8px;
    line-height: 14px;
    padding: 3px 10px;
    position: absolute;
    top: 10px;
    z-index: 3;
}
.rec-groupbox {
    display: inline-block;
    width: 100%;
}
.rec-groupbox > figure {
    display: inline-block;
    margin-bottom: 0;
    width: 100%;
}
.rec-groupbox > figure img {
    border-radius: 5px;
    width: 100%;
}
.grp-dp {
    display: inline-block;
    left: 30px;
    position: absolute;
    top: 55%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
}
.grp-dp > img {
    border: 3px solid #fff;
    border-radius: 100%;
    max-width: 90px;
}
.rec-group .more {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}
.rec-group .more-post-optns > i{
	color: #fff;

}
.rec-group .more-post-optns::before{
	background: rgba(0,0,0,.5)
}
.grp-name {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    bottom: 0;
    color: #fff;
    left: 0;
    padding-bottom: 10px;
    padding-left: 130px;
    padding-top: 5px;
    position: absolute;
    width: 100%;
	border-radius: 6px;
}
.grp-name > h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
.grp-name > span {
    color: #d9d9d9;
    font-size: 12px;
    padding-left: 20px;
}
.grp-name > a {
    border: 1px solid;
    border-radius: 30px;
    float: right;
    font-size: 11px;
    padding: 1px 15px;
    margin-right: 10px;
}
.rec-grp-caro .owl-nav {
    position: absolute;
    right: 20px;
    top: -45px;
}
.rec-grp-caro .owl-nav .owl-prev {
    left: -47px;
}
.rec-grp-caro .owl-dots {
    margin-top: 20px;
}
.rec-grp-caro .owl-next::before,
.rec-grp-caro .owl-prev::before{
	box-shadow: none;
}
.rec-grp-caro .owl-next::before {
    content: "\eaa0";
	font-family: icofont;
}
.rec-grp-caro .owl-prev::before {
    content: "\ea9d";
	font-family: icofont;
}
/*--- video sidebar ---*/
.video-search {
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}
.video-search > input {
    border: 1px solid #eaeaea;
    border-radius: 30px;
    display: inline-block;
    font-size: 13px;
    padding: 9px 27px 9px 15px;
    width: 100%;
}
.video-search > i {
    font-size: 15px;
    position: absolute;
    right: 10px;
    top: 7px;
}
.video-search > button {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 15px;
    visibility: hidden;
}
.video-links > li > a i {
    font-size: 15px;
}
.widget ul.video-links {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.video-links > li > a {
    display: inline-block;
    font-size: 14px;
    padding: 9px 0;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.sidebar .widget .video-links > li {
    margin-bottom: 8px;
}
.video-links > li > a:hover {
    background: #e9f3ff none repeat scroll 0 0;
    border-radius: 30px;
}
.watchlist > li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.watchlist > li figure img {
    border: 1px solid red;
    border-radius: 100%;
}
.watchlist > li a {
    display: inline-block;
    font-weight: 400;
    padding-left: 6px;
    vertical-align: middle;
	width: 76%;
}
.widget ul.watchlist {
    border-bottom: 0 none;
    margin-bottom: 0;
    margin-top: 20px;
    padding-bottom: 0;
}
.unread .new-highlight {
    background: red none repeat scroll 0 0;
    border-radius: 100%;
    display: inline-block;
    float: right;
    height: 10px;
    position: absolute;
    top: 20px;
    width: 10px;
}
.watchlist > li.active{
	background: #e9f3ff none repeat scroll 0 0;
	border-radius: 30px;
}
.widget .main-title {
    border-bottom: 0 none;
    font-size: 16px;
    margin-bottom: 0;
}
/*--- Content Loader spiners ---*/
.sp {
  width: 32px;
  height: 32px;
  clear: both;
  margin: 20px auto;
}
/* Spinner Circle Rotation */
.sp-circle {
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px solid #088dcd;
  border-radius: 50%;
  -webkit-animation: spCircRot .6s infinite linear;
  animation: spCircRot .6s infinite linear;
}
@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/* Spinner 3Balls Scale */
.sp-3balls, .sp-3balls:before, .sp-3balls:after {
  border-radius: 50%;
  background-color: #088dcd;
  width: 18px;
  height: 18px;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  display: inline-block;
}
.sp-3balls {
  position: relative;
  background-color: #088dcd;
  opacity: 1;
  -webkit-animation: spScaleAlpha 1s infinite linear;
  animation: spScaleAlpha 1s infinite linear;
}
.sp-3balls:before, .sp-3balls:after {
  content: '';
  position: relative;
  opacity: 0.25;
}
.sp-3balls:before {
  left: 30px;
  top: 0px;
  -webkit-animation: spScaleAlphaBefore 1s infinite linear;
  animation: spScaleAlphaBefore 1s infinite linear;
}
.sp-3balls:after {
  left: -30px;
  top: -23px;
  -webkit-animation: spScaleAlphaAfter 1s infinite linear;
  animation: spScaleAlphaAfter 1s infinite linear;
}
@-webkit-keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@keyframes spScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@-webkit-keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes spScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
/* Spinner VolumeButton */
.sp-volume {
  background-color: #088dcd;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spVolRot .6s infinite linear;
  animation: spVolRot .6s infinite linear;
}
.sp-volume:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  left: 5px;
  top: 5px;
  background-color: white;
}
@-webkit-keyframes spVolRot {
  from {
    -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
  }
}
@keyframes spVolRot {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/* Spinner Vortex */
.sp-vortex {
  border: 1px #088dcd solid;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.sp-vortex:after, .sp-vortex:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: inherit;
  height: inherit;
  -webkit-animation: spVortex 2s infinite linear;
  animation: spVortex 2s infinite linear;
}
.sp-vortex:before {
  border-top: 6px #088dcd solid;
  top: -3px;
  left: calc( -50% - 3px );
  -webkit-transform-origin: right center;
          transform-origin: right center;
}
.sp-vortex:after {
  border-bottom: 6px #088dcd solid;
  top: 3px;
  right: calc( -50% - 3px );
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
@-webkit-keyframes spVortex {
  from {
    -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
  }
}
@keyframes spVortex {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/* Spinner Slices */
.sp.sp-slices {
  border-radius: 50%;
  border-top: 16px rgba(0, 0, 0, 0.75) solid;
  border-left: 16px rgba(0, 0, 0, 0.25) solid;
  border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
  border-right: 16px rgba(0, 0, 0, 0.25) solid;
  -webkit-animation: spSlices 1s infinite linear;
  animation: spSlices 1s infinite linear;
}
@-webkit-keyframes spSlices {
  0% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  25% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.75) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  50% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.75) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  75% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.75) solid;
  }
  100% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
}
@keyframes spSlices {
  0% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  25% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.75) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  50% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.75) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
  75% {
    border-top: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.75) solid;
  }
  100% {
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
  }
}
/* Spinner Sphere */
.sp-sphere {
  border-radius: 50%;
  border-left: 0px #088dcd solid;
  border-right: 0px #088dcd solid;
  -webkit-animation: spSphere 1s infinite linear;
  animation: spSphere 1s infinite linear;
}
@-webkit-keyframes spSphere {
  0% {
    border-left: 0px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
  33% {
    border-left: 32px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
  34% {
    border-left: 0px #088dcd solid;
    border-right: 32px #088dcd solid;
  }
  66% {
    border-left: 0px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
}
@keyframes spSphere {
  0% {
    border-left: 0px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
  33% {
    border-left: 32px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
  34% {
    border-left: 0px #088dcd solid;
    border-right: 32px #088dcd solid;
  }
  66% {
    border-left: 0px #088dcd solid;
    border-right: 0px #088dcd solid;
  }
}
/* Spinner Bars */
.sp-bars {
  position: relative;
  width: 7px;
  border: 1px #088dcd solid;
  background-color: rgba(8, 141, 205, 0.25);
  -webkit-animation: spBars 1s infinite linear;
  animation: spBars 1s infinite linear;
  border-radius: 10px;
  height: 25px;
}
.sp-bars:after, .sp-bars:before {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border: inherit;
  background-color: inherit;
  top: -1px;
  border-radius: 10px;
}
.sp-bars:before {
  left: -15px;
  -webkit-animation: spBarsBefore 1s infinite linear;
  animation: spBarsBefore 1s infinite linear;
}
.sp-bars:after {
  right: -15px;
  -webkit-animation: spBarsAfter 1s infinite linear;
  animation: spBarsAfter 1s infinite linear;
}
@-webkit-keyframes spBarsBefore {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  50% {
    -webkit-transform: scale(1, 0.75);
            transform: scale(1, 0.75);
  }
  75% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes spBarsBefore {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  50% {
    -webkit-transform: scale(1, 0.75);
            transform: scale(1, 0.75);
  }
  75% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes spBars {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  75% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes spBars {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  75% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes spBarsAfter {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.75);
            transform: scale(1, 0.75);
  }
  75% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes spBarsAfter {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.75);
            transform: scale(1, 0.75);
  }
  75% {
    -webkit-transform: scale(1, 1.25);
            transform: scale(1, 1.25);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
/* Spinner Clock */
.sp-clock {
  border: 1px #088dcd solid;
  border-radius: 50%;
  position: relative;
}
.sp-clock:before {
  content: '';
  border-left: 1px #088dcd solid;
  position: absolute;
  top: 2px;
  width: 1px;
  height: calc( 50% - 2px );
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-animation: spClock 1s infinite linear;
  animation: spClock 1s infinite linear;
}
@-webkit-keyframes spClock {
  from {
    -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
  }
}
@keyframes spClock {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/* Spinner Wave */
.sp-wave {
  border-radius: 50%;
  position: relative;
  opacity: 1;
}
.sp-wave:before, .sp-wave:after {
  content: '';
  border: 1px #088dcd solid;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
}
.sp-wave:before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
  -webkit-animation: spWaveBe 0.6s infinite linear;
  animation: spWaveBe 0.6s infinite linear;
}
.sp-wave:after {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
  -webkit-animation: spWaveAf 0.6s infinite linear;
  animation: spWaveAf 0.6s infinite linear;
}
@-webkit-keyframes spWaveAf {
  from {
    -webkit-transform: scale(0.5, 0.5);
	  transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1, 1);
	  transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes spWaveAf {
  from {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes spWaveBe {
  from {
    -webkit-transform: scale(1, 1);
	  transform: scale(1, 1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
	  transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
@keyframes spWaveBe {
  from {
    -webkit-transform: scale(1, 1);
	  transform: scale(1, 1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
	  transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
/* Spinner Texture */
.sp-texture {
  border: 1px #088dcd solid;
  border-radius: 4px;
  position: relative;
  background: linear-gradient(45deg, transparent 49%, #088dcd 50%, #088dcd 50%, transparent 51%, transparent), linear-gradient(-45deg, transparent 49%, #088dcd 50%, #088dcd 50%, transparent 51%, transparent);
  background-size: 16px 16px;
  background-position: 0% 0%;
  -webkit-animation: spTexture 1s infinite linear;
  animation: spTexture 1s infinite linear;
}
@-webkit-keyframes spTexture {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -16px 0px;
  }
}
@keyframes spTexture {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -16px 0px;
  }
}
/* Spinner LoadBar */
.sp-loadbar {
  width: 50px;
  height: 18px;
  border: 1px #088dcd solid;
  border-radius: 4px;
  background: linear-gradient(-60deg, transparent 0%, transparent 50%, #088dcd 50%, #088dcd 75%, transparent 75%, transparent);
  background-size: 20px 30px;
  background-position: 0px 0px;
  -webkit-animation: spLoadBar 0.8s infinite linear;
  animation: spLoadBar 0.8s infinite linear;
}
@-webkit-keyframes spLoadBar {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -20px 0px;
  }
}
@keyframes spLoadBar {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -20px 0px;
  }
}
/* Spinner Hydrogen */
.sp-hydrogen {
  position: relative;
  border: 1px #088dcd solid;
  border-radius: 50%;
  -webkit-animation: spHydro 0.6s infinite linear;
  animation: spHydro 0.6s infinite linear;
}
.sp-hydrogen:before, .sp-hydrogen:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #088dcd;
  border-radius: 50%;
}
.sp-hydrogen:before {
  top: calc( 50% - 5px );
  left: calc( 50% - 5px );
}
.sp-hydrogen:after {
  top: -1px;
  left: -1px;
}
@-webkit-keyframes spHydro {
  from {
    -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
  }
}
@keyframes spHydro {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
/*--- group detail ---*/
.group-feed {
    display: inline-block;
    width: 100%;
	position: relative;
}
.group-avatar {
    display: inline-block;
    position: relative;
    width: 100%;
}
.group-avatar > img {
    border-radius: 10px;
    display: inline-block;
    width: 100%;
}
.group-dp {
    bottom: 25px;
    display: inline-block;
    left: 25px;
    margin: 0;
    position: absolute;
}
.group-dp > img {
    border: 3px solid #fff;
    border-radius: 100%;
    max-width: 135px;
}
.edit-dp {
    background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
    border: 1px solid #b3b3b3;
    border-radius: 100%;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 10px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 40px;
}
.edit-dp i{
	font-size: 22px;
	vertical-align: sub;
}
.group-avatar > a {
    background: #fff none repeat scroll 0 0;
    border-radius: 20px;
    bottom: 20px;
    font-size: 13px;
    padding: 6px 18px;
    position: absolute;
    right: 20px;
}
.group-avatar > a > i {
    color: green;
    margin-right: 5px;
}
.grp-info {
    display: inline-block;
    padding: 16px 5px;
    position: relative;
    width: 100%;
}
.grp-info > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.grp-info > ul > li {
    color: #92929e;
    display: inline-block;
    font-size: 13px;
    margin-right: 20px;
	vertical-align: middle;
}
.grp-info > ul > li > span {
    color: #1f273f;
    font-weight: 500;
}
.grp-info > h4 {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    vertical-align: middle;
    width: 100%;
}
.grp-info > h4 > span {
    display: block;
    font-size: 11px;
}
.grp-info ul.more-grp-info {
    float: right;
    width: auto;
	margin-top: -15px;
	position: relative;
	z-index: 10;
}
.grp-info ul.more-grp-info > li {
    margin-right: 0;
    margin-left: 20px;
}
.edit-pp {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    left: 10px;
    line-height: 38px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: all 0.2s linear 0s;
    width: 40px;
}
.edit-pp label i{
	font-size: 20px;
	vertical-align: middle;
}
.group-avatar:hover .edit-pp {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.more-grp-info form {
    display: inline-block;
    position: relative;
    width: 100%;
}
.more-grp-info form input {
    background: #fff none repeat scroll 0 0;
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
    margin-bottom: 0;
	border-radius: 0;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 5px;
}
.more-grp-info form i {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.grp-about{
	display: inline-block;
	width: 100%;
}
.grp-about h4 {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
}
.badges {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.badges > li {
    display: inline-block;
    margin-right: 2px;
}
.badges > li img {
    max-width: 33px;
}

.tab-title {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.tab-title > span {
    border: 1px solid;
    border-radius: 5px;
    color: #92929e;
    font-size: 11px;
    padding: 1px 6px;
    vertical-align: middle;
}

/*--- about page ---*/
.grp-info.about > h4 {
    display: inline-block;
    width: auto;
}
.grp-info > ul.joined-info {
    float: right;
    margin-top: 10px;
}
.nav.nav-tabs.about-btn,
.timeline-btn{
    border-bottom: 0 none;
    display: inline-block;
    width: 72%;
}
.nav.nav-tabs.about-btn > li a,
.timeline-btn > li a{
    background: #f8f8f8 none repeat scroll 0 0;
    border-radius: 5px;
    color: #088dcd;
    font-size: 14px;
    padding: 7px 10px;
	display: inline-block;
}
.nav.nav-tabs.about-btn > li,
.timeline-btn > li{
    margin-right: 5px;
	display: inline-block;
}
.grp-info > ul.joined-info > li:last-child{
    margin-right: 0;
}
.nav.nav-tabs.about-btn > li a:hover,
.timeline-btn > li a:hover{
    background: #ebebeb none repeat scroll 0 0;
}
.nav.nav-tabs.about-btn > li a.active,
.timeline-btn > li a.active{
    background: #088dcd none repeat scroll 0 0;
    color: #fff;
}
.uzr-pictures {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}
.uzr-pictures > a {
    display: inline-block;
    width: 100%;
	position: relative;
	overflow: hidden;
}
.uzr-pictures > a::before {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    border-radius: 6px;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.1s linear 0s;
    visibility: hidden;
    width: 100%;
    z-index: 0;
}
.uzr-pictures:hover a::before {
    opacity: 1;
    visibility: visible;
}
.uzr-pictures > a > img {
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
.pix-filter {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
}
.pix-filter > li {
    display: inline-block;
    margin: 0 12px 0 0;
}
.pix-filter > li > a {
    border-bottom: 2px solid #eaeaea;
    display: inline-block;
    padding: 0 10px;
    text-transform: capitalize;
}
.hover-action {
    left: 50%;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 30px;
}
.hover-action > li {
    color: #fff;
    display: inline-block;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    visibility: hidden;
    width: 100%;
	font-size: 14px;
	margin-bottom: 2px;
}
.hover-action > li a {
    display: inline-block;
    padding: 4px 0;
    width: 100%;
}
.hover-action > li a > i {
    color: #088dcd;
}
.uzr-pictures:hover .hover-action > li {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
}
.uzr-pictures:hover .hover-action > li:last-child {
    transition-delay: 0.09s;
}
.user-video {
    background: #fafafa none repeat scroll 0 0;
    border: 1px solid #e1e8ed;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
	margin-bottom: 22px
}
.user-video > figure {
    display: inline-block;
    margin: 0;
    position: relative;
    width: 100%;
}
.user-video > figure > img {
    border-radius: 6px 6px 0 0;
	width: 100%;
}
.user-video > figure a.play-btn {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    border: 3px solid #088dcd;
    border-radius: 100%;
    color: #088dcd;
    font-size: 20px;
    height: 50px;
    left: 50%;
    line-height: 44px;
    padding-left: 4px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out 0s;
    width: 50px;
}
.user-video > figure a.play-btn:hover {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
            transform: translate(-50%, -50%) scale(1.1);
}
.user-video > span {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 10px 0;
    text-align: center;
    width: 100%;
}
.user-video > figure::before {
    background: rgba(21, 21, 31, 0.6) none repeat scroll 0 0;
    border-radius: 6px;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    visibility: hidden;
}
.user-video > figure:hover::before {
    opacity: 1;
    visibility: visible;
}

.vid-action {
    display: inline-block;
    list-style: outside none none;
    margin: 6px 0;
    padding: 0;
    text-align: center;
    width: 100%;
}
.vid-action > li {
    color: #92929e;
    display: inline-block;
    margin: 0 10px;
}

.user-video > figure::before {
    background: rgba(21,21,31,.6);
    border-radius: 6px;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s ease-in-out,visibility .3s ease-in-out;
    visibility: hidden;
}
.user-video > figure:hover::before {
    opacity: 1;
    visibility: visible;
}
.info-block-list {
    display: inline-block;
    width: 100%;
}
.info-block {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
}
.info-block:last-child{
	margin-bottom: 0;
}
.info-block > h6 {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 8px;
    width: 100%;
}
.info-block > p {
    margin-bottom: 0;
    width: 100%;
	font-size: 13.4px;
}

.info-block-list > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.info-block-list > ul > li {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 12px;
    width: 49%;
	font-size: 13px;
}
.info-block-list > ul > li span {
    font-weight: normal;
}
.info-block-list > ul > li:nth-last-child(1), .info-block-list > ul > li:nth-last-child(2) {
    margin-bottom: 0;
}
.sidebar .widget > .user-stat > li em {
    color: #7d7e9e;
    line-height: 16px;
    margin-bottom: 0;
}
.sidebar .widget > .user-stat > li i {
    border: 1px solid;
    border-radius: 100%;
    display: inline-block;
    font-size: 17px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    vertical-align: middle;
    width: 35px;
}
.sidebar .widget > .user-stat > li span {
    display: inline-block;
    font-weight: 500;
    padding-left: 10px;
    vertical-align: middle;
	font-size: 13px;
}

/*--- friends group carousel ---*/
.owl-carousel .frnd-meta > img {
    max-width: 70px;
    width: auto;
}
.owl-carousel .frnd-name > a {
    font-size: 13px;
}
.owl-carousel .frnd-name {
    margin-left: 75px;
    margin-top: -20px;
}
.owl-carousel .friend-box > figure > span {
    font-size: 12px;
}
/*--- group rules widget ---*/
.grop-rules {
    display: inline-block;
    width: 100%;
}
.grop-rules > ol {
    color: #5a6381;
    display: block;
    font-size: 12px;
    margin-bottom: 0;
    padding-left: 0;
}
.sidebar .widget .grop-rules > ol li {
    margin-bottom: 10px;
    display: flex;
}
.sidebar .widget .grop-rules > ol li:last-child {
    margin-bottom: 0;
}
.sidebar .widget .grop-rules > ol li i{
	margin-top: 4px;
	color: #1f273f;
	font-size: 20px;
}
/*--- recent media widget ---*/
.recent-media {
    display: inline-block;
    width: 100%;
}
.recent-media > figure {
    display: inline-block;
    position: relative;
    width: 100%;
}
.recent-media > figure:last-child {
    margin-bottom: 0;
    margin-top: 0;
}
.recent-media > figure img {
    display: inline-block;
    width: 100%;
	border-radius: 8px;
}
.recent-media > figure a {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    border: 3px solid;
    border-radius: 100%;
    color: red;
    font-size: 20px;
    height: 50px;
    left: 50%;
    line-height: 44px;
    opacity: 0;
    padding-left: 5px;
    position: absolute;
    text-align: center;
    top: 45%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 50px;
}
.recent-media figure span {
    bottom: 10px;
    color: #fff;
    left: 10px;
    position: absolute;
    text-shadow: 0 1px 2px #000;
}
.recent-media > figure:hover > a {
    opacity: 1;
    visibility: visible;
}
/*--- cart button fixed on page ---*/
.cart-product{
    background: red none repeat scroll 0 0;
    border: 3px solid;
    border-radius: 100%;
    bottom: 30px;
    color: #fff;
    height: 50px;
    left: 20px;
    line-height: 56px;
    position: fixed;
    text-align: center;
    width: 50px;
    transition: all 0.2s linear 0s;
    z-index: 98;
}
.cart-product a i {
    font-size: 28px;
}
.cart-product > span,
.chat-live > span{
    background: red none repeat scroll 0 0;
    border: 2px solid #fff;
    border-radius: 100%;
    font-size: 10px;
    height: 20px;
    line-height: 13px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -12px;
    width: 20px;
}
.cart-product > a {
    display: inline-block;
}
.chat-live{
    background: red none repeat scroll 0 0;
    border: 3px solid;
    border-radius: 100%;
    bottom: 30px;
    color: #fff;
    height: 50px;
    right: 30px;
    line-height: 48px;
    position: fixed;
    text-align: center;
    width: 50px;
	z-index: 99999;
	transition: all 0.2s linear 0s;
}
.chat-live:hover,
.cart-product:hover{
	box-shadow: -1px 3px 15px rgba(8,141,205,.8);
}
.chat-btn {
    display: inline-block;
    font-size: 28px;
    line-height: 44px;
}
/*--- chat box ---*/
.chat-box {
    background: #fff none repeat scroll 0 0;
    border-radius: 8px 8px 0 0;
    bottom: -500px;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.2);
    display: inline-block;
    overflow: hidden;
    padding: 15px;
    position: fixed;
    right: 110px;
    width: 330px;
	z-index: 999999;
	max-height: 460px;
	transition: all 0.3s linear 0s;
}
.chat-box.active {
	bottom: 0;
}
.chat-head {
    background: red none repeat scroll 0 0;
    border-radius: 5px;
    display: inline-block;
    padding: 15px 10px;
    width: 100%;
}
.chat-head > h4 {
    color: #fff;
    float: left;
    font-size: 14px;
    font-weight: 500;
    width: 80%;
}
.chat-head > span {
    color: #fff;
    cursor: pointer;
    float: right;
    font-size: 20px;
    margin-top: -6px;
}
.chat-head > form {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
}
.chat-head > form input {
    border-left-colors: none;
    border-right-colors: none;
    border-top-colors: none;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-color: #eaeaea;
    border-image: none;
    border-style: none none solid;
    border-width: medium medium 1px;
    color: #fff;
    width: 100%;
}
.chat-head > form input::-webkit-input-placeholder{color: #fff;}
.chat-head > form input:-ms-input-placeholder{color: #fff;}
.chat-head > form input::placeholder{color: #fff;}
.user-tabs {
    display: inline-block;
    padding: 10px 0;
    width: 100%;
}
ul.tab-content {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
	border-bottom: 0;
}
.user-tabs .nav-tabs {
   	border-bottom: 0 none;
    display: inline-block;
    margin-bottom: 20px;
}
.user-tabs .nav-tabs > li.nav-item{
	display: inline-block;
	margin-right: 10px;
	position: relative;
}
.user-tabs .nav-tabs > li > em {
    border: 1px solid #f0506e;
    border-radius: 40px;
    font-size: 10px;
    font-style: normal;
    line-height: 12px;
    padding: 0 3px;
    position: absolute;
    right: -6px;
    text-align: center;
    top: -3px;
	color: #f0506e;
}
.user-tabs .nav-tabs > li > a {
    font-size: 13px;
    font-weight: 500;
    padding: 6px 4px 2px;
    text-transform: capitalize;
	display: inline-block;
	border-bottom: 2px solid transparent;
	color: #999;
}
.user-tabs .nav-tabs > li > a.active{
	color: #3e3f5e;
}
.user-tabs .nav-tabs::before{
	display: none;
}
.friend {
    display: inline-block;
    width: 100%;
	position: relative;
	min-height: 300px;
	max-height: 300px;
}
.friend > a {
    display: inline-block;
    width: 100%;
	margin-bottom: 12px;
}
.friend > a figure .status::before{
	left: auto;
	top: auto;
	right: 2px;
	bottom: 0;
}
.friend > a:hover{
	background: #eeeeee none repeat scroll 0 0;
    border-radius: 30px;
}
.friend > a figure {
    display: inline-block;
	margin-bottom: 0;
	position: relative;
}
.friend > li a figure {
}
.friend > a figure img {
    border: 1px solid #bababa;
    border-radius: 100%;
}
.friend > a > span {
    font-size: 13px;
    font-weight: 500;
    padding-left: 5px;
}
.friend > a figure.group-chat img.two {
    max-width: 26px;
}
.friend > a figure.group-chat img.two {
    max-width: 20px;
    position: absolute;
    right: -12px;
    top: 5px;
}
.friend > a figure.group-chat {
    margin-right: 20px;
}
.friend > a figure.group-chat img.three {
    bottom: 10px;
    max-width: 24px;
    position: absolute;
    right: -10px;
}
.friend > a figure.group-chat img {
    max-width: 38px;
}
.friend > a figure.group-chat .status::before {
    bottom: 4px;
    right: -4px;
}
.friend > a > i {
    float: right;
    margin-right: 10px;
    margin-top: 13px;
	color: #a2a2ae;
}
.friend > a > i img {
    border-radius: 100%;
    max-width: 15px;
}
/*--- side chat message box ---*/
.chat-card {
    background: #fbfbfb none repeat scroll 0 0;
    border: 1px solid #e1e8ed;
    border-radius: 3px;
    height: 100%;
    position: absolute;
    right: -340px;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 9999999;
	transition: all 0.3s linear 0s;
}
.chat-card.show {
	right: 0;
}
.chat-card-head {
    color: #fff;
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    width: 100%;
}
.chat-list > ul > li {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.chat-card-head > h6 {
    display: inline-block;
    font-weight: 500;
    margin: 0;
    vertical-align: middle;
	color: #fff;
	font-size: 15px;
}
.chat-card-head > img {
    border: 2px solid;
    border-radius: 100%;
    max-width: 35px;
    vertical-align: middle;
}
.chat-card-head .frnd-opt {
    float: right;
}
.chat-card-head .more-post-optns > i svg {
    stroke: #fff;
}
.frnd-opt > span::before {
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 30px;
    left: 50%;
    position: absolute;
    top: 52%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    transition: all 0.2s linear 0s;
    width: 30px;
}
.frnd-opt > span:hover::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

.frnd-opt > span {
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    position: relative;
    vertical-align: middle;
}
.frnd-opt .more {
    display: inline-block;
    float: unset;
    vertical-align: middle;
}
.frnd-opt .more-post-optns > ul li {
    color: #333;
}
.chat-card-head span.status {
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.more-optns:hover > ul {
    opacity: 1;
    visibility: visible;
}
.more-optns > ul {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 3px;
    box-shadow: 0 10px 23px #8b8b8b;
    color: #333;
    line-height: normal;
    line-height: initial;
    list-style: outside none none;
    margin-bottom: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 22;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear 0s;
}
.more-optns > ul li {
    display: inline-block;
    font-size: 11px;
    line-height: 24px;
    width: 100%;
}
.chat-list {
    display: inline-block;
    padding: 15px;
    position: relative;
    width: 100%;
}
.chat-list > ul {
    margin: 0;
    padding-left: 0;
    max-height: 325px;
    overflow: hidden;
    position: relative;
    padding-right: 8px;
}
.chat-thumb {
    display: inline-block;
    vertical-align: top;
    width: 28px;
}
.notification-event {
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
    width: 84%;
}
.chat-thumb > img {
    border-radius: 100%;
}
.chat-message-item {
    background: #deebf3;
    border-radius: 23px 0 23px 23px;
    display: inline-block;
    font-size: 13px;
    padding: 12px;
    color: #333;
}
li.me .notification-event .chat-message-item{
	border-radius: 0 23px 23px 23px;
}
.notification-date {
    color: #999;
    display: inline-block;
    font-size: 11px;
    width: 100%;
}
.notification-date > i img {
    max-width: 15px;
    vertical-align: middle;
}
.chat-list > ul li.you .chat-thumb {
    float: right;
}
.chat-list > ul li.you .notification-event {
    float: right;
    margin-left: 0;
    margin-right: 8px;
}
.chat-list > ul li.you .chat-message-item {
    background: rgba(202, 239, 142, 0.5) none repeat scroll 0 0;
}
.text-box {
    position: relative;
}
.text-box > textarea {
    background: #f0f0f0 none repeat scroll 0 0;
    border: 0 none;
    border-radius: 30px;
    font-size: 13px;
    height: 42px;
    padding: 12px 40px;
    vertical-align: middle;
    width: 100%;
	overflow: hidden;
}
.text-box > button {
    border: 0 none;
    border-radius: 100%;
    color: #fff;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 25px;
}
.text-box .add-smiles {
    display: inline-block;
    left: 10px;
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.text-box {
    display: inline-block;
    position: relative;
    width: 100%;
}
.add-smiles > span img {
    max-width: 20px;
}
.text-box .smiles-bunch {
    top: -65px;
}
.text-box .smiles-bunch::before {
    left: 5px;
}
.text-box .smiles-bunch > i{margin: 2px;}
.text-box .smiles-bunch > i img{
	max-width: 22px;
}
.chat-message-item > figure {
    margin-bottom: 5px;
}
.chat-message-item > figure img {
    border-radius: 10px;
}
.chat-message-item > .caption {
    font-size: 12px;
}
.chat-message-item > .caption i {
    color: red;
    font-size: 16px;
    margin-left: 10px;
	cursor: pointer;
}
.frnd-opt .more-post-optns::before{
	background: rgba(0,0,0,.1);
}
/*--- chat sidebar ---*/
.profile-short {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    display: inline-block;
    padding: 15px;
    position: relative;
    width: 100%;
}
.chating-head {
    border: 1px solid #eaeaea;
    border-radius: 6px 6px 0 0;
    float: left;
    padding: 10px;
    width: 100%;
}
.s-left {
    float: left;
    width: 50%;
}
.s-right {
    float: right;
    margin-top: 10px;
    text-align: right;
    width: 50%;
}
.s-left > h5 {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
}
.s-left > p {
    font-size: 11px;
    margin-bottom: 0;
}
.s-right > span {
    cursor: pointer;
    margin-left: 15px;
}
.short-intro {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.short-intro figure img {
    display: inline-block;
    width: 100%;
}
.short-intro > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 43px;
    margin-top: 10px;
    padding-left: 0;
    text-align: left;
    width: 100%;
}
.short-intro > ul li {
    display: inline-block;
    width: 100%;
	margin-bottom: 11px;
}
.short-intro > ul > li > p {
    float: right;
    font-size: 12px;
    margin-bottom: 0;
}
.short-intro > ul > li > span {
    font-size: 13px;
    font-weight: 500;
}
/*--- credit balance widget ---*/
.card-credit {
    display: inline-block;
    width: 100%;
}
.card-credit > h6 {
    color: #088dcd;
    display: inline-block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}
.card-credit > h6 > i {
    display: inline-block;
    vertical-align: text-bottom;
}
.card-credit > span {
    color: #92929e;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}
.happy-spend {
    display: inline-block;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}
.happy-spend > p img {
    max-width: 20px;
}
.happy-spend > p {
    margin-bottom: 0;
}
/*--- card widgett ---*/
.set-card {
    display: inline-block;
    width: 100%;
}
.set-card > p {
    margin-top: 15px;
}
.added-complete {
    float: right;
    margin-top: 10px;
}
.added-complete > h6 {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
}
.added-complete > h6 > span {
    color: #92929e;
    font-weight: normal;
}
.uk-margin{
	margin-top: 0 !important;
}
.chosen-container-single .chosen-single{
	padding-left: 15px;
}
.uk-margin .chosen-container-single .chosen-single{
	height: 47px;
	line-height: 47px;
	box-shadow: none;
	background: #fff;
	border: 1px solid #e5e5e5;
	color: #62626e;
}
.uk-margin .chosen-container-single .chosen-single div b {
    
}
.add-credits > p {
    margin-bottom: 0;
    margin-top: 20px;
}
.add-credits > p b {
    color: #3e3f5e;
    font-weight: 500;
}
.add-credits {
    display: inline-block;
    width: 100%;
}
.main-wraper > h6 {
    font-weight: 400;
	font-size: 14px;
}
/*--- payout & earning page ---*/
table.table-default {
	margin-bottom: 0;
	border-radius: 5px;
	background: #fff;
}
table.table-default thead th {
	background: #088dcd none repeat scroll 0 0;
	border-top: 0 none;
	font-size: 13px;
	padding: 12px 15px;
	color: #fff;
	text-transform: capitalize !important;
}
table.table.table-default tbody td {
    font-size: 12px;
    padding: 10px 15px;
}
table.table.table-default tbody td a:hover{
	text-decoration: underline;
}
.table.table-default.table-responsive-md.table-striped thead th{
	color: #fff;
}
.total-balnce {
    background: #4a5373 none repeat scroll 0 0 !important;
    color: #fff;
    font-weight: 500;
}
.widget table.table-default thead th{
	color: #fff;
}
.list-crumb {
    display: inline-block;
    list-style: outside none none;
    padding: 0;
    position: relative;
    width: 100%;
}
.list-crumb > li {
    color: #088dcd;
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
    padding-right: 15px;
    position: relative;
}
.list-crumb > li::after {
    content: "/";
    position: absolute;
    right: 0;
    top: 0;
}
.list-crumb > li:last-child::after {
    display: none;
}
.list-crumb > li:last-child a {
    color: #000;
}
.manage-payout {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 0;
}
.manage-payout > a {
    display: inline-block;
    color: #ff7373;
	font-weight: 400;
}
/*--- payout statement page end ---*/
/*--- dark mod night view ---*/
body.nightview {
    background: #151a27 none repeat scroll 0 0;
	color: #fff;
}
.nightview p{
	color: #a5adc2; 
}
.nightview h1, 
.nightview h2, 
.nightview h3, 
.nightview h4, 
.nightview h5, 
.nightview h6{
	color: #fff;
}
.nightview .topbar {
    background: #1d2333 none repeat scroll 0 0;
	border-bottom-color: #2e3648;
}
.nightview .white-bg {
    background: #0d1323 none repeat scroll 0 0;
}
.nightview .logo > span {
    color: #fff;
}
.nightview .menu-caro {
    border-bottom-color: #1e2638;
}
.nightview .topbar .name > h4 {
    color: #fff;
}
.nightview .topbar .name > span {
    color: #919ab2;
}
.nightview .topbar ul.web-elements > li > a,
.nightview .web-elements .user-dp {
    background: #293249 none repeat scroll 0 0;
    border-color: #495269;
}
.nightview .topbar ul.web-elements > li > a i {
    color: #fff;
}
.nightview .dropdown {
    background: #21283b none repeat scroll 0 0;
}
.nightview .dropdown > li > a {
    background: #31384b none repeat scroll 0 0;
    border-color: #40475a;
    color: #d1d8eb;
}
.nightview .dropdown > li > a.logout {
    background: #ff7373 none repeat scroll 0 0;
    border-color: #ff2727;
    color: #fff;
}
.nightview .user-avatar > img {
    border-color: #495269;
}
.nightview .post-detail-btn li.nav-item a.active::before{
	border-bottom-color: #151a27;
}
.nightview .post-detail-btn li.nav-item a.active::after{
	border-left-color: #151a27;
}
.nightview .post-detail-btn li.nav-item a.active{
	background: #151a27;
}
.nightview .main-wraper, 
.nightview aside .widget,
.nightview .profile-short{
	background: #1d2333;
	border: 1px solid rgba(255,255,255,.04);
}
.nightview .main-title{
	border-bottom: 1px solid rgba(255,255,255,.08);
}	
.nightview .widget-analytics > li > span {
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
    border-color: rgba(255, 255, 255, 0.09);
}
.nightview .bottombar{
	background: #161b28;
}
.nightview .members {
    background: #293249 none repeat scroll 0 0;
    border-color: #2e3648;
}
.nightview .upload-media > li:hover,
.nightview .stat-tools > a, 
.nightview .stat-tools > .box a,
.nightview .share-options > ul > li a{
    background: #293249;
	color: #fff;
}
.nightview .we-video-info{
	border-top-color: #2e3648;
}
.nightview .Emojis{
	background: #21283b;
}
.nightview .emoji-state > img{
	border-color: #1e2638;
}
.nightview .we-video-info > ul li span i {
    color: #616a82;
}
.nightview .filtr-tabs > li > a{
    background: #0d1323
}
.nightview nav.sidebar{
    background: #0d1323;
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.nightview .menu-slide > li.active > a{
    background: #31384b;
}
.nightview .sidemenu > i::before, 
.nightview .res-search > span::before{
   background: #1d2333; 
}
.nightview .responsive-header{
    background: #0d1323;
    border-bottom-color: #21282d;
}
.nightview .menu-slide > li > a:hover{
    background: #21283b;
}
.nightview .more-post-optns::before {
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
}
.nightview .more-post-optns > ul {
    background: #21283b none repeat scroll 0 0;
}
.nightview .more-post-optns > ul > li i {
    color: #fff;
}
.nightview .friend-name > ins {
    color: #b1bad2;
}
.nightview .friend-name > span {
    color: #b1bad2;
}
.nightview .re-links-meta > span {
    color: #b1bad2;
}
.nightview .we-video-info > ul li span ins {
    color: #b1bad2;
}
.nightview .widget > span {
    color: #b1bad2;
}
.nightview .friend-meta span {
    color: #b1bad2;
}
.nightview .widget.web-links.stick-widget.is_stuck > p {
    color: #b1bad2;
}
.nightview .link-item > a p{
	color: #088dcd;
}
.nightview .friend-box{
	background: #141926;
	border-color: transparent;
}
.nightview .widget .ak-groups > li{
	border-color: #2f3749;
}
.nightview .uni-info > ul > li,
.nightview .dept-info > ul > li{
	border-bottom-color: #222a3c;
}
.nightview .popup,
.nightview .popup-innner,
.nightview .share-options{
    background: #1d2333;
}
.nightview .popup-head {
    border-bottom-color: #2e3648;
}
.nightview .inquiry-about > input[type="text"]{
	background: transparent;
	border-bottom-color: #2e3648;
	color: #fff;
}
.nightview .auto-popup .popup-meta > span,
.nightview .inquiry-about > label{
	color: #616a82;
}
.nightview .owl-prev::before, 
.nightview .owl-next::before{
	background: #21283b;
}
.nightview .chat-box {
    background: #21283b none repeat scroll 0 0;
}
.nightview .friend > a:hover {
    background: #293249 none repeat scroll 0 0;
}
.nightview .user-tabs .uk-tab > li.uk-active > a {
    color: #fff;
}
.nightview .user-tabs .uk-tab > li > a {
    color: #979fb5;
}
.nightview .chat-card{
	background: #151a27;
	border-color: #2e3648;
}
.nightview .chat-list > ul li .chat-message-item{
	background: #31384b;
	color: #fff;
}
.nightview .chat-list > ul li.you .chat-message-item{
	background: #31384b;
	color: #fff;
}
.nightview .frnd-opt .more-post-optns > ul li{color: #fff;}
.nightview .progress__inner {
    background: transparent none repeat scroll 0 0;
    color: #088dcd;
}
.nightview .your-grp > a{
	color: #959db2;
}
.nightview .blog-details-meta > ul li{
	color: #fff;
}
.nightview .uk-pagination > * > *,
.nightview .uk-pagination > .uk-disabled > *{
	background: #293249;
	color: #b7bfd5;
}
.nightview .blog-post-meta > ul > li{
	color: #b1bad2;
}
.nightview .comment-box{
    background: transparent;
    border-color: rgba(255, 255, 255, 0.08);
}
.nightview .create-by > div span{
    color: #fff;
}
.nightview .course {
    background: #262d40 none repeat scroll 0 0;
}
.nightview .prise > span {
    color: #fff;
}
.nightview .uk-pagination .uk-active > span{
    background: #088dcd;
    color: #fff;
}
.nightview .categ-card{
    background: #1d2333;
}
.nightview .grp-info > ul > li > span,
.nightview .sidebar .widget .grop-rules > ol li i {
    color: #fff;
}
.nightview .group-avatar > a {
    color: #333;
}
.nightview .card{
    background: #1d2333;
}
.nightview .card-header{
    background: #293249;
}
.nightview .card-header button.btn::after,
.nightview .question-collaps .card-header button.btn{
    color: #fff;
}
.nightview .help-bg{
    background: transparent;
}
.nightview .chating-head{
    border-color: rgba(255, 255, 255, 0.04);
}
.nightview .chat-header{
    background: #293249;
}
.nightview .chat-content .date{
    background: #091229;
    border-color: transparent;
}
.nightview ul.chatting-area > li.me p {
    color: #fff;
}
.nightview ul.chatting-area > li.you p {
    background: rgba(39, 170, 225, 0.5) none repeat scroll 0 0;
    color: #fff;
}
.nightview .attach-options{
    background: #161b28;
}
.nightview .attach-options > a,
.nightview .notification-box > ul > li{
    border-bottom:  1px solid rgba(255, 255, 255, 0.08);
}
.nightview table.table.table-default tbody td{
    background: #293249;
    color: #fff;
    border-bottom:  1px solid rgba(255, 255, 255, 0.08);
}
.nightview .list-crumb > li:last-child a{color: #fff;}
.nightview .quick-all{
    border-color: transparent; 
}
.nightview .quick-all .spark-meta > h4{
    color: red;
}
.nightview .table.table-striped th {
    color: #fff;
}
.nightview .table td, 
.nightview .table th {
    border-color: rgba(255, 255, 255, 0.08);
}
.nightview .price-plan-wraper > h5{
    background: #293249;
    color: #b1bad2;
}
.nightview .price-plan-wraper > h5 b{color: #fff;}
.nightview .post-subject > p{
    color: #fff;
}
.nightview .responsive-tab .uk-list,
.nightview .cart-box > ul{
    background: #1d2333 none repeat scroll 0 0;
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.nightview .cart-table .table td, 
.nightview .cart-table .table th{
    color: #fff;
}
.nightview .cart-box > ul li:last-child{
    border-color: rgba(255, 255, 255, 0.04);
}
.nightview footer{
    background: #1d2333 none repeat scroll 0 0;
    border: 1px solid rgba(255, 255, 255, 0.1); 
}
.nightview footer .bg-image {
    opacity: 0.05;
}
.nightview .light-bg{
    background: #21283b none repeat scroll 0 0;
    border-color: #2f3749;
}
.nightview .uk-tab::before{
    border-bottom: transparent;
}
.nightview .courier-box{
    background: #21283b;
    border-color: #2f3749;
}
.nightview .cart-summary > ul li{
    border-bottom-color: #2f3749;
}
.nightview .friendz{
    background: #21283b;
    border-color: #2f3749;
}
.nightview .nav-item > span{
    color: #000;
}
.nightview .form-group1 label {
    color: #fff;
}
.nightview .image-upload-wrap {
    background: #293249;
    border-color: #3e4658;
}
.nightview .switch::before{
    background: #293249 !important;
}
.nightview .switch{
    background: #161b28;
}
.nightview .bg-white {
    background-color: #21283b !important;
}
.nightview .uk-alert-danger{
    background-color: #21283b;
}
.nightview .video-links > li > a:hover{
    background: #293249;
}
.nightview .jalendar .jalendar-container .jalendar-pages > .header h2{
    color: #fff;
}
.nightview .jalendar .jalendar-container .jalendar-pages .days .day.today span{
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
}
.nightview .uk-card-default{background: transparent;}
.nightview .is-helpful{
    border-bottom-color: rgba(255, 255, 255, 0.04);
    border-top-color: rgba(255, 255, 255, 0.04);
}
.nightview form input::-webkit-input-placeholder, .nightview form textarea::-webkit-input-placeholder{
    color: #9aa4ba;
}
.nightview form input:-ms-input-placeholder, .nightview form textarea:-ms-input-placeholder{
    color: #9aa4ba;
}
.nightview form input::placeholder,
.nightview form textarea::placeholder{
    color: #9aa4ba;
}
.nightview .pagination > li > a.active{
    background: #088dcd;
    border-color: transparent;
}
.nightview .anser .friend-info{
    border-color: rgba(255, 255, 255, 0.04);
}
.nightview .side-slide{
    background: #21283b;
    border-color: rgba(255, 255, 255, 0.04);
}
.nightview .new-messages, 
.nightview .notificationz{
    border-top-color: rgba(255, 255, 255, 0.04);
}
.nightview .nav.nav-tabs.slide-btns li a{
    background: #161b28;
}
.nightview ul.drop-menu li{
	background: #161b28;
}
.nightview .recent-search {
    background: #21283b none repeat scroll 0 0;
}
.nightview .recent-search .so-history > li {
    background: rgba(0,0,0,.1);
}
.nightview .recent-search .so-history > li:hover{
    background: rgba(0,0,0,.3);
}
.nightview .cancel-search{
    background: #151a27;
}
.nightview .chat-rooms li{
    background: #151a27;
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.nightview .story-wraper{
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.nightview .commenter{
    background: #151a27;
}
.nightview .chat-rooms li > a.join{
    background: #088dcd;
    color: #fff;
}
.nightview .plyr--audio .plyr__controls{
	background: #151a27;
	background: var(--plyr-audio-controls-background,#151a27);
}
.nightview .popup-wraper, .post-new-popup, .wraper-invite, .new-question-popup, .createroom-popup{
	background: rgba(0, 0, 0, 0.85) none repeat scroll 0 0;
}
.nightview .dropzone .dz-message .dz-button,
.nightview .post-categoroes > li,
.nightview .dropzone{
	background: #21283b;
	color: #9aa4ba;
}

.nightview .drop-menu > li > a{color: #fff;}
.nightview .statistic > li,
.nightview .incldes > ul > li > span,
.nightview .time,
.nightview .lecturez,
.nightview .course-cat > span,
.nightview .grop-rules > ol,
.nightview .grp-info > ul > li,
.nightview .help-qst > li,
.nightview #components-nav > li ol li,
.nightview .exp-meta > ins,
.nightview .twiter-feed > li > div p,
.nightview .jalendar .jalendar-container .jalendar-pages .days .day
{
    color: #a5adc2;
}
.nightview .category > li a,
.nightview .course-details > a,
.nightview .sidebar .invitepage > li button,
.nightview .suggestd > li button.sug-like,
.nightview .group-box > button,
.nightview .pagination > li > a,
.nightview .help-topic > span,
.nightview .more-attachments,
.nightview .message-text-container textarea,
.nightview .tags > li a,
.nightview .tags > li a .responsive-tab .uk-list > li > a,
.nightview .responsive-tab .uk-list > li > a,
.nightview .progress-bar-labels > li,
.nightview .dis-n-exp > span,
.nightview .button.soft-primary,
.nightview .emojionearea, .emojionearea.form-control,
.nightview .select-box__input-text
{
    background: #293249;
    color: #fff;
    border-color: #3e4658;
    box-shadow: 0px 0px 20px #13192b;
}
.nightview .new-comment form input,
.nightview .new-post > form input,
.nightview .searches input,
.nightview .post-new .c-form textarea, 
.nightview .post-new .c-form input,
.nightview .post-new .c-form .chosen-container .chosen-single,
.nightview .share-options > form > textarea,
.nightview .text-box > textarea,
.nightview .uk-input, 
.nightview .uk-select, 
.nightview .uk-textarea,
.nightview .uk-margin .chosen-container-single .chosen-single,
.nightview .add-comment .c-form input, 
.nightview .add-comment .c-form textarea,
.nightview .more-grp-info form input,
.nightview .c-form > div input, 
.nightview .c-form > div textarea, 
.nightview .c-form > input, 
.nightview .c-form > textarea,
.nightview .newsletter > input,
.nightview .search-question > form input,
.nightview .social-links > input,
.nightview .video-search > input,
.nightview .search-section form input
{
	background: #21283b;
	color: #9aa4ba;
	border-color: #2f3749;
	box-shadow: none;
}
.menu-slide .menu-item-has-children > a::before {
	color: #7f8994;
	content: "\ea77";
	font-family: icofont;
	position: absolute;
	right: 13px;
	top: 12px;
}
.submenu {
	list-style: outside none none;
	padding-left: 34px;
	display: none;
	padding-top: 10px;
}
.menu-item-has-children ul.submenu > li a {
	color: inherit;
	display: inline-block;
	padding: 5px 14px;
	width: 100%;
	transition: all 0.1s linear 0s;
	font-size: 13px;
	position: relative;
}
.menu-item-has-children ul.submenu > li a:hover {
	padding-left: 16px;
}
.menu-item-has-children ul.submenu > li a::before {
	content: "";
	height: 5px;
	left: 0;
	position: absolute;
	top: 13px;
	width: 5px;
}
.menu-slide > li > a i svg {
	height: 16px;
	width: 16px;
}
svg:hover {
    color: #045b84;
}
nav.sidebar {
	background: #f5f5f5 none repeat scroll 0 0;
	border-right: 1px solid #e1e8ed;
	height: 100vh;
	left: -90%;
	max-width: 250px;
	padding-top: 153px;
	position: fixed;
	top: 0;
	width: 270px;
	z-index: 99;
	transition: all 0.6s linear 0s;
	padding-bottom: 20px;
}
nav.sidebar.hide {
	left: 0;
}
nav.sidebar.padding {
    padding-top: 80px;
}
.menu-slide {
	display: inline-block;
	list-style: outside none none;
	padding-left: 0;
	position: relative;
	width: 100%;
	height: 100%;
}
.menu-slide > li {
	display: inline-block;
	padding: 0 15px;
	position: relative;
	width: 100%;
}
.menu-slide > li > a {
	display: inline-block;
	font-size: 13px;
	font-weight: 500;
	padding: 10px 15px;
	position: relative;
	vertical-align: middle;
	width: 100%;
}
.menu-slide > li > a > i {
	font-size: 14px;
	vertical-align: text-bottom;
	margin-right: 10px;
}
.menu-slide > li.active > a {
	background: #ffffff none repeat scroll 0 0;
	border-radius: 9px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.menu-slide > li > a:hover {
	background: #cfd9e4 none repeat scroll 0 0;
	border-radius: 8px;
}
/*--- comments ---*/
.comments-area {
    display: inline-block;
    width: 100%;
}
.comments-area > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    width: 100%;
	padding-left: 0;
}
.comments-area > ul li {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}
.comments-area > ul li figure {
    display: inline-block;
    vertical-align: top;
}
.comments-area > ul li figure img {
    border-radius: 100%;
    max-width: 25px;
}
.commenter {
    background: #fff none repeat scroll 0 0;
    border-radius: 6px;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 4px;
    max-width: 93%;
    padding: 8px 10px;
    vertical-align: top;
}
.commenter > h5 {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 10px;
}
.commenter > span {
    color: #92929e;
    font-size: 11px;
}
.comments-area > ul > li > a {
    color: red;
    float: right;
    font-size: 12px;
    margin: 0 10px;
}
.comments-area > ul > li > a:hover{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.commenter > p {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 5px;
    width: 100%;
}
.commenter > a {
    display: inline-block;
    font-size: 12px;
    width: 100%;
	color: #ff7373;
}
/* Popover styling */
.popover_title {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);
  text-align: center;
  padding: 15px 0;
}
.popover_wrapper {
  position: relative;
  margin-top: 0;
  display: inline-block;
}
.popover_content {
    background-color: #fff;
    border-radius: 8px;
    bottom: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    left: -20px;
    opacity: 0;
    padding: 10px;
    position: absolute;
    -webkit-transform: translate(0px, 10px);
            transform: translate(0px, 10px);
    visibility: hidden;
    width: 100px;
}
/*.popover_content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}*/
.popover_wrapper:hover .popover_content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, -10px);
          transform: translate(0, -10px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover_message {
  text-align: center;
}
.popover_content > span {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 6px;
    width: 100%;
}
.popover_content > span > img {
    max-width: 20px;
}
/*--- story box ---*/
.story-title {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}
.story-title > h5 {
    display: inline-block;
    font-size: 16px;
}
.story-title > a {
    color: #088dcd;
    float: right;
    font-size: 12px;
    margin-top: 3px;
    text-decoration: underline;
}
.story-card {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.story-wraper {
    display: inline-block;
    margin-right: 5px;
    max-width: 111px;
    overflow: hidden;
    position: relative;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid #eaeaea;
}
.story-card > .story-wraper:last-child {
    margin-right: 0;
}
.users-dp {
    left: 5px;
    position: absolute;
    top: 5px;
}
.users-dp Img {
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    max-width: 35px;
}
.story-wraper > img {
    border-radius: 8px;
    transition: all 0.2s linear 0s;
}
.story-wraper > span {
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    bottom: 0;
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 5px;
    position: absolute;
    text-align: center;
    width: 100%;
	border-radius: 0 0 8px 8px;
}
.story-wraper:hover > img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
a.add-new-stry {
    background: #088dcd none repeat scroll 0 0;
    border: 1px solid #fff;
    border-radius: 100%;
    bottom: 23px;
    color: #fff;
    display: inline-block;
    height: 30px;
    left: 50%;
    line-height: 30px;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    width: 30px;
    z-index: 2;
}
/*--- chat room sec ---*/
ul.chat-rooms {
    float: unset;
    width: 100%;
}
.chat-rooms {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.chat-rooms li > img {
    border-radius: 100%;
    display: inline-block;
    width: 100%;
}
.chat-rooms li {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    width: 100%;
}
.chatroom-title {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.chatroom-title > i {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    vertical-align: middle;
}
.chatroom-title > span {
    display: inline-block;
    font-weight: 500;
    vertical-align: sub;
}
.create-newroom {
    color: red;
    float: right;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
}
.chatroom-title > span em {
    color: #92929e;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
}
.room-avatar {
    display: inline-block;
    position: relative;
    width: 88px;
}
.chat-rooms .room-avatar > img {
    border: 1px solid #d6d6d6;
    border-radius: 100%;
    display: inline-block;
    padding: 2px;
    width: auto;
}
.chat-rooms li > a.join {
    background: #deebf3 none repeat scroll 0 0;
    border-radius: 5px;
    font-size: 12px;
    padding: 4px 14px;
	transition: all 0.2s ease 0s;
}
.chat-rooms li > a.join:hover{color: #fff;}
.chat-rooms li > a.say-hi {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #088dcd;
    border-radius: 100%;
    color: #088dcd;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
	transition: all 0.2s ease 0s;
}
.chat-rooms li > a.say-hi:hover{
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
.chat-rooms li > span {
    display: inline-block;
    font-size: 14px;
    margin: 10px 0;
    width: 100%;
}
.chat-rooms li .room-avatar .status::before {
    border: 2px solid #fff;
    bottom: 0;
    height: 14px;
    top: auto;
    width: 14px;
}
/*--- video section ---*/
.user-post.video .friend-name{
	width: 94%;
}
.user-post.video .post-meta iframe{
	margin-bottom: 0;
	border: 0;
}
.wraper-title {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.wraper-title > span {
    display: inline-block;
    font-weight: 500;
}
.wraper-title > span > i {
    color: red;
    font-size: 16px;
    margin-right: 3px;
}
.wraper-title > a {
    color: red;
    float: right;
    font-size: 12px;
    font-weight: 500;
    margin-top: 3px;
}
.videos-caro.owl-carousel {
    display: inline-block;
    float: none;
    width: 100%;
}
.item-video {
    display: block;
}
.posted-user {
    display: inline-block;
    margin-top: 10px;
    vertical-align: middle;
}
.item-video .posted-user > img {
    border: 1px solid #cacaca;
    border-radius: 100%;
    display: inline-block;
    max-width: 32px;
    vertical-align: middle;
    width: auto;
}
.posted-user > span {
    font-size: 13px;
    font-weight: 400;
}
.vid-info {
    color: #92929e;
    float: right;
    font-size: 11px;
    margin-top: 18px;
}
.vid-info > span {
    margin: 0 5px;
}
.owl-video-tn.owl-lazy {
    height: 300px;
}
.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
  
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease;
    transition: transform .1s ease, -webkit-transform .1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.1,1.1);
            transform: scale(1.1,1.1);
}
/*--- sponsord caro ---*/
.sponsored-caro .owl-item li {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 15px;
}
.sponsor-prod-name {
    display: inline-block;
    width: 100%;
}
.sponsor-prod-name > a {
    font-weight: 500;
}
.sponsor-prod-name > span {
    color: #92929e;
    float: right;
}
.shop-btn {
    color: #088dcd;
    display: inline-block;
    margin-top: 5px;
}
.sponsored-caro .stat-tools {
    text-align: center;
}
.share-info {
    color: #92929e;
    float: right;
    font-size: 11px;
    margin-top: 7px;
}
.share-info > span {
    margin-left: 10px;
}
/*--- new post popup ---*/
.post-newmeta{
	display: inline-block;
	width: 49%;
	padding-right: 12px;
	position: relative;
}
.post-categoroes {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 15px;
    padding-left: 0;
    width: 100%;
}
.post-categoroes > li {
    background: #f3f5f5 none repeat scroll 0 0;
    border-radius: 20px;
    color: #787d98;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 8px;
    padding: 5px 15px;
    width: 49%;
}
.post-new {
    display: inline-block;
    padding-top: 20px;
    width: 100%;
}
.post-categoroes > li:hover {
    background: #088dcd none repeat scroll 0 0;
    color: #fff;
}
.post-categoroes > li i {
    color: #b8bdd8;
    margin-right: 3px;
}
.post-categoroes > li:hover i {
    color: #fff;
}
.post-new .c-form {
    margin-top: 0;
    vertical-align: top;
    width: 46%;
	padding-left: 12px;
}
.dropzone {
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
}
.dropzone .dz-message {
    line-height: 122px;
    margin: 0;
    vertical-align: middle;
}
.dropzone .dz-preview {
    margin: 5px;
}
.c-form input.schedule-btn {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #088dcd;
    display: inline-block;
    font-size: 13px;
    padding: 2px 10px;
    text-transform: capitalize;
    width: 130px;
	text-align: center;
	cursor: pointer;
}
.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th{
	text-align: center;
}
.xdsoft_datetimepicker .xdsoft_calendar td > div{padding-right: 0;}
.activity-post {
    display: inline-block;
    width: 50%;
}
/*-- pure css select dropdown ---*/
.select-box {
    float: right;
    font-size: 13px;
    margin-top: 30px;
    position: relative;
    vertical-align: middle;
    width: 45%;
}
.select-box__current {
    cursor: pointer;
    outline: medium none;
    position: relative;
}
.select-box__current:focus + .select-box__list {
	opacity: 1;
	-webkit-animation-name: none;
	        animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
	cursor: pointer;
}
.select-box__current:focus .select-box__icon {
	-webkit-transform: translateY(-50%) rotate(180deg);
	        transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	width: 13px;
	opacity: 0.3;
	transition: 0.2s ease;
}
.select-box__value {
	display: flex;
}
.select-box__input {
	display: none;
}
.select-box__input:checked + .select-box__input-text {
	display: block;
}
.select-box__input-text {
    background-color: #f8fafa;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    display: none;
    font-size: 13px;
    margin: 0;
    padding: 4px 10px;
    width: 100%;
	color: #6d6e8e;
}
.select-box__list {
	position: absolute;
	width: 150px;
	padding: 0;
	margin: 0;
	list-style: none;
	opacity: 0;
	-webkit-animation-name: HideList;
	        animation-name: HideList;
	-webkit-animation-duration: 0.5s;
	        animation-duration: 0.5s;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-timing-function: step-start;
	        animation-timing-function: step-start;
	box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}
.select-box__option {
    background-color: #f3f5f5;
    display: block;
    margin-bottom: 0;
    padding: 4px 10px;
}
.select-box__option:hover, .select-box__option:focus {
	color: #546c84;
	background-color: #fbfbfb;
}
@-webkit-keyframes HideList {
	from {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	to {
		-webkit-transform: scaleY(0);
		        transform: scaleY(0);
	}
}
@keyframes HideList {
	from {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}
	to {
		-webkit-transform: scaleY(0);
		        transform: scaleY(0);
	}
}
/*--- create room popup ---*/
.popup-head > h5.only-icon > i {
    color: #088dcd;
    font-size: 28px;
}
.room-meta {
    display: inline-block;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}
.room-meta > h4 {
    display: inline-block;
    font-size: 20px;
    width: 100%;
}
.main-btn.full-width {
    border-radius: 5px;
    width: 100%;
}
.room-meta > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 20px 0;
    padding: 0;
    text-align: left;
    width: 100%;
}
.room-meta > ul > li {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.room-meta > ul > li i {
    display: inline-block;
    font-size: 20px;
    vertical-align: top;
}
.room-meta > ul > li > div {
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
}
.room-meta > ul > li .checkbox {
    float: right;
    margin: 0;
    width: auto;
}
.room-meta > span {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
}
.room-meta > ul > li > div > h6 {
    font-size: 14px;
}
.room-meta > ul > li > div > span {
    color: #92929e;
}
/*--- new popup with comment ---*/
#img-comt{
	padding-right: 0 !important;
	z-index: 99999;
}
#img-comt .modal-header {
    border: 0 none;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 5px;
}
#img-comt .modal-dialog {
    max-width: 100%;
	margin: 0;
	height: 100vh;
	position: fixed;
}
/*#img-comt .modal-content{
	height: 100vh;
}*/
.modal-body .we-comment{
	width: 88%;
}
.modal-body .post-comt-box{
	width: 90%;
}
.modal-body .post-comt-box form textarea{
	height: 50px;
}
.modal-body .we-comet {
    max-height: 410px;
	position: relative;
}
#img-comt .modal-header button.close {
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
    border-radius: 100%;
    color: #000;
    display: inline-block;
    height: 30px;
    margin: 0;
    padding: 0;
    width: 30px;
    z-index: 44;
}
.pop-image .stat-tools > a, .pop-image .stat-tools > .box a {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #fff;
    color: #FFFFFF;
    text-transform: uppercase;
}
.pop-image .stat-tools > a > i, .pop-image .stat-tools > .box a > i {
    color: inherit;
}
#img-comt .modal-content{
	border-radius: 0;
}
.user {
    display: inline-block;
    margin-bottom: 12px;
    width: 95%;
}
.user > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}
.user > figure img {
    border: 2px solid #cacaca;
    border-radius: 100%;
}
.user-information {
    display: inline-block;
    padding-left: 7px;
    vertical-align: middle;
	max-width: 54%;
}
.user-information > h4 {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0;
    width: 100%;
}
.user-information > h4 a {
    color: #212335;
}
.user-information > span {
    display: inline-block;
    font-size: 12px;
	color: #92929d;
}
.user > a {
    border: 1px solid;
    border-radius: 6px;
    float: right;
    font-size: 12px;
    margin-top: 16px;
    padding: 3px 20px;
}
.pop-image {
    background: #181e28;
    display: table;
    height: 98.2vh;
    padding: 50px;
    width: 100%;
}
.pop-item {
    display: table-cell;
    vertical-align: middle;
}
.pop-item > figure {
    display: inline-block;
    margin-bottom: 0;
	width: 100%;
	text-align: center;
}
.pop-item > figure img{width: 100%; }
.commentbar .modal-body {
    padding: 0;
	height: 100vh;
}
.commentbar .new-comment .commenter{
	width: 88%;
	padding: 0;
}
.commentbar .comments-area > ul li figure{
	margin-bottom: 0;
}
.commentbar {
    display: inline-block;
    position: relative;
    width: 100%;
	padding: 20px;
}
.commentbar .comments-area {
    display: inline-block;
    max-height: 420px;
    min-height: 420px;
    position: relative;
    width: 100%;
}
.commentbar > .user{width: 100%;}
.users-thumb-list > a {
    border: 2px solid #fff;
    border-radius: 100%;
    float: left;
    margin-right: -7px;
}
.users-thumb-list > a img {
    border-radius: 100%;
    max-width: 25px;
}
.users-thumb-list > span {
    display: inline-block;
    font-size: 9px;
    width: 82%;
}
.users-thumb-list {
    float: right;
    text-align: center;
    width: 34%;
}
.pop-item .emoji-state > p{
	color: #fff;
}
.modal.fade .modal-dialog{
	transition: none !important;
	-webkit-transform: none !important;
	        transform: none !important;
}
#img-comt .modal-body{
	height: 99.8vh;
}
.action-block {
    position: absolute;
    right: 60px;
    top: 70px;
}
.pop-item > .action-block > a {
    border: 1.2px solid #fff;
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    height: 36px;
    line-height: 34px;
    margin: 0 5px;
    text-align: center;
    width: 36px;
}
.pop-item > .action-block > a svg {
    height: 18px;
    stroke: #fff;
    width: 18px;
}
/*--- date and time widget ---*/
.date-time {
    display: inline-block;
    padding: 50px 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
}
.realtime {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.realtime > span {
    color: #088dcd;
    font-size: 40px;
    font-weight: 500;
}
.date-time > span#date {
    color: #3e3f5e;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    max-width: 90%;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.9);
}
.widget > img {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}
/*-- live stream page ---*/
#jqcam-picFS {
    display: none;
}
#jqcam-croppedFS {
    display: none;
}
#jqcam-snapBtn {
    display: none;
}
.jqcam-fieldset > legend {
    display: none;
}
/*--- live chat sidebar ---*/
.livestream-chat,
.side-area{
    background: #fafafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    display: inline-block;
    height: 88vh;
    padding: 15px;
    width: 100%;
}
.popup-content {
    display: inline-block;
    padding: 26px 30px;
    width: 100%;
}
.popup-content > h2 {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
}
.popup-content > span {
    display: inline-block;
    font-size: 13.5px;
    text-align: center;
    width: 100%;
}
.box-data {
    display: inline-block;
    margin-top: 50px;
    width: 100%;
}
.starting-ask {
    background: #fafafa none repeat scroll 0 0;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 15px;
    padding: 10px 15px;
    width: 100%;
	border: 1px solid #eaeaea;
}
.starting-ask.selected {
    border-color: #088dcd;
    border-width: 2px;
}
.starting-ask > i {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #088dcd;
    border-radius: 100%;
    display: inline-block;
    height: 40px;
    line-height: 35px;
    text-align: center;
    width: 40px;
}
.starting-ask > h6 {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
.starting-ask > p {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 4px;
}
.box-data > input {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 15px;
    padding: 8px 15px;
    width: 79%;
	vertical-align: top;
}
.box-data .chosen-container {
    display: inline-block;
    width: 20% !important;
	vertical-align: middle;
}
.wizard .box-data .chosen-container > a.chosen-single {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    box-shadow: none;
    height: 38px;
    line-height: 34px;
}
.wizard .chosen-container-single .chosen-single div b {
	
    
}
.box-data .seting-mode > p {
    font-size: 13px;
    margin-bottom: 0;
}
.box-data .seting-mode {
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 0;
    width: 100%;
}
#wizard > .content > h2{
	display: none;
}
.side-links {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}
.side-links > li {
    display: inline-block;
    width: 100%;
}
.side-links > li > a {
    background: #eaeaea none repeat scroll 0 0;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 10px;
    padding: 8px 10px;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.side-links > li > a > i {
    margin-right: 5px;
}
.side-links > li > a.active {
    background: #088dcd none repeat scroll 0 0;
    color: #fff;
}
.side-links > li > a:hover {
    background: #dadada none repeat scroll 0 0;
}
.side-links > li > a.active:hover {
    background: #0388c8 none repeat scroll 0 0;
}
.container-fluid.no-pad {
    padding: 0;
}
.livestream-chat .emojionearea.emojionearea-inline{
	height: 40px;
}
.livestream-chat .emojionearea .emojionearea-editor{
	height: 40px;
	min-height: 40px;
	max-height: 40px;
	resize: none;
	padding: 10px;
}
.livechat-head {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 0;
    width: 100%;
}
.livechat-head > h5 {
    display: inline-block;
}
.stream-controls {
    background: #fafafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    bottom: 0;
    left: 0;
    padding: 20px;
    position: absolute;
    width: 100%;
}
.stream-controls > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.stream-controls > ul > li {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    margin: 0 20px;
    position: relative;
	z-index: 1;
}
.stream-controls > ul > li:hover::before {
    background: #eaeaea none repeat scroll 0 0;
    border-radius: 20px;
    content: "";
    height: 30px;
    left: -8px;
    position: absolute;
    top: -5px;
    width: 120%;
    z-index: -1;
}
.stream-controls > ul > li > span {
    color: #92929e;
    font-size: 11px;
    position: absolute;
    right: -8px;
    top: -8px;
}
.stream-controls > ul > li > i {
    font-size: 18px;
    vertical-align: middle;
    color: #92929e;
}
.screen-area {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 100%;
	height: 86vh;
}
ul.chatting-area.max-height {
    max-height: 412px;
}
.screen-area #my_camera video{
	width: 750px !important;
}
#my_camera video {
    background: #000;
}

/*
Advanced example - Customized layout

*/

@media all and (min-width: 600px) {

    /* Change color for backdrop */
    .fancybox-custom-layout .fancybox-bg {
        background: #fcfaf9;
    }

    .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
        opacity: 1;
    }

    /* Move caption area to the right side */
    .fancybox-custom-layout .fancybox-caption {
        background: #f1ecec;
        bottom: 0;
        color: #6c6f73;
        left: auto;
        padding: 30px 20px;
        right: 44px;
        top: 0;
        width: 256px;
    }

    .fancybox-custom-layout .fancybox-caption h3 {
        color: #444;
        font-size: 21px;
        line-height: 1.3;
        margin-bottom: 24px;
    }

    .fancybox-custom-layout .fancybox-caption a {
        color: #444;
    }

    /* Remove gradient from caption*/
    .fancybox-custom-layout .fancybox-caption::before {
        display: none;
    }

    /* Adjust content area position */
    .fancybox-custom-layout .fancybox-stage {
        right: 300px;
    }

    /* Align buttons at the right side  */
    .fancybox-custom-layout .fancybox-toolbar {
        background: #3b3b45;
        bottom: 0;
        left: auto;
        right: 0;
        top: 0;
        width: 44px;
    }

    /* Remove background from all buttons */
    .fancybox-custom-layout .fancybox-button {
        background: transparent;
    }

    /* Navigation arrows */
    .fancybox-custom-layout .fancybox-navigation .fancybox-button div {
        padding: 6px;
        background: #fcfaf9;
        border-radius: 50%;
        transition: opacity .2s;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        opacity: .7;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]):hover div {
        opacity: 1;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button[disabled] {
        color: #999;
    }

    .fancybox-custom-layout .fancybox-navigation .fancybox-button:not([disabled]) {
        color: #333;
    }

    /* Reposition right arrow */
    .fancybox-custom-layout .fancybox-button--arrow_right {
        right: 308px;
    }
}

/*--- badges page ---*/
.author-info {
    display: inline-block;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
}
.badge-box {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-top: 30px;
    padding: 15px;
    text-align: center;
    width: 100%;
}
.badge-box > figure {
    display: inline-block;
    width: 100%;
}
.badge-box > figure img {
    max-width: 65px;
}
.badge-box > h5 {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 7px;
    text-transform: capitalize;
    width: 100%;
}
.badge-box > p {
    display: inline-block;
    font-size: 12px;
    line-height: 19px;
    width: 100%;
	margin-bottom: 0;
}
.badge-caro {
    margin-bottom: 30px;
    position: relative;
}
.badge-caro .owl-item > li img {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.badge-caro .owl-item > li {
    background: #fafafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 5px;
	padding: 0 10px;
}
.badge-caro .owl-item > li span {
    display: inline-block;
    font-size: 11px;
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
}
.badge-caro .owl-item > li .progress {
    border-radius: 0;
    left: 0;
    position: absolute;
    top: 115px;
    width: 100%;
    font-size: 10px;
}
/*---- event page ---*/
.event-browse.owl-carousel {
    float: none;
    margin-bottom: 0;
}
.event-browse li {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    display: inline-block;
    padding: 15px 0;
    text-align: center;
    width: 100%;
	cursor: pointer;
}
.event-browse li > i {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
    width: 35px;
}
.event-browse li span {
    display: block;
    font-size: 14px;
    margin-top: 10px;
}
.event-browse li.red i {
	background: #ff7373;
}
.event-browse li.blue i {
	background: #54c7ec;
}
.event-browse li.yellow i {
	background: #c9e413;
}
.event-browse li.green i {
	background: #45bd62;
}
.event-browse li.purpal i {
	background: #ff66bf;
}
.event-browse li.orang i {
	background: #fb724b;
}
.event-browse li.pink i {
	background: #efb6b6;
}
.event-post {
    background: #fff none repeat scroll 0 0;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
}
.event-post > figure {
    display: inline-block;
    margin-bottom: 7px;
    width: 100%;
}
.event-meta {
    display: inline-block;
    padding: 0 15px 15px;
    position: relative;
    width: 100%;
}
.event-post:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.event-post > figure img {
    border-radius: 5px 5px 0 0;
    display: inline-block;
    width: 100%;
}
.event-meta > span {
    color: #ff7373;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 6px;
    width: 100%;
}
.event-meta > h6 {
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
}
.event-meta > p {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
    width: 100%;
}
a.classic-btn {
    background: #f3f3f3 none repeat scroll 0 0;
    border-radius: 5px;
    color: #088dcd;
    display: inline-block;
    font-size: 13px;
    padding: 7px 20px;
    text-transform: capitalize;
}
.event-meta .more {
    bottom: auto;
    position: absolute;
    right: 15px;
    top: 0;
}
.event-meta a.classic-btn {
    text-align: center;
    width: 100%;
}
a.classic-btn:hover {
    background: #ebebeb none repeat scroll 0 0;
}
.event-detail-img {
    display: inline-block;
    width: 100%;
}
.event-detail-img img {
    width: 100%;
}
.container, .container-lg, .container-md, .container-sm, .container-xl{
    max-width: 1230px;
}
/*--- market product page ---*/
.market-post {
    display: inline-block;
    position: relative;
    width: 100%;
}
.market-post > figure, .market-post > figure img {
    display: inline-block;
    width: 100%;
}
.market-post > figure a {
    display: inline-block;
    width: 100%;
}
.prod-info {
    display: inline-block;
    width: 100%;
}
.prod-info > span {
    color: red;
    float: right;
    font-size: 18px;
    font-weight: 500;
}
.prod-info > a {
    color: #434155;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    width: 83%;
}
.like-meta {
    display: inline-block;
    list-style: outside none none;
    margin: 5px 0 0;
    padding: 0;
    width: 100%;
}
.like-meta > li {
    color: #8e8e9a;
    display: inline-block;
    margin-right: 10px;
	font-size: 13px;
}
/*--- New Register Page ---*/
.reg-from {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 15px;
    position: relative;
    resize: vertical;
    width: 100%;
    z-index: 2;
}
.ver-center {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    resize: vertical;
}
.reg-from > span {
    color: #515365;
    display: block;
    font-size: 22px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.reg-from > p {
    display: block;
    margin: 0 auto 20px;
    text-align: center;
    width: 100%;
}
.reg-from > span i {
    color: red;
    font-size: 20px;
    margin-right: 2px;
    vertical-align: middle;
}
.c-form .gender {
    display: inline-block;
    width: 100%;
}
.c-form .gender .form-radio {
    display: inline-block;
    width: 100%;
}
.c-form .gender .form-radio .radio {
    background: #f8fafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
    padding: 10px;
    width: 31.2%;
}
.c-form .gender .form-radio .radio:last-child{
	margin-right: 0;
}
.reg-from .c-form .checkbox label {
    font-size: 11px;
}
.reg-from .c-form input[type="radio"]{
	margin-bottom: 0;
	vertical-align: middle;
}
.reg-from .c-form button {
    padding: 8px 24px;
    text-transform: uppercase;
}
.reg-with {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 5px;
    width: 100%;
}

.big-ad {
    display: inline-block;
    position: relative;
    width: 100%;
    z-index: 2;
}

.social-reg {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.social-reg > li {
    display: inline-block;
}
.social-reg > li a {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
    width: 100%;
}
.big-ad {
    display: table-cell;
    height: 100vh;
    vertical-align: middle;
    width: 100%;
}

.big-ad > figure {
    margin-bottom: 50px;
}

.big-ad > h1 {
    color: #333145;
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 15px;
    width: 100%;
}
.big-ad > p {
    display: inline-block;
    font-size: 15px;
    width: 87%;
    color: #636175;
}   
.barcode {
    display: inline-block;
    margin-top: 50px;
    width: 100%;
}
.barcode > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 100px;
}
.barcode > figure > img {
    border: 6px solid #fff;
}
.app-download {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    width: 80%;
}
.app-download > span {
    color: #232135;
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
}
.app-download > ul.colla-apps {
    margin-bottom: 0;
	padding: 0;
}
.app-download > ul.colla-apps > li {
    display: inline-block;
    width: 25%;
}
.app-download > ul.colla-apps > li > a {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 30px;
    display: inline-block;
    padding: 5px 0;
    text-align: center;
    width: 100%;
}
.app-download > ul.colla-apps > li > a img {
    margin-right: 6px;
}
.reg-from .c-form .chosen-container {
    display: block;
    width: 100% !important;
}
.reg-from .c-form .chosen-container > a {
    background: #f8fafa none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    box-shadow: none;
    display: inline-block;
    height: 46px;
    line-height: 45px;
    width: 100%;
}
.row.merged10 {
}
.reg-from .c-form label {
    color: #72727e;
    font-size: 12px;
    margin-bottom: 5px;
}
.chosen-container-single .chosen-single div b{
	}
/*--- create wallet page ---*/
.nav.nav-tabs.walllt-btn {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
	border-bottom: 0;
}
.nav.nav-tabs.walllt-btn > li {
    display: inline-block;
    vertical-align: middle;
	margin-right: 5px;
}
.nav.nav-tabs.walllt-btn > li > a {
    background: #eaeaea none repeat scroll 0 0;
    border-radius: 5px;
    color: #535165;
    display: inline-block;
    padding: 5px 20px;
}
.walet-cat {
    background: #eaeaea none repeat scroll 0 0;
    border-radius: 7px;
    cursor: pointer;
    display: inline-block;
    height: 250px;
    padding: 20px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 100%;
    border: 1px solid #dddddd;
}

.nav.nav-tabs.walllt-btn > li > a.active {
    background: red none repeat scroll 0 0;
    color: #fff;
}


.bt-balance > i {
    cursor: pointer;
    margin-right: 9px;
}
.bt-balance > span {
    color: #535165;
    font-weight: 500;
}
.add-currency {
    background: red none repeat scroll 0 0;
    border: 2px solid transparent;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 10px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 100%;
}
.add-currency:hover {
    background: transparent none repeat scroll 0 0;
    border-color: red;
    color: red;
}
/*--- wallet balance widget ---*/
.walt-balance, .ask-question {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.walt-balance > ul {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 20px;
    padding-left: 0;
    width: 100%;
}
.widget .walt-balance > ul > li {
    border-right: 2px solid #ccc;
    cursor: pointer;
    font-size: 16px;
    line-height: 12px;
    margin-bottom: 0;
    margin-right: 5px;
    padding-right: 5px;
    position: relative;
    transition: all 0.2s linear 0s;
    width: auto;
}
.widget .walt-balance > ul > li:last-child {
    border-right: 0 none;
}
.widget .walt-balance > ul > li:hover {
    color: red;
}
.walt-balance > h6 {
    color: #535165;
    float: left;
    font-size: 20px;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
}
.walt-balance > a {
    background: transparent none repeat scroll 0 0;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    display: inline-block;
    margin-right: 10px;
    padding: 3px 10px;
    text-align: center;
    transition: all 0.2s linear 0s;
    width: 100px;
}
.walt-balance > span {
    color: red;
    float: right;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 40px;
    margin-top: -19px;
    text-align: right;
    width: 100%;
}
.walt-balance > a:last-child {
    margin-right: 0;
}
.walt-balance > a:hover {
    background: red none repeat scroll 0 0;
    color: #fff;
}
.transction {
    display: inline-block;
    width: 100%;
}
.transction > ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.transction > ul > li {
    background: #f2f2f2 none repeat scroll 0 0;
    display: inline-block;
    padding: 8px 10px;
    position: relative;
    width: 100%;
}
.transction > ul > li figure img {
    border: 1px solid;
    border-radius: 100%;
    display: inline-block;
    max-width: 35px;
}
.transction > ul > li > figure {
    display: inline-block;
    margin: 0 6px 0 0;
    vertical-align: middle;
}
.trans-detail {
    display: inline-block;
    vertical-align: middle;
}
.trans-detail > span {
    color: #535165;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
}
.trans-detail > p {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    width: 100%;
}
.amount-status {
    float: right;
    text-align: center;
}
.amount-status > span {
    font-size: 12px;
}
.amount-status > h6 {
    color: #535165;
    font-weight: 400;
}
.transction > ul > li:nth-child(2n) {
    background: #eaeaea none repeat scroll 0 0;
}

/*--- create wallet popup ---*/
.add-popup {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    left: 50%;
    padding: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 650px;
	box-shadow: 0 2px 4px rgba(0,0,0,.5);
}
.card-poup-meta {
    display: inline-block;
    width: 100%;
}
.card-poup-meta > ul,
.security > ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
}
.card-poup-meta > ul > li,
.security > ul > li{
    background: #eaeaea none repeat scroll 0 0;
    border: 2px solid #dfdfdf;
    border-radius: 6px;
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 110px;
    margin: 0 4px;
    cursor: pointer;
    vertical-align: top;
}
.security > ul > li{
    width: 295px;
}
.card-poup-meta > ul > li.active,
.security > ul > li.active{
    background: #fff;
}
.card-poup-meta > ul > li figure,
.security > ul > li figure{
    display: inline-block;
    margin-bottom: 5px;
    width: 100%;
}
.card-poup-meta > ul > li img,
.security > ul > li img{
    max-width: 50px;
}
.card-poup-meta > ul > li > p,
.security > ul > li > p{
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
}
.card-poup-meta > ul > li span,
.security > ul > li span{
    color: #535165;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 3px;
    width: 100%;
}
.popup-title > h5 {
    color: #535165;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 4px;
    width: 100%;
}
.popup-title {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}
.card-poup-meta > button {
    color: #fff;
    display: inline-block;
    padding: 8px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    border: none;
    border-radius: 5px;
}
.security {
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 30px;
    width: 100%;
}
.security > h6 {
    color: #535165;
    display: inline-block;
    font-size: 17px;
    margin-bottom: 12px;
    width: 100%;
}
/*--- product page feature post ----*/
.big-post.mb-4 {
    display: inline-block;
    position: relative;
    width: 100%;
}
.big-post > figure {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    width: 100%;
}
.big-post > figure > a {
    display: inline-block;
    width: 100%;
}
.big-post > figure::before {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,088dcd+100&0+0,1+100 */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(125,185,232,0) 0%,rgba(8,141,205,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#088dcd',GradientType=0 ); /* IE6-9 */

    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.big-deals {
    bottom: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
}
.big-deals > ins {
    color: #fff;
    display: inline-block;
    font-size: 11px;
    text-decoration: none;
    width: 100%;
}
.big-deals > h4 {
    color: #fff;
    display: inline-block;
}
.big-deals > p {
    color: #fff;
    display: inline-block;
    line-height: 20px;
    width: 100%;
}
.big-deals > a {
    background: #fff none repeat scroll 0 0;
    border-radius: 16px;
    color: blue;
    display: inline-block;
    font-size: 13px;
    padding: 4px 12px;
}
.align-center {
    text-align: center;
}


.nav-res {
    margin-top:0.1%;
    margin-bottom: 0%;
    padding:0px;
    
  }

  #progress-bar{
    --progress: 0;
    height: 9px;
    width: var(--progress);
    background-color: var(--blue);
    position:fixed;
    left:-4.3%;
   
  }

  @media (max-width:920px){
    #progress-bar{
        left:-16%;
        top:0.1%;
      }
  }
  .shadow-post{
    box-shadow: 10px 10px 10px rgba(0,0,0,0.2),
    -10px -10px 10px rgba(255,255,255,0.5);
  }
  #border-shadow{
    border-radius: none;  
}


@media(max-width:1024px){

  
    
    p{
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 10px;
    }
    
    #GroupM{
      display:None;
    }
    .paragraph-up{
        margin-top:80%;
    }
    .txx{
        position: absolute;
    margin-top: 135%;
    margin-left: 3%;
    width: 317px;
    height: 245px;
   
    margin-bottom: 10%;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.2),
    -10px -10px 10px rgba(255,255,255,0.5);
    }
    .card-up{
        padding-top:-120%;
       
      
    }
    .body-top{
        margin-top: -25%;
    }
}

@media (max-width:1024px){
      /*Card Profile starts*/
     #text-title{
         
        width:110%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }
.cardpro{
   left: 20%;
   }
   #widget1{
    padding: 50%;
}
   .heading1{
    width:115%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-left:-8%;
    margin-top:-7%;
   }
   .img-fix{
       margin-left:55%;
       margin-top: -5%;
   }
.fix-details{
  margin-left:-16%;
  margin-top:-60%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
    
}


}

/*Card Profile starts*/
.cardpro{
    background-color: #f4f3f3;
    width: 262px;
    height: 450px;
    text-align: center;
    margin: -17.5%;
    border-radius: 10px;
    
   }

   /* .height-widget{
       height:100%;
   } */
   .card-background11{
    /* background: linear-gradient(to right, #009688, #4CAF50); */
    height: 160px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   }
   
   .card-image11{
    border-radius: 50%;
    width: 120px;
    height: 130px;
    position: relative;
    top: 100px;
    border: 6px solid #f4f3f3;
   }
   
   .card-info11{
    margin-top: 80px;
    padding: 10px;
   }
   .fab{
    color: grey;
    font-size: 24px;
    margin: 15px;
    cursor: pointer;
   }
   
   .fab:hover{
    color: #524b4b;
   }
   
   .heading1{
    color: #009688; 
    
   }
   
   .infotag{
    color: grey;
   }
   /* .image-bg{
    background-color: red;
    background-image:
      radial-gradient(
        circle at top right,
        yellow,
        #f06d06 50%
      );
   } */

/*Card Profile Ends */

@media (max-width:1024px){
    #chatmobile{
        top: 5%;
    }

    .shadow-1-strong{
        margin-left:10%;
        margin-top: 5%;
    }
}
.notcard{box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
         padding-left: 30px;
         padding-right: 30px;
         padding-bottom: 0.1px;
        margin-bottom: 15px;
         border-left-style: solid;
         border-left-width: 7px; 
         border-left-color: #ef4343;
         border-radius: 2px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;}

        .notmove{
            padding-left: 20px;
        }

.fields{
    margin-top: 5px;
}
.followBar{
    margin-top: -40px;
}
.AboutUserin {
    width: 50%;
    margin-left: 28%;
}

.FollowB.works{
    background-color: red;
    border-color: red;
    padding-top: 20px;
}

.FollowB.reading{
    background-color: black; 
    padding-top: 20px;
}

.FollowB.followers{
    background-color: green;
    border-color: green;
    padding-top: 20px;
}

.text-center{color:white;}

.FollowB{
    background-color: white; 
    border-style: solid;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    width: 200px;
    border-radius: 4px;
    border-color: black;

  }

  
.BoxMessage{
        
        margin-top: -15%;
        border-radius: 20px;
}
.MessageB{
 
    border-radius: 10px;
    height: 160px;
    padding-top: 0px; 
    /* background-color: red; */
}
.textMessage{
    margin-left: 12%;
}
.Fbutton{
    width: 5px;
}
.MessageBox{
    box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
    inset 0.1rem 0.1rem 0.3rem #868686;
    border-radius: 10px;
    height: 80px;
    padding-top: 20px;
}
.sendB{
  font-size: 22px;  
  margin-left: 10px;
  cursor: pointer;
}
.InputMessageBox{
 border-radius: 30px;
 width: 400px;
 margin-left: 12%;
 margin-top: 5%;
 height: 40px;
 box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
              inset 0.1rem 0.1rem 0.3rem #868686;
 
}
/* Avator styling  */
.avatar{

    border-width: 1px;
    border-color: gray;
    border-style: outset;
    margin-left: 45%;
    margin-top: -70px;

    }
    .avatarM{

        border-width: 1px;
        border-color: gray;
        border-style: outset;
        margin-left: 2%;
        margin-top: -8%;
    
        }    
    
.profileCover{   
    background-color: #f6f6f6;
background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
height: 150px;
}
label.filebutton {
    overflow:hidden;
    position:relative;
    margin-left: 55.4%;
  margin-top: 6%;
  z-index: 1000;


}

label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding:0;
}
.fa-camera{

    font-size: 40px;
   
}
.uploadImg{
 margin-left: 90%;
 margin-top: -300px;

}
.uploadBooks{
    margin-left: 90%;
    margin-top: -5px;
   cursor: pointer;
   }
.userN{
    margin-top: 30px;
    margin-left: 47%;
}

.btn.btn-primary.uploadImg{color:black;
                           background: white;
                            border-color: black;}

.followers:hover{
    cursor: pointer;
}

.customModal{
    box-shadow: 0px 5px 10px 0px rgb(82 80 80);
    border:0px;
    background: #121217
}

@media screen and (max-width: 1000px) {
    /* Avator styling  */
.avatar{
    margin-left: 30%;

    }
    .uploadImg{
        
        margin-left: 68%;
        width: 30%;
        margin-top: -60%;
        
       }
      


       .fa-camera{

        font-size: 30px;
    }
    .userN{
        margin-top: 30px;
        margin-left: 40%;
      
    }
    label.filebutton {
        overflow:hidden;
        position:relative;
        margin-left: 69.4%;
      margin-top: 23%;
     
    
    }
}




@media screen and (max-width: 1024px) {
    /* Avator styling  */
.avatar{
    margin-left: 23%;

    }
    .AboutUserin {
        width: 95%;
        margin-left: 2%;
    }
    .InputMessageBox{
        border-radius: 30px;
        width: 65%;
        margin-left: 21%;
        margin-top: 5%;
        height: 35px;
        box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
                     inset 0.1rem 0.1rem 0.3rem #868686;
        
       }
       .sendB{
        font-size: 17px;  
        margin-left: 3px;
        cursor: pointer;
      }
       .avatarM{

        border-width: 1px;
        border-color: gray;
        border-style: outset;
        margin-left: 2%;
        margin-top: -13%;
    
        }    
    
    .BoxMessage{
            width: 135%;
            margin-left: -15%;
            box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
            inset 0.1rem 0.1rem 0.3rem #868686;

    }

  
    .uploadImg{
        
        margin-left: 68%;
        width: 30%;
        margin-top: -48%;
        
       }
       .profileCover{
        background: rgb(2,0,36);
        background: grey;
        height: 150px;
    }
       .fa-camera{
           

        font-size: 30px;
    }
    .userN{
        margin-top: 10px;
        margin-left: 40%;
      
    }
    label.filebutton{
        position:relative;
        margin-left: 63.4%;
      margin-top: 23%;
     
    
    }
    .notstyle{
        width: 700px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-left:10px;
        padding-top: 10px;
    }
    .MessageBox{
        height: 120px;
        width: 115%;
        margin-left: -10%;

    }
    .textMessage{
        margin-left: 25%;
    }
    
}

@media only screen and (max-width: 1000px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 640px;
    }

    .notcard{
       width: 680px; 
    }
}

@media only screen and (max-width: 750px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 500px;
    }

    .notcard{
       width: 600px; 
    }
}
@media only screen and (max-width: 600px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 400px;
    }

    .notcard{
       width: 500px; 
    }
}

@media only screen and (max-width: 490px){
    #notes{
       margin-top: 10px;
       height: 130px;
       width: 300px;
    }

    .notcard{
       width: 400px; 
    }
}
   

@media only screen and (max-width: 360px){
    #notes{
       margin-top: 10px;
       height: 130px;
       width: 270px;
    }

    .notcard{
       width: 350px; 
    }
}
           

                   /* Extra small devices (phones, 320px and down) */
@media only screen and (min-width: 320px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
                     
        .notstyle{
            width: 315px; 
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            margin-left: 2px;
            padding-left:10px;
            padding-top: 10px;
}

.text-center{margin-left:-30px;
font-size: 14px;
margin-top:-5px;
padding-left: 5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 70px;
border-radius: 4px;
border-color: black;
}}

@media only screen and (min-width: 360px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
                     
    .notstyle{
        width: 355px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: 2px;
        padding-left:10px;
        padding-top: 10px;
}

.text-center{margin-left:-30px;
font-size: 14px;
margin-top:-5px;
padding-left: 5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 85px;
border-radius: 4px;
border-color: black;
}}

@media only screen and (min-width: 400px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
    .notstyle{
        width: 390px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: 2px;
        padding-left:10px;
        padding-top: 10px;
}

.text-center{margin-left:-20px;
font-size: 14px;
margin-top:-5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 170px;
border-radius: 4px;
border-color: black;
}}


@media only screen and (device-width: 1024px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }

    .uploadImg{
            margin-top: -265px;}

    .avatar{
        margin-left: 375px;
        margin-top: -250px;
        }
   


.text-center{margin-left:-20px;
font-size: 18px;
margin-top:-5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 280px;
border-radius: 4px;
border-color: black;
}}


@media only screen and (min-width:1280px){

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }

    .notstyle{
        margin-left: 50px;
        width: 700px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-left:10px;
        padding-top: 10px;
}
}


.navp{
    transition: all 0.1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
   margin-top: 17%;
    z-index: 2000;
    box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
    width: 100%;
  }
  .AboutUser{
      margin-left: 20%;
      width: 60%;
  }
  .scrollNav {
    transition: all 0.5s ease-in;
    z-index: 2000;
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #dddddd;
  }
  .styl {
    padding-top: 80px;
  }
  .btn--outline{
    margin-top: -25px;
  }
  
  .prem{
    background-color: #c7e9fb;
    background-image: linear-gradient(315deg, #c7e9fb 0%, #ef4343 74%);  
    border-radius:20px;
  }
  /* Avator styling  */
  .avator{
  vertical-align: middle;
  width: 35px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-color: gray;
  border-style: outset;
  }
  #mobileNav{
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  }
  
  /* Avator styling  */
  .avator2{
    vertical-align: middle;
    width: 35px;
    height: 30px;
    border-radius: 50%;
    border-width: 2px;
    border-color: gray;
    border-style: outset;
    z-index: -999;
    }
    
  
  a.nav-links-mobile.sign{
    color:#020202;
  }
  .fa-bars{
    color: #020202;
  
   
  }
  .navbar-containerp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    max-width: auto;
    
  }
  
  
  .navbar-logo {
     color: #fff; 
    justify-self: start;
    margin-left: 20px;
  
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-itemp{
    font-size: 15px;
  
  }
  .nav-userp{
    font-size: 15px;
    margin-left: -20px;
    display: none;
  
  }
  .nav-userdp{
    font-size: 17px;
    margin-right: 200px;
    position:absolute;
    color: #ef4343;

   
  
  }
  .nav-logoutp{
    font-size: 17px;
    right: 20px;
    position:absolute;
    color: #ef4343;
   
  
  }
  a.nav-linksp{
    text-decoration: none;
    -webkit-text-decoration-line:none;
            text-decoration-line:none;
    color:black;
  }
  .nav-linksp:hover  {
    text-decoration: none;
    color: #ef4343;
  
    
  }
  
  .nav-linksp:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0%;
    background: #ef4343;
    border-radius: 12px;
    transition: all 0.4s ease;
  }
  .nav-linksp:hover:before{
    width: 80%;
  }
  .nav-linksp:before{
    left: 10%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .nav-linksp:before{
    width: 100%;
    bottom: -5px;
    opacity: 0;
  }
  .nav-linksp:hover:before{
    bottom: 0px;
    opacity: 1;
  }
  .nav-linksp:before{
    width: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  }
  .nav-linksp:hover:before{
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .nav-menup {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 1rem;
  }
  
  .nav-linksp {
    color: #ef4343;
    font-weight:bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 135%;
  }
  
  .menu-iconp {
    display: none;
  }
  
  .nav-linksp{
    text-decoration: none;
    transition: all 0.2s ease-out;
  }
  svg.sc-bdvvtL.cmlAWX.feather.feather-moon {
    margin-top: -6.5px;
    height: 25px;
    width:25px;
  
  }
  
  svg.sc-bdvvtL.cmlAWX.feather.feather-sun {
    margin-top: -6.5px;
    height: 25px;
    width:25px;
   
  }
  
  
  .nav-links-mobilep{
    display: none;
    
  }
  
  
  .menu-iconp{
    display: none;
  }
  /* tesla fixed the movement of the side menu wen screens are changed in size */
 
  @media screen and (max-width: 1024px) {
    .navp {
      width: 100%;
      margin-top: 44%;

   
    
  }
  .nav-user{
    font-size: 15px;
    margin-inline: 10;
    margin-left: -20px;
    display: inline-block;
  }
  .nav-menu {
    display: inline-table;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    position: absolute;
    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .prem{
    display: none;
    background-color: #c7e9fb;
    background-image: linear-gradient(315deg, #c7e9fb 0%, #ef4343 74%);  
    border-radius:20px;
  }
  }
  
  
  /* tesla fixed the movement of the side menu wen screens are changed in size */
  @media screen and (max-width: 1080px) {
    .navp {
      width: 100%;
      margin-top: 64%;

    
  }
  
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: inline-table;
      flex-direction: column;
      width: 50%;
      height: 80vh;
      position: absolute;
      top: 0%;
      margin: 0;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #ffffff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
     
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      
    }
  
  
    
  
    .navbar-logo {
      position: absolute;
      top: 0;
      margin-top: -25px;
      margin-right: 80%;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    
    .menu-icon {
      display: block;
      position: absolute;
      top: -0px;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      margin-top: -19px;
    }
  
    .fa-times {
       color: #fff; 
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display:ruby-base;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
    .nav-user{
      font-size: 15px;
      margin-inline: 10;
      margin-left: -20px;
      display: inline-block;
    }
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .nav-logout{
  display: none;
     
    
    }
    .AboutUser{
        margin-left: 0%;
    }
    
    @media only screen and (device-width: 1024px) {
        .navp{margin-top:200px;}}
  }
  
  
.dropM {
  width: 170px;
  position: absolute;
  top: 60px;
 margin-left: -70px;
  list-style: none;


}

.dropM li {
  background: white;
  width: 170px;
  cursor: pointer;
  text-align: left;
  border-style: solid;
  border-color: #ef4343;
  


}

.dropM li:hover {
  background: #ef4343;
  

}

.dropM.clicked {
  display: none;
}

.dropL {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: black;
  padding: 16px;
  font-size: 12px;
}
.dropL:hover {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
  text-decoration: none;
  font-size: 18px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
/* Mother Land */
.mom-container {
    width: 100%;
    margin-bottom: 2%;
}
/* Mother Land Flag */
.country-flag{
    background-color:transparent;
}

/* country flag */
#flag{
    margin-left: 37%;
    width: 25%;
    height: 20vh;
    margin-top: 8%;
    margin-bottom: 2%;
}
#flag2{
    position: absolute;
    top: 0%;
    width: 100%;
    height: 45vh;
    opacity: 0.2;

}
/* country selector list */
.country-selector{
    position: absolute;
    top: 12%;
    width: 12%;
    left: 5%;
}

/* country history container */
.history{
    text-align: center;
    margin: 0% 2%;
    border-radius: 4px;
}
#name{
    text-align: center;
    font-weight: 700;
    padding: 0% 4%;
}
.history > #heading{
    text-align: left;
    font-weight: 500;
    margin-left: 5%;
}

.history #historytext{
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    color:inherit;
    text-align: justify;
    line-height: 25px;
    font-size:19px;
    padding: 1% 5%;    
}
.history #horizontal{
    margin-left: 5%;
    margin-top: 0.5%;
    background-color: grey;
    width: 90%;
    height: 1px;
}

#imgBtn{
    color: #9f1f4a;
    background-color: transparent;
    border: none;
    margin-top: -2%;
    margin-left: 80%;
    
}

/* updata button */
#update-btn{
    position: relative;
    color: #fff;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 15%;
}

#edit-bt{
    color: #9f1f4a;
    background-color: transparent;
    border: none;
}
/* edit text area */
.textedit-box{
    text-align: center;
    margin:5% 0;
    width: 100%;
    padding: 2%;
    border-radius: 4px;
}
.textarea{
    margin:0.5% 2%;
}
#text{
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 60vh;
    padding: 2%;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media screen 1080px */
@media screen and (max-width:1080px) {
/* country flag */
#flag{
    margin-left: 40%;
    width: 20%;
    height: 10vh;
    margin-bottom: 2%;
    opacity: 1;
}
#flag2{
    display: none;
}
/* country selector list */
.country-selector{
    position: absolute;
    width: 25%;
    height: 5%;
    top: 12%;
    left: 2%;
}

.history > #heading{
    text-align: left;
    font-weight: 500;
    margin-left: 5%;
    font-size: 25px;
}
    #name{
        font-size: 25px;
       text-align: center;
       font-weight: 600;
       margin-bottom: -5%;
    }
    .history{
        margin: 2% 5%;
        text-align: center;
    }
   
    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 30px;
        font-size: 19px;
        padding: 2% 4%;
        border-radius: 8px;
        
    }
    .history > h1{
        font-size: 25px;
        width: 90%;
        margin-left: 5%;
        padding: 2% 4%;
    }
 /* updata button */
#update-btn{
    position: relative;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 5%;
}   
    /* edit button */
    #imgBtn{
        margin-left: 0%;
    }

    /* edit text area */
.textedit-box{
    text-align: center;
    margin:10% 0;
    width: 100%;
    height: 70vh;
    padding: 2%;
    border-radius: 4px;
}
    .textarea{
        width: 90%;
        margin:1% 5%;
        
    }
    #text{
        font-size: 17px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
    
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media screen 720px */
@media screen and (max-width:720px) {

    #flag{
        margin-left: 38%;
        width: 25%;
        height: 15%;
        margin-top: 5%;
        margin-bottom: 2%;
        padding: 10px 20px;
        opacity: 1;
    }
    #flag2{
        display: none;
    }
    /* country selector menu */
    .country-selector{
        position: absolute;
        width: 25%;
        top: 10%;
        left: 2%;
    }

     /* history title & paragraph */
     .history{
         margin-top:5% ;
         margin: 2% 5%;
         /* box-shadow: 1px 2px 6px 1px rgb(83, 82, 82); */
     }
     
    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 25px;
        font-size: 19px;
        padding: 2% 5%;
    }

    .text-field{
        margin: 5% 0%;
    }
    /*edit button  */
    #imgBtn{
        margin-left: 0%;
    }
    /* update button */
  
    #text{
        font-size: 20px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
}
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Media Screens */
@media screen and (max-width:650px) {
     /* all css  */
     #flag{
        margin-left: 38%;
        width: 25%;
        height: 15%;
        margin-top: 5%;
        margin-bottom: 2%;
        padding: 10px 20px;
        opacity: 1;
    }
    #flag2{
        display: none;
    }
    /* country selector menu */
    .country-selector{
        position: absolute;
        width: 25%;
        top: 10%;
        left: 2%;
    }
  
     /* history title & paragraph */
     .history{
         margin-top:5% ;
         margin: 2% 5%;
     }

    .history #historytext{
        background-color: transparent;
        text-align: justify;
        line-height: 25px;
        font-size: 18px;
        padding: 2% 5%;
        border-radius: 8px;   
    }

    .text-field{
        margin: 5% 0%;
    }

    /* edit button */
    #imgBtn{
        text-align: center;
    }
   
    #text{
        font-size: 15px;
        width: 100%;
        height: 60vh;
        padding: 2%;
    }
    
   
}
/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* media Screen 480px */
@media screen and (max-width:480px) {
         /* all css  */
         #flag{
            margin-left: 36%;
            width: 30%;
            height: 15%;
            margin-top: 5%;
            margin-bottom: 2%;
            padding: 10px 20px;
            opacity: 1;
        }
        #flag2{
            display: none;
        }

        /* country selector menu */
        .country-selector{
            position: absolute;
            width: 25%;
            top: 12%;
            left: 5%;
        }

        .history > #heading{
            text-align: center;
            font-weight: 500;
            margin-left: 5%;
            font-size: 25px;
        }
        .history{ 
            margin-top: 5%;
            text-align: center;
        }
        .history #historytext{
            background-color: transparent;
            text-align: justify;
            line-height: 25px;
            font-size: 17px;
            padding: 2% 4%;
            border-radius: 8px;   
        }
       
       
        .text-field{
            margin: 5% 0%;
        }

        /* edit button */
        #imgBtn{
            margin-left: 0%;
            text-align: center;
        }
      
        #text{       
            font-size: 15px;
            width: 100%;
            height: 60vh;
            padding: 2%;
        }
}
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
@media screen and (max-width:360px) {
   /* country flag */
   #flag{
    margin-left: 36%;
    width: 30%;
    height: 15%;
    margin-top: 5%;
    margin-bottom: 2%;
    padding: 10px 20px;
    opacity: 1;
}
#flag2{
    display: none;
}

/* country selector menu */
.country-selector{
    position: absolute;
    width: 25%;
    top: 12%;
    left: 4%;
}
/* updata button */
#update-btn{
    position: relative;
    color: #fff;
    width:20%;
    padding: 0.5%;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 5%;
}   

.history{
    margin-top: 10%;
    text-align: center;
    text-align: justify;
    border-radius: 4px;
}

.history #historytext{
    background-color: transparent;
    line-height: 25px;
    word-spacing: 1px;
    font-size: 17px;
    padding: 1% 4%;    
}
    .textarea{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .text-field{
        margin: 5% 0%;
    }
    #edit-btn{
        margin-left: 5%;
        margin-bottom: 2%;
        width:30%;
        padding: 1%;
        font-size: 15px;
    }
    #imgBtn{
        color: #9f1f4a;
        background-color: transparent;
        border: none;
        margin-top: -10%;
        margin-left: 30%;
        
    }
  
    #text{
        font-size: 20px;
        margin:0 5%;
        width: 100%;
        height: 70vh;
        padding: 30px;
    }

}
.CID-img{
    width: 100%;
    height: 60vh;
    /* margin-top: 5%; */
}
.CID-container{

    margin:0% 5%;
}
.fa {
    position: relative;
    font-size: 40px;
    margin-top:5% ;
}

.fa-chevron-circle-left{
    align-content: left;
    margin-left:-54.5% ;
    background:#ef4343;
    border-radius: 50px;
}
.fa-chevron-circle-left:hover{
    color: #fff;
    cursor: pointer;
    background:#ef4343;
    border-radius: 50px;

}
.fa-chevron-circle-right{
   margin-left: 51% ;
   background:#ef4343;
   border-radius: 50px;
}
.fa-chevron-circle-right:hover{
    color: #fff;
    cursor: pointer;
    background:#ef4343;
    border-radius: 50px;

}
/* media screens */
/* ===================================================================================================== */
@media screen and (max-width: 720px) {

    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
    }

    .fa {
        display: none;
}
}
@media screen and (max-width:650px) {
    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
    }

    .fa {
        display: none;
    }
}
/* ========================================================================================= */
@media screen and (max-width:480px) {
    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
        padding: 2% 5%;
    }

    .fa {
        display: none;
    }
}
/* =========================================================================================== */
@media screen and (max-width:360px) {
    .CID-img{
        width: 100%;
        height: 30vh;
        margin-top: 20%;
    }

    .fa {
        display: none;
    }
}
/* image upload box */
.imgUpd-box{
    text-align: center;
    margin: 0% 2%;
    width: 95%;
    padding: 2%;
    margin-top: 8%;
    border-radius: 4px;
}
/* close button */

/* upload button*/
#upload-btn{
    color: #fff;
    padding: 0% 3%;
    border-radius: 8px;
}
/* file container */
#file-label{
    background: #ef4343;
    overflow:hidden;
    position:relative;
    margin-top: 2%;
    padding: 1%;
    color: #fff;
    border-radius: 8px;
}
#file-label:hover{
    cursor: pointer;
    background-color: #fff;
    color: #000;
}
/* image icon */
.fa-image{
    font-size: 50px;
}
/* ordinary file input */
#file{
    display: none;
}


.pop{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pop-inner{
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 95%;
    height: 90vh;
    padding-bottom: 5%;
    border-radius: 8px;
    background-color: #fff ;
}
.pop-inner .closebtn{
    position:absolute;
    background-color: transparent;
    color: #9f1f4a;
    border: none;
    font-size:35px;
    top: -0.5%;
    right: 1%;
}

.pop-inner > img{
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.1;

}

/* ======Media Screen===== */
@media screen and (max-width:1080px) {
  .pop{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pop-inner{
    align-items: center;
    position: relative;
    width: 90%;
    height: 90vh;
    margin-bottom: 6%;
    border-radius: 8px;
    background-color: #fff ;
}

}
@media screen and (max-width:720px) {
  
}
@media screen and (max-width:650px) {
  
}
@media screen and (max-width:480px) {
  
}
/* ALL ABOUT CSS */
/* ABOUT HEADING */
.about-main > h1{
    text-align: center;
    font-weight: 700;
    padding: 10% 0;
}
.about-container{
    margin: 0 5%;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 45%;
    width: 700px;
}
.A_para {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 20px;
    margin-left: 2%;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    /* color: rgb(247, 10, 128); */
}

/* IMAGES */
#img1{
    margin-top: -40%;
    margin-left: 1%;
    height: 40%;
    width: 35%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 40%;
    height: 20%;
    margin-left: 55%;
    margin-top: -25%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 40%;
    padding: 30px;
    margin-left: 1%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    font-family:Arial, Helvetica, sans-serif;
    line-height: 25px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 10%;
    margin-bottom: -15%;
}
.about-footer2{
    margin-left: 15%;
    display: flex;
    text-align: center;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 16%;
}

.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}

/* MEDIA SCREENS */
@media screen and (max-width:1080px)
{
   /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    margin-top: -2%;
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 25px;
    margin: 0 1%;
    font-size: 20px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: none;
            transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 35px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    margin-left: 18%;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 12%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}
@media screen and (max-width:720px)
{
     /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    margin-top: -3%;
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 17px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: none;
            transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    text-align: center;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 10%;
}
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}  
}
@media screen and (max-width:650px)
{
    /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 18px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: none;
            transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 18px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{

    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 10%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}
@media screen and (max-width:480px) {
     /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 25px;
    margin: 0 2%;
    font-size: 18px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: none;
            transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 18px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    margin-left: 0%;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left:12%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
    
}
@media screen and (max-width:360px)
{
    /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 35px;
    margin: 0 2%;
    font-size: 20px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    -webkit-transform: none;
            transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 35px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 16%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}



.row{
    margin-top: 5%;
    overflow-x: hidden;

}
.Movetile{
margin-left: 4%;
font-size: 15px;

}
.rowPosters{
    display: flex;
    margin-top:-6%;
    overflow-y:hidden;
    overflow-x: scroll;
    padding: 20px;
    padding-top: 100px;
}


.rowPoster{
    width: 100px;
    height: 150px;
    margin-right: 10px;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
}

.rowPoster:hover {
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
}
@media screen and (max-width: 1024px) {
    .row{
        margin-top: 20%;
        overflow-y:hidden;

    }
    .rowPosters{
  
        padding-top: 22px;
    }
}
#top{
    background-color: #ef4343;
    padding: 10% 0%;
    width: 100%;
    margin-top: -10%;
    text-align: center;
    color: #ffff;
}
.P-body{
    /* color: black; */
    text-align: justify;
}
ol li:hover{
    cursor: pointer;
    color: #ef4343;
}
/* all lists tags */
#P-list{
    font-family:Arial, Helvetica, sans-serif;
    line-height: 25px;
    display: list-item;
    text-align: left;
    /* color: inherit; */
}
.P_para{
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 35px;
}
/* bold tags */
b{
    margin: 0%;
}
/* italic tags */
i{
    margin: 0;
}
/* paragraph tags */
p{
    line-height: 45px;
    font-size: 20px;
}
h2{
    padding: 2% 0%;
}
/* form  */
.P-form {
  margin: 5% 0%;
}
#point{
    font-family:Arial, Helvetica, sans-serif;
}
.P-form  > input  {
    width: 80%;
    background-color: honeydew;
    padding: 2%;
     }
    #top-btn{
        background-color:   #ef4343; 
        color:#fff;
        padding:1% 4%;
        position: fixed;
        bottom:2%;
        right:5%;
        font-size:15px;
        cursor:pointer;
        border-radius: 2px;
        border-color: #fff;
    }
    /* footer */
    .P-footer {
      
        display: grid;
        background-color: #ef4343;
      

    }
    .P-footer > #link{
       
        width: 90%;
        padding-left: 5%;
        margin-top:2%;
        margin-bottom: 2%;
        text-decoration: none;

    }
/* ===================================================================================================== */
    /* Media Screens */
    @media screen and (width:1080px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            /* font-size: 15px;  */
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
    }
    @media screen and (width:720px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 15px;
        }
        ul li{
            font-size: 15px;
        }
        ol li{
            font-size: 15px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
        
    }
    @media screen and (width:650px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 14px;
        }
        ul li{
            font-size: 16px;
        }
        ol li{
            font-size: 16px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 5%;
        }
        
    }
    /* =================================================================================================== */
    @media screen and (width:480px) {
        *{
            margin: 0;
            background-color: #fff;
            line-height: 25px;
        }
        .P-body{
            background-color: #fff;
            padding:0 5%;
        }
        p{
            font-size: 14px;
        }
        ul li{
            font-size: 14px;
        }
        ol li{
            font-size: 14px;
        }
        .P-form  > input  {
            width: 90%;
            background-color: #fff;
            padding: 1%;
            margin-top: 1%;
            font-size: 15px; 
        }
        #top-btn{
            position: fixed;
            right: 10%;
        }
        .P-footer {
        display: grid;
        background-color:  #ef4343;

        }
        .P-footer > Link{
            margin-left: 5%;
        }
        
    }
.body {
    margin-top: 7%;
}
.addButton{
    
    height: 125px;
    width: 125px;
    border-radius: 15px;
    text-align: center;
    margin-left: 45%;
    cursor: pointer;
}
.PriceButton{
    
    height: 25px;
    width: 85px;
    border-radius: 15px;
    text-align: center;
    margin-left: 7%;
    cursor: pointer;
}

.plusL{
    font-size: 70px;

    color: black;
}
.plusTxt{
    color: black;
}
.bookSlides{
    width: 900px;
    height: 350px;
    margin-left: 16.2%;
    margin-top: -5%;

}
@media screen and (max-width: 1024px) {
    .bookSlides{
        width: 105%;
        height: 280px;
        margin-left: -5%;
        margin-top: 10%;
    
    }
    .addButton{
    
        height: 125px;
        width: 125px;
        border-radius: 15px;
        text-align: center;
        margin-left: 30%;
        cursor: pointer;
        margin-top: 30%;
    }
    .body {
        margin-top: -17%;
    }
}
/*----------------------------------------------------
@File: Default Styles
@Author: 
@URL: 
Author E-mail: 

This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
---------------------------------------------------- */
/*=====================================================================
@Template Name: 
@Author: 
@Developed By: 
@Developer URL: 
Author E-mail: 

@Default Styles

Table of Content:
01/ Variables
02/ predefine
03/ header
04/ banner
05/ about
06/ portfolio
07/ team
08/ blog
09/ service
10/ blog
11/ contact 
12/ footer 


=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/


/*Mixins*/
/* Placeholder Mixins*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}
.welcome{
  font-size: 70px;
  background-image: linear-gradient(
    -230deg,
    #fe3f09 0%,
    #fe3f09 29%,
    #ffcec1 67%,
    #f7d9d0 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 7s linear infinite;
          animation: textclip 7s linear infinite;
 
      
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}

.become{
  font-size: 70px;
  margin-left: 30px;
  margin-top: -20px;
}
.become::first-letter{
  font-size: 100px;
  letter-spacing: -3px;

}

body {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: white;
}

:focus {
  outline: none;
}

button:focus {
  outline: none;
}


.logwr{margin-left: 150px;}

.bawr{margin-left: 150px;}
.custom_height {
  height: 100%;
}

.content_right{
  margin-left:615px;
}

#passwr{
  margin-left: 100px;
  width: 350px;
}

.imgwr{margin-left: -115px;}

@media (max-width:1219px){
  .imgwr{Width: 600px;}

  .content_right{
    margin-left:420px;
    margin-top: -30px;
    width: 360px;
  }
  
.welcome{
  font-size: 55px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 110px;}

.bawr{margin-left: 110px;}
#passwr{
  margin-left: 60px;
}

}

@media (max-width:1024px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 55px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 110px;}

.bawr{margin-left: 110px;}
#passwr{
  margin-left: 60px;
}

}
@media (max-width:500px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 30px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 30px;}

.bawr{margin-left: 30px;}
#passwr{
  margin-left: -20px;
}

}

@media (max-width:359px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 24px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: -10px;}

.bawr{margin-left: -10px;}
#passwr{
  margin-left: -20px;
  width: 310px
}

}

@media (max-width:300px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 20px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: -30px;}

.bawr{margin-left: -30px;}
#passwr{
  margin-left: -20px;
  width: 288px
}

}
/* wrapper */
.HIW-body{
    max-width: 95%;
    margin: 4% 2%;
}
/* welcome page */
.HIW_welcome_page{
    margin-top: 10%;
    text-align: center;
  }
.HIW_welcome_page > h1{
    text-shadow: #242424;
    text-transform: uppercase;
    color: #ef4343;
    font-size: 70px;
    font-weight: bolder;
  }
  /* HIW components */
.intro_comp .HIW-nav-btn→{
    margin-left: 90%;
}
.home_comp{    
    text-align: left;
}
.home_comp > h1{
    text-transform: uppercase;
    color: #ef4343;
    font-size: 60px;
    font-weight: 800;
}
.nav_comp > h3,.intro_comp > h3,
.write_comp > h3,.browse_comp > h3,
.feature_comp > h3,.mother_comp > h3,
.home_comp > h3{
    font-weight: 700;
    color: black;
    text-align: left;
    margin-left: 5%;
}

#signImg{
    margin-left: 5%;
    width: 90%;
    height: 70vh;
    box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
    border-radius: 4px;
    margin-bottom: 1%;
}
#signImgb{
    margin-bottom: 1%;
    margin-left: 5%;
    height: 70vh;
    width: 30%;
    box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
    border-radius: 4px;
}
/* HIW Containers */
.HIW-heading{
    width: 100%;
    height: 50vh;
    text-align: center;
    padding: 4% ;
    margin: 1% 4;
    border-radius: 2px;
    
}
.HIW-content{
    overflow: scroll;
    width: 96%;
    height: 80vh;
    padding: 2%;
    box-shadow:0px 4px #ef4343;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2%;
    margin-left: 2%;
}
.HIW-content > h2{
    text-align: center;
    margin-left: 10%;
}
#HIW_list{
    display: list-item;
    list-style-type: none;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
}
.HIW-contentList #HIW-link{
    
    text-decoration: none;
}
#steps{
    margin-left: 5%;
    color:#ef4343;
    font-weight: 700;
}
/* Naviagtion buttons */
#next{
    color: #ef4343;
    text-decoration: none;
}
#back{
    color: #ef4343;
    text-decoration: none;
}
.HIW-nav-btn{
    position: fixed;
    bottom: -1%;
    margin-top: 2%;
    margin-bottom: 5.5%;
    display: flex;
    flex: 100% 1;
}
.HIW-nav-btn← {
    font-size:20px;
    position: fixed;
    left: 10%;
    border-color: #ef4343;
    color:#ef4343;
    border-radius: 20%;
    padding: 0 1%;
}
.HIW-nav-btn→ {
    font-size: 20px;
    position: fixed;
    right: 10%;
    border-color: #ef4343;
    border-radius: 20%;
    color:#ef4343;
    padding: 0 1%;
}
/* Links */
#HIW_links{
    color: #ef4343;
    text-decoration: none;
    font-size: 25px;
    
}
#HIW_links:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    color: #ef4343;
}
/* =================================Media Screens===================================== */
@media screen and (max-width:1080px) {
    #HIW-mobile{
        display: none;
      }
      .HIW-content{
        
        width: 96%;
        height: 80vh;
      }

    .nav_comp > h3,.intro_comp > h3,
    .write_comp > h3,.browse_comp > h3,
    .feature_comp > h3,.mother_comp > h3,
    .home_comp > h3,#steps{
        font-size: 20px;
        margin-bottom: -1%;
    }
    /* popup welcome page */
    .HIW_welcome_page #h1{
        font-size: 25px;
    }
    .HIW_welcome_page #HIW_links{
        font-size: 20px;
    }

      .HIW-nav-btn{
        position: fixed;
        bottom: 8%;
        margin-top: 2%;
        display: flex;
        flex: 100% 1;
    }
    #signImgb{
        text-align: center;
        margin-left: 5%;
        width: 85%;
        height: 90vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
    #signImg{
        display: none;
    }
    .intro_comp .HIW-nav-btn→{
        margin-left: 80%;
    }
    .HIW_welcome_page > h1{
        text-shadow: #242424;
        text-transform: uppercase;
        color: #ef4343;
        font-size: 40px;
        font-weight: bolder;
      }
    .HIW-nav-btn→{
        right: 18%;
    }
    .HIW-nav-btn←{
        left: 18%;
    }

    .HIW-nav-btn→,.HIW-nav-btn← {
        font-size: 15px;
    }
  
}
@media screen and (max-width:720px) {
    #HIW-mobile{
        display: none;
    }
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 85%;
        height: 90vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:650px) {
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:480px) {
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:360px) {
    #signImg{
        display: none;
    } 
    #signImgb{
        align-content: center;
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }  
}
*{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
.HIWpopup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HIWpopup-inner{
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 95%;
    height: 90vh;
    padding-bottom: 5%;
    border-radius: 8px;
    background-color: #fff ;
}
.HIWpopup-inner .HIW-closebtn{
    position:absolute;
    background-color: transparent;
    color: #ef4343;
    border: none;
    font-size:35px;
    top: -0.5%;
    right: 1%;
}
.HIW-closebtn:hover{
    padding: 0px 4px;
    color: red;
    

}
.HIWpopup-inner > img{
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.1;

}

/* ======Media Screen===== */
@media screen and (max-width:1080px) {
  .HIWpopup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .HIWpopup-inner{
    align-items: center;
    position: relative;
    width: 90%;
    height: 90vh;
    margin-bottom: 6%;
    border-radius: 8px;
    background-color: #fff ;
}

}
@media screen and (max-width:720px) {
  
}
@media screen and (max-width:650px) {
  
}
@media screen and (max-width:480px) {
  
}



.button-5 {
  align-items: center;
  background-clip: padding-box;
  background-image: linear-gradient(315deg, #e9e9e9 0%, #f6f6f6 74%);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  border-style: solid;
  border-color: rgb(199, 197, 197);
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}


    .button {
        display: block;
        font-weight: 400;
        line-height: 1.5;
        color: black;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        background-color: rgb(223, 215, 215);
        border-style:solid;
        border-color: rgb(223, 215, 215);
        border-radius: 50%;
        padding: 0.2rem 0.4rem;
        margin-left: 900px;
        margin-top: 10px;
    }
    
  /* .bi-trash3{background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    margin-top:-250px;
     margin-left:-45px;
  } */

  .Move{
      margin-top:-180px;
     
      
    }

    .TextQuote{
   
      margin-left: 50px;
      margin-top: -300px;

    }
.card1{
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    float:right;
    margin-top: -55px;
width: 300px;
display: table-cell;
border-color: rgb(190, 190, 190);
  border-style: solid;
  border-top: none;
border-width: 1px;
}

small{
  font-weight: 5px;

}

 .Qnav{background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9  74%);
width: 1066px;
height: 110px;
border-bottom-color: rgb(190, 190, 190);
  border-bottom-style: solid;
border-bottom-width: 1px;}
  
.Qnavmove{
  display: inline-block;
  
}

.nav-link{Color: black;

}

.ItemQ{
  padding-left: 20px;
}

.figure-caption{position:absolute;
                margin-left: 314px;
                margin-top: -195px;
              text-shadow: 1px 1px black;
              
            }

           #txt{ font-size: 20px;
             color: white;
             word-wrap: break-word;
              max-width:500px;}
              

.imagx{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin-left:275px;
  margin-top: 15px;
  border-radius: 2px;
  border-color: red;
  width: 600px;
  height: 500px;
}

.footer1{
  margin-top: -5px;
  margin-left: 567px;
  background:rgba(0, 0, 0, .06);
   opacity:50%;
    width: 300px;
   color:white;
   
}
.btn.btn-dark.mt-2{margin-left:20%;}


@media (max-width:1265px){
 


  
  .figure{
    margin-left: -20.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}


  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
 
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
    margin-bottom: 5%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:700px){
 

  .figure{
    margin-left: -40.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
 

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
  }
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
    margin-bottom: 5%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:460px){
 

  .figure{
    margin-left: -61.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
 

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
  }
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}


@media (max-width:400px){
 

  .imagx{width:340px;}
  .figure{
    margin-left: -71%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -5%;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}




@media (max-width:370px){


  .imagx{width:340px;}
  .figure{
    margin-left: -78%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
  

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -15px;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
    height:30px;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:354px){

  .figure{
    margin-left: -82%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:310px;
  text-align: center;
}

moment{align-items: center;}

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -15px;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
    height:30px;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
    margin-left: 10%;
  }
  .imagx{
    width:310px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:1024px) {
  #textinput{
   
    height: 120%;
}

#upload-btn{
  width:30%;
}
.icon {
  margin-left: 1%;
  margin-top: -1%;
}
.title {
  display: none;
}
/* #cancel{
  display: none;
} */
.figure-caption{
max-width: 200px;
margin-top:-270px;
left: -8%;
}
.bi-trash3{
  color:white;
}
.likes{
  color:yellow;
  margin:5px;
  font-size: 16px;

}
#uploadbtn{
 min-width: 40%;

}
#selected{
  margin-top: -10%;
  
}
#photoinput{
  height: 5%;
}
.label{
  display: none;
}
#sendbutton{
  margin-top: -5%;
  margin:3%;
}
.figure{
  margin-left: -136%;
}
.button{
  margin-left: 90%;
  
}
.Move{
  margin-top: -17%;
}

}


.nav {
  transition: all 0.1s linear;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top:0;
  z-index: 2000;
  box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
  width: 100%;
}
.showMobile{
  display: none;
}

.Bsize{
 width: 150px;
 height: 35px;

}
.Fsize{
  font-size: 15px;
  margin-top: -15px;
}
.scrollNav {
  transition: all 0.5s ease-in;
  z-index: 2000;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #dddddd;
}
.styl {
  padding-top: 80px;
}
.btn--outline{
  margin-top: -25px;
}

.prem{
background-color: #ef4343;
background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);

  border-radius:20px;
}
/* Avator styling  */
.avator{
vertical-align: middle;
width: 35px;
height: 30px;
border-radius: 50%;
border-width: 2px;
border-color: gray;
border-style: outset;
}
#mobileNav{
position: fixed;
z-index: 999;
top: 0;
width: 100%;
}

/* Avator styling  */
.avator2{
  vertical-align: middle;
  width: 35px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-color: gray;
  border-style: outset;
  z-index: -999;
  }
  

a.nav-links-mobile.sign{
  color:#020202;
}
.fa-bars{
  color:#000000 ;

 
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  max-width: auto;
  
}


.navbar-logo {
   color: #ef4343; 
  justify-self: start;
  margin-left: 20px;

  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-item{
  font-size: 15px;
  margin-left: -20px;


}
.nav-item2{
  font-size: 15px;
  margin-left: -20px;
display: none;

}
.nav-user{
  font-size: 15px;
  margin-left: -20px;
  display: none;

}
.nav-userd{
  font-size: 17px;
  margin-right: 500px;
  position:absolute;
  background-color: #ef4343;
  background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
 

}
.nav-logout{
  font-size: 17px;
  right: 20px;
  position:absolute;
 color: #ef4343;
  color: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
 

}
a.nav-links{
  text-decoration: none;
  -webkit-text-decoration-line:none;
          text-decoration-line:none;
  color: black;
}
.nav-links:hover  {
  text-decoration: none;
  color: #ef4343;
  cursor: pointer;

  
}

.nav-links:before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0%;
  background-color: #ef4343;
  background-image: linear-gradient(45deg, #e3c664 0%, #ef4343 50%, #b21818 100%);
  
  border-radius: 12px;
  transition: all 0.4s ease;
}
.nav-links:hover:before{
  width: 80%;
}
.nav-links:before{
  left: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.nav-links:before{
  width: 100%;
  bottom: -5px;
  opacity: 0;
}
.nav-links:hover:before{
  bottom: 0px;
  opacity: 1;
}
.nav-links:before{
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.nav-links:hover:before{
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left;
          transform-origin: left;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: left;
  width: 60vw;
  justify-content: end;
  margin-right: 15rem;
  z-index: 2000;
}

.nav-links {
 color:#000000;
  font-weight:bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 135%;
}

.menu-icon {
  display: none;
}

.nav-links {
  text-decoration: none;
  transition: all 0.2s ease-out;
}
svg.sc-bdvvtL.cmlAWX.feather.feather-moon {
  margin-top: -6.5px;
  height: 25px;
  width:25px;

}

svg.sc-bdvvtL.cmlAWX.feather.feather-sun {
  margin-top: -6.5px;
  height: 25px;
  width:25px;
 
}


.nav-links-mobile {
  display: none;
  
}


.menu-icon {
  display: none;
}
/* tesla fixed the movement of the side menu wen screens are changed in size */


/* tesla fixed the movement of the side menu wen screens are changed in size */
@media screen and (max-width: 1080px) {
  .nav {
    width: 100%;
 
  
}

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: inline-table;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    position: absolute;
    top: 0%;
    
    padding-top: -20px;
    left: -100%;
  }

  .nav-menu.active {
    background: #ffffff;
    color:#000000;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
   
  }
 
  /* padding of the mobile nav menu */
  .nav-links {
    text-align: center;
    padding: 8px;
    width: 100%;
    display: table;
    color: #000000; 

  }


  

  .navbar-logo {
    position: absolute;
    top: 0;
    margin-top: -15px;
    margin-right: 92%;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }
  .showMobile{
    display: flex;
  }
  .showDesktop{
    display: none;
  }

  
  .menu-icon {
    display: block;
    position: absolute;
    top: -0px;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: -19px;
  }

  .fa-times {
     color: #fff; 
    font-size: 2rem;
  }

  .nav-links-mobile {
    display:ruby-base;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  .nav-user{
    font-size: 15px;
    margin-inline: 10;
    margin-left: -20px;
    display: inline-block;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .nav-logout{
display: none;
   
  
  }
  .nav-item2{
    font-size: 15px;
  display: flex;
  
  }
  .nav-item{
    font-size: 15px;
  display: flex;

  
  }
  
}




.dropMt {
  background: white;
    width: 400px;
    height: 140px;
    position: absolute;
    top: 60px;
   margin-left: -100px;
    list-style: none;
    border-radius: 15px;
    border-style: solid;
    border-color: #ef4343;
  
  }
  
  .dropMt li {
    width: 170px;
    top:25px;
    cursor: pointer;
    display: inline;
    
    
  
  
  }
/*   
.spacing{
margin: 10px;
} */
  
  .dropMt.clicked {
    display: none;
  }
  
  .dropLt {
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    padding: 16px;
    font-size: 16px;
  }
  .dropLt:hover {
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    padding: 16px;
    text-decoration: none;
    color: black;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
  
.custom-modal{
    width:30%
}
.bottom-section{
    /* width:62% */
    width:20vw;
}
.card2{
    margin-top: -55px;
    float: right;
    width: 300px;
display: table-cell;
border-color: rgb(190, 190, 190);
  border-style: solid;
  border-top: none;
border-width: 1px;
}

#bi-quote2{
  position: absolute;
  margin-top: -14px;
  margin-left: 170px;
  border-style: solid;
  border-radius: 50%;
}

.but2{
  border-style: solid;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  border-color:rgb(255, 255, 255);
  padding: 4px;
  margin-top: 5px;
  margin-left: 9px;
}

.Move2{
  position:absolute;
  margin-left: 600px;
  margin-top:-85px;

}


#Inputtextq{
  width: 400px;
   height: calc(var(--variable-height));
  margin-top: -181px;
   margin-left: 400px;
  border-bottom-style: solid;
    border-Bottom-Width: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.shadow-lg1{
  margin-top:1%;
  width:400px;
  margin-left:29%;
  height:5%;}

  .Qnavmove{
    margin-top: 68px;
  }

  .color1{ border-top-left-radius: 5px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color: #87CEFA;
    border-Bottom-Color: #87CEEB;
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
font-size: 15px; }

.bi.bi-quote2{
  border-style: solid;
  border-radius: 50%;
  background-color: #87CEEB;
  border-color: #87CEEB;

}
  .color2{font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#D8BFD8;
    border-Bottom-Color:#DDA0DD; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote3{
    border-style: solid;
    border-radius: 50%;
    background-color: #DDA0DD;
    border-color: #DDA0DD;
  }

  

  .color3{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#FFC72C;
    border-Bottom-Color:#FFD700; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote4{
    border-style: solid;
    border-radius: 50%;
    background-color: #FFD700;
    border-color: #FFD700;
  
  }

  .color4{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#90EE90;
    border-Bottom-Color:#50C878; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }
  .bi.bi-quote5{
    border-style: solid;
    border-radius: 50%;
    background-color: #50C878;
    border-color: #50C878;
  
  }
  .color5{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#ED2939;
    border-Bottom-Color:#e41c38; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote6{
    border-style: solid;
    border-radius: 50%;
    background-color: #e41c38;
    border-color: #e41c38;
  
  }

  .color6{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:pink;
    border-Bottom-Color:lightpink; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote7{
    border-style: solid;
    border-radius: 50%;
    background-color: lightpink;
    border-color: lightpink;
  
  }

  .color7{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: 0px;
    background-color:#ff4f00;
    border-Bottom-Color:#ff4500; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .quo{
    margin-top: -14px;
  margin-left: 170px;
  border-style: solid;
  border-radius: 50%;
  background-color: grey;
    border-color: grey;}

    

  .cont {
    width: 250px;
    border-bottom: grey;
    margin-top: 50px;
    margin-left: 150px;
    background: darkgrey;
  }

  
 
  .cont2 {
    background-color: #87CEFA;
   border-bottom-style: solid;
  border-bottom-color:#62bfe4;
  border-width: 60px;
  border-left: none;
  border-right: none;
  border-top: none;
  }
.quo2{
      border-style: solid;
      border-radius: 50%;
      background-color: #62bfe4;
      border-color: #62bfe4;

    }
  .cont3 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #D8BFD8;
    border-bottom-style: solid;
    border-bottom-color:#DDA0DD;
    
  }
  .quo3{
    border-style: solid;
    border-radius: 50%;
    background-color: #DDA0DD;
    border-color: #DDA0DD;

  }
  .cont4 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #FFC72C;
    border-bottom-style: solid;
    border-bottom-color:#FFD700;
  }

  .quo4{
    border-style: solid;
    border-radius: 50%;
    background-color: #FFD700;
    border-color: #FFD700;

  }

  .cont5 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #90EE90;
    border-bottom-style: solid;
    border-bottom-color:#50C878;
  }
  .quo5{
    border-style: solid;
    border-radius: 50%;
    background-color: #50C878;
    border-color: #50C878;

  }
  .cont6 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #ED2939;
    border-bottom-style: solid;
    border-bottom-color:#e41c38;
  }
  .quo6{
    border-style: solid;
    border-radius: 50%;
    background-color: #e41c38;
    border-color: #e41c38;

  }
  .cont7 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color:pink;
    border-bottom-style: solid;
    border-bottom-color:lightpink;
  }
  .quo7{
    border-style: solid;
    border-radius: 50%;
    background-color: lightpink;
    border-color: lightpink;

  }
  .cont8 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color:#ff4f00;
    border-bottom-style: solid;
    border-bottom-color:#ff4500;
  }
  .quo8{
    border-style: solid;
    border-radius: 50%;
    background-color: #ff4500;
    border-color: #ff4500;

  }
  .bi.bi-quote8{
    border-style: solid;
    border-radius: 50%;
    background-color: #ff4500;
    border-color: #ff4500;
  
  }

  .rainbow{
    width: 10px;
    margin-left: 360px;
    margin-top: 30px;
    
  }

  
@media (max-width:1265px){

  
  .color1{ border-top-left-radius: 0px;}

  .itemQ{margin-left: -30px;
    margin-top: -60px;}
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .bi.bi-trash3{
    color: black;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
 
}

@media (max-width:900px){

  
  .color1{ border-top-left-radius: 0px;}

  .bi.bi-trash3{
    color: black;
   
  }
 .but2{
   padding: -10px;
  
 }
  .card2{
    width: 230px;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
   
  .Move2{
    margin-left: 110px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

}

@media (max-width:700px){

  
  .color1{ border-top-left-radius: 0px;}

  .bi.bi-trash3{
    color: black;
  }

  .card2{
    width: 190px;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  /* .bi.bi-quote{
    margin-left: 150px;
  } */

}
 
@media (max-width:665px){

  
  .color1{ border-top-left-radius: 0px;}
  
  .bi.bi-trash3{
    color: black;
  }

  .card2{
    display: none;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 320px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .rainbow{
    width: 10px;
    margin-left: 340px;
    margin-top: 40px;
    
  }
}



@media (max-width:400px){

  .bi.bi-trash3{
    color: black;
  }

  .card2{
    display: none;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 320px;
     border-top-right-radius: 0px;
  border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .bi.bi-trash3{
    color: black;
  }

  .rainbow{
    width: 10px;
    margin-left: 330px;
    margin-top: 30px;
    
  }

  .color1{ border-top-left-radius: 0px;}
 
}




@media (max-width:370px){


.card2{
  display: none;
}

#Inputtextq{
  margin-left: 10px;
  width: 290px;
  border-top-right-radius: 0px;
  border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
 
.Move2{
  margin-left: 140px;
}

.shadow-lg{
  width: 320px;
  margin-left: 3%;
}

.bi.bi-quote{
  margin-left: 150px;
}



.bi.bi-trash3{
  color: black;
}


.rainbow{
  width: 10px;
  margin-left: 300px;
  margin-top: 30px;
  
}

.color1{ border-top-left-radius: 0px;}

}


 @media (max-width:1024px){
.bi-chat-quote-fill{
  margin:50px 50px 50px 50px;

}
  
 }



 


      
.Qnav2{background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9  74%);
  width: 1066px;
  height: 110px;
  border-bottom-color: rgb(190, 190, 190);
    border-bottom-style: solid;
  border-bottom-width: 1px;}
    
  .Qnavmove2{
    display: inline-block;
    
  }
  
  .nav-link{Color: black;
  
  }
  
  .ItemQ2{
    padding-left: 20px;
  }
  .Qnavmove2{
    margin-top: 68px;
  }
  @media (max-width:1024px){
    .itemQ2{margin-left: -400px;
     }
 
     .Qnavmove2{
        display: inline-flex;
        
      }
    }

.row{
  margin-top: 2%;

}
.Movetile{
margin-left: 4%;
font-size: 15px;

}
.rowPosters{
  display: flex;
  margin-top:-6%;
  overflow-y:hidden;
  overflow-x: scroll;
  padding: 20px;
  padding-top: 100px;
}
.rowPosterB{
  -webkit-animation: slide infinite 3s alternate;
          animation: slide infinite 3s alternate;
}
 
 .rowPosterB:hover
{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
@-webkit-keyframes slide {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: -100px;
  }
  100% {
    margin-left: -200px;
  }
}
@keyframes slide {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: -100px;
  }
  100% {
    margin-left: -200px;
  }
}
.rowPoster{
  width: 100px;
  height: 150px;
  margin-right: 10px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}
.rowPosterB{
  width: 400px;
  height: 250px;
  margin-right: 10px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}

.rowPoster:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}
@media screen and (max-width: 1024px) {
  
  .row{
      margin-top: 2%;
      overflow-y:hidden;

  }
  .rowPosters{

      padding-top: 22px;
  }
  .rowPosters::-webkit-scrollbar{
    display: none;
  }
  .rowPosterB{
    width: 320px;
    height: 220px;
    margin-right: 10px;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;

  }
}
*{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar,:-moz-any {
    display: none;
  }
  /* ===============Inventor Main Class=============== */
  .main-box{
      box-shadow: 1px 3px 6px 1px  darkgray;
      margin: 0% 15%;
      padding: 2% 2%;
      margin-top: -8%;
  }
  .inventor-container{
      margin-top: 5%;
  }

  /* ===================TestInventor Main Css================= */
  .inventor-class{
    
    margin-bottom: 1%;
    
}
.inventor-bg{
    border-radius: 4px;
    border: solid;
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
}
.avator-class{
    padding-top: 2%;
    display: flex;
}

.inventor-pic{
    width: 25%;
    height: 35vh;           
    border-radius: 50%;
    margin:5% 2%;
    margin-left: 4%;
}

.inventor-title{
    margin-left: 10%;
    text-align: center;
}
.inventor-title h1{
    margin-top: 20%;
    font-weight: 900;
}
.add-bio{
    margin-top: 3%;
    display: flex;
}
.add-bio button{
    border-color: #dd541e;
    color: #fff;
    width: 100px;
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    font-size: 15px;
    border-radius: 4px;
    padding: 0% 1%;
    margin-left: 0%;
    margin-right: 65%;
}
.add-bio button:hover{
    cursor: pointer;
}

.inventor-bg button{
    border-color: #fff;
    color: #fff;
    width: 100px;
    background-color: transparent;
    font-size: 15px;
    border-radius: 4px;
    padding: 0% 1%;
    margin-top: 3%;
    margin-bottom: 4%;
    margin-left: 12%;
}

.inventor-details{
    display: block;
}

.details-para{
    padding-top: 0%;
}

.details-para p{
    font-size: 17px;
    text-justify: center;
    overflow-y: scroll;
    /* font-family: Arial, Helvetica, sans-serif; */
}
.details-persona{
    margin: 5% 0;
}
.details-persona h5{
    font-size: large;
}
/* =================================paragraph pic && paragraphs================================== */
.para-pic{
    width: 95%;
    height: 60vh;
    margin:1%;
}
.alt-para-pic{
    display:none;
    width: 0%;
    height: 0;
}
.fa{
    position: relative;   
}

/* =====================================Meadi Screens============================== */
@media screen and(max-width: 1080px) {
    .inventor-class{
        margin: 2% 12%;
        margin-bottom: 5%;
    }
    .inventor-bg{
        border-radius: 4px;
        border: solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        padding-top: 5%;
        display: flex;
    }
    
    .inventor-pic{
        width: 30%;
        height: 32vh;           
        border-radius: 50%;
        margin: 2%;
        margin-left: 4%;
    }
    
    .inventor-title{
        margin-left: 10%;
        text-align: center;
    }
    .inventor-title h1{
        margin-top: 30%;
        font-weight: 900;
    }
    
    .add-bio button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 12%;
    }
    
    .inventor-details{
        display: block;
        flex-direction: grid;
        grid-gap: 5%;
        margin-top: 3%;
    }
    
    .details-para{
        padding-top: 5%;
    }
    
    .details-para p{
        font-size: 17px;
        overflow-y: scroll;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h5{
        font-size: large;
    }
    
}
/* ====================820px====================== */
@media screen and (max-width:820px) {
    .main-box{
        box-shadow: 1px 3px 6px 1px  darkgray;
        margin: 0% 0%;
        padding: 0;
        margin-top: 0%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .add-bio button{
        border-color: #dd541e;
        color: #fff;
        width: 100px;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-left: 3%;
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 35%;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 17px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* =====================720px===================== */
@media screen and (max-width:720px) {
    .main-box{
        box-shadow: 1px 3px 6px 1px  darkgray;
        margin: 0% 0%;
        padding: 0;
        margin-top: -5%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 0%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 5%;
    }
    
    .inventor-pic{
        width: 40%;
        height: 50vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 16px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
  /* =============paragraph images================== */
  .para-img, .para-img2{
    display: block;
   }   
}
/* ========================650px============================ */
@media screen and (max-width:650px) {
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 2%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 45%;
        height: 47vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 16px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* ========================480px========================= */
@media screen and (max-width:480px) {
    .main-box{margin-top: 2%;}
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 40%;
        height: 35vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .edit-bio button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 15px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* ===================360px================================== */
@media screen and (max-width:360px) {
    .main-box{
        margin-top: 7%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 45%;
        height: 30vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 15px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-pic{
        width: 100%;
        height: 60;
        margin:1%;
    }
    .para-img, .para-img2{
        display: block;
    }   
}
.afriInv-container{
    margin: 0 20%;
    padding: 0 2%;
    margin-top: -5%;
}
.form-box{
    padding: 4% 4%;
    box-shadow: 1px 3px 6px 1px rgb(66, 65, 65);
}
.form-box h1{
    font-size: 50px;
    text-align: center;
}
.inv-btn{
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    color: #fff;
}
.inv-btn:hover{
    background-image: linear-gradient(45deg, #f88d63 0%, #fd6969 50%, #f70c0c 100%);
    color: #fff;
}

/* =================media Screens=================== */
@media screen and (max-width:1080px) {
    .afriInv-container{
        margin: 0 20%;
        padding: 0 2%;
        margin-top: -5%;
    }
    .form-box{
        padding: 4% 4%;
        box-shadow: 1px 3px 6px 1px rgb(66, 65, 65);
    }
    .form-box h1{
        font-size: 50px;
        text-align: center;
    }
}
@media screen and (max-width:820px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 40px;
    }
}
@media screen and (max-width:720px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 35px;
    }
}
@media screen and (max-width:650px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 35px;
    }
}
@media screen and (max-width:480px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 30px;
    }
}
@media screen and (max-width:360px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 30px;
    }
}
*{
    margin: 0;
}
.style2{
    margin-left: 750px;
    max-width: 410px;
     margin-top: 80px; 
}

#inter{
    width: 100px;

}

.nxt{
    width: 200px;
    margin-left: 100px;
}

 @media (max-width:1150px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 200px;
    }

    
  
 
  }

  @media (max-width:411px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 100px;
        margin-left: 0px;
    }

    
  
 
  }
  @media (max-width:397px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 100px;
        margin-left: 0px;
    }

  
 
  }
  
  
#map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  @media (min-width: 768px) {
    .news-input {
      width: 50%;
    }
  }
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: #ef4343;
    border-color: #fff;
}



