
.row{
  margin-top: 2%;

}
.Movetile{
margin-left: 4%;
font-size: 15px;

}
.rowPosters{
  display: flex;
  margin-top:-6%;
  overflow-y:hidden;
  overflow-x: scroll;
  padding: 20px;
  padding-top: 100px;
}
.rowPosterB{
  animation: slide infinite 3s alternate;
}
 
 .rowPosterB:hover
{
    animation-play-state: paused;
}
@-webkit-keyframes slide {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: -100px;
  }
  100% {
    margin-left: -200px;
  }
}
@keyframes slide {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: -100px;
  }
  100% {
    margin-left: -200px;
  }
}
.rowPoster{
  width: 100px;
  height: 150px;
  margin-right: 10px;
  transition: transform 450ms;
}
.rowPosterB{
  width: 400px;
  height: 250px;
  margin-right: 10px;
  transition: transform 450ms;
}

.rowPoster:hover {
  transform: scale(1.09);
}
@media screen and (max-width: 1024px) {
  
  .row{
      margin-top: 2%;
      overflow-y:hidden;

  }
  .rowPosters{

      padding-top: 22px;
  }
  .rowPosters::-webkit-scrollbar{
    display: none;
  }
  .rowPosterB{
    width: 320px;
    height: 220px;
    margin-right: 10px;
    transition: transform 450ms;

  }
}