.backgb {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: -1;
  overflow-x: hidden;


}

 video {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: -1;
} 

.hero-container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.hero-container > h1 {
  color: #ef4343;
  font-size: 100px;
  margin-top: -100px;
  text-align: center;
}

.herobutton{
  background-color: #ffff;
  width: 140px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  border: 2px solid #ffff;
  margin:0 auto 60px;
  float: inline-end;
  padding:9px 0;
  clear:left;
  
  
}
.herobutton:hover{
 text-decoration: none;
 
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}
.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 650px) {
  .hero-container > h1 {
    font-size: 40px;
    text-align: center;
  }
  .hero-container > p {
    font-size: 25px;
    text-align: center;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}
.typewriter {
  font-family: Courier, monospace;
display: inline-block;
}

.typewriter-text {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2px;
 animation: typing 5s steps(30), blink .75s step-end infinite;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-right: 4px solid #fff;
  box-sizing: border-box;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: forward;
}

@keyframes typing {
  from { 
      width: 0% 
  }
  to { 
      width: 100% 
  }
}

@keyframes blink {
  from, to { 
      border-color: transparent 
  }
  50% { 
      border-color: #fff; 
  }
}
