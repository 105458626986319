
      
.Qnav2{background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9  74%);
  width: 1066px;
  height: 110px;
  border-bottom-color: rgb(190, 190, 190);
    border-bottom-style: solid;
  border-bottom-width: 1px;}
    
  .Qnavmove2{
    display: inline-block;
    
  }
  
  .nav-link{Color: black;
  
  }
  
  .ItemQ2{
    padding-left: 20px;
  }
  .Qnavmove2{
    margin-top: 68px;
  }
  @media (max-width:1024px){
    .itemQ2{margin-left: -400px;
     }
 
     .Qnavmove2{
        display: inline-flex;
        
      }
    }