.body {
    margin-top: 7%;
}
.addButton{
    
    height: 125px;
    width: 125px;
    border-radius: 15px;
    text-align: center;
    margin-left: 45%;
    cursor: pointer;
}
.PriceButton{
    
    height: 25px;
    width: 85px;
    border-radius: 15px;
    text-align: center;
    margin-left: 7%;
    cursor: pointer;
}

.plusL{
    font-size: 70px;

    color: black;
}
.plusTxt{
    color: black;
}
.bookSlides{
    width: 900px;
    height: 350px;
    margin-left: 16.2%;
    margin-top: -5%;

}
@media screen and (max-width: 1024px) {
    .bookSlides{
        width: 105%;
        height: 280px;
        margin-left: -5%;
        margin-top: 10%;
    
    }
    .addButton{
    
        height: 125px;
        width: 125px;
        border-radius: 15px;
        text-align: center;
        margin-left: 30%;
        cursor: pointer;
        margin-top: 30%;
    }
    .body {
        margin-top: -17%;
    }
}