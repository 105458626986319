*{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar,:-moz-any {
    display: none;
  }
  /* ===============Inventor Main Class=============== */
  .main-box{
      box-shadow: 1px 3px 6px 1px  darkgray;
      margin: 0% 15%;
      padding: 2% 2%;
      margin-top: -8%;
  }
  .inventor-container{
      margin-top: 5%;
  }

  /* ===================TestInventor Main Css================= */
  .inventor-class{
    
    margin-bottom: 1%;
    
}
.inventor-bg{
    border-radius: 4px;
    border: solid;
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
}
.avator-class{
    padding-top: 2%;
    display: flex;
}

.inventor-pic{
    width: 25%;
    height: 35vh;           
    border-radius: 50%;
    margin:5% 2%;
    margin-left: 4%;
}

.inventor-title{
    margin-left: 10%;
    text-align: center;
}
.inventor-title h1{
    margin-top: 20%;
    font-weight: 900;
}
.add-bio{
    margin-top: 3%;
    display: flex;
}
.add-bio button{
    border-color: #dd541e;
    color: #fff;
    width: 100px;
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    font-size: 15px;
    border-radius: 4px;
    padding: 0% 1%;
    margin-left: 0%;
    margin-right: 65%;
}
.add-bio button:hover{
    cursor: pointer;
}

.inventor-bg button{
    border-color: #fff;
    color: #fff;
    width: 100px;
    background-color: transparent;
    font-size: 15px;
    border-radius: 4px;
    padding: 0% 1%;
    margin-top: 3%;
    margin-bottom: 4%;
    margin-left: 12%;
}

.inventor-details{
    display: block;
}

.details-para{
    padding-top: 0%;
}

.details-para p{
    font-size: 17px;
    text-justify: center;
    overflow-y: scroll;
    /* font-family: Arial, Helvetica, sans-serif; */
}
.details-persona{
    margin: 5% 0;
}
.details-persona h5{
    font-size: large;
}
/* =================================paragraph pic && paragraphs================================== */
.para-pic{
    width: 95%;
    height: 60vh;
    margin:1%;
}
.alt-para-pic{
    display:none;
    width: 0%;
    height: 0;
}
.fa{
    position: relative;   
}

/* =====================================Meadi Screens============================== */
@media screen and(max-width: 1080px) {
    .inventor-class{
        margin: 2% 12%;
        margin-bottom: 5%;
    }
    .inventor-bg{
        border-radius: 4px;
        border: solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        padding-top: 5%;
        display: flex;
    }
    
    .inventor-pic{
        width: 30%;
        height: 32vh;           
        border-radius: 50%;
        margin: 2%;
        margin-left: 4%;
    }
    
    .inventor-title{
        margin-left: 10%;
        text-align: center;
    }
    .inventor-title h1{
        margin-top: 30%;
        font-weight: 900;
    }
    
    .add-bio button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 12%;
    }
    
    .inventor-details{
        display: block;
        flex-direction: grid;
        grid-gap: 5%;
        margin-top: 3%;
    }
    
    .details-para{
        padding-top: 5%;
    }
    
    .details-para p{
        font-size: 17px;
        overflow-y: scroll;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h5{
        font-size: large;
    }
    
}
/* ====================820px====================== */
@media screen and (max-width:820px) {
    .main-box{
        box-shadow: 1px 3px 6px 1px  darkgray;
        margin: 0% 0%;
        padding: 0;
        margin-top: 0%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .add-bio button{
        border-color: #dd541e;
        color: #fff;
        width: 100px;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-left: 3%;
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 35%;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 17px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* =====================720px===================== */
@media screen and (max-width:720px) {
    .main-box{
        box-shadow: 1px 3px 6px 1px  darkgray;
        margin: 0% 0%;
        padding: 0;
        margin-top: -5%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 0%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 5%;
    }
    
    .inventor-pic{
        width: 40%;
        height: 50vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 16px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
  /* =============paragraph images================== */
  .para-img, .para-img2{
    display: block;
   }   
}
/* ========================650px============================ */
@media screen and (max-width:650px) {
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 2%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 45%;
        height: 47vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 16px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* ========================480px========================= */
@media screen and (max-width:480px) {
    .main-box{margin-top: 2%;}
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 40%;
        height: 35vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .edit-bio button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 15px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-img, .para-img2{
        display: block;
    }
}
/* ===================360px================================== */
@media screen and (max-width:360px) {
    .main-box{
        margin-top: 7%;
    }
    .inventor-class{
        margin: 0% 3%;
        margin-bottom: 0%;
    }
    .inventor-bg{
        margin-top: 5%;
        border-radius: 4px;text-align: center;
        border:  solid;
        background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    }
    .avator-class{
        display: block;
        padding-top: 10%;
    }
    
    .inventor-pic{
        width: 45%;
        height: 30vh;
        border-radius: 50%;
        margin: 5%;
    }
    
    .inventor-title{
        margin-left: 0%;
        text-align: center;
        margin-top: -15%;
    }
    .inventor-title h1{
        font-weight: 800;
        font-size:x-large;
    }
    .inventor-title h2{
        font-weight: 800;
        font-size:large;
    }
    
    .inventor-bg button{
        border-color: #fff;
        color: #fff;
        width: 100px;
        background-color: transparent;
        font-size: 15px;
        border-radius: 4px;
        padding: 0% 1%;
        margin-top: 3%;
        margin-bottom: 4%;
        margin-left: 0%;
    }
    .inventor-details{
        display: block;
    }
   
    .details-persona{
        width: 100%;
        margin-left: 0;
    }
    .details-para p{
        font-size: 15px;
        overflow-y: scroll;
        width: 100%;
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .details-persona h3, .details-para h3{
        font-size: larger;
    }

    .inventor-social{
        text-align: center;
    }
    /* =============paragraph images================== */
    .para-pic{
        width: 100%;
        height: 60;
        margin:1%;
    }
    .para-img, .para-img2{
        display: block;
    }   
}