.card2{
    margin-top: -55px;
    float: right;
    width: 300px;
display: table-cell;
border-color: rgb(190, 190, 190);
  border-style: solid;
  border-top: none;
border-width: 1px;
}

#bi-quote2{
  position: absolute;
  margin-top: -14px;
  margin-left: 170px;
  border-style: solid;
  border-radius: 50%;
}

.but2{
  border-style: solid;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  border-color:rgb(255, 255, 255);
  padding: 4px;
  margin-top: 5px;
  margin-left: 9px;
}

.Move2{
  position:absolute;
  margin-left: 600px;
  margin-top:-85px;

}


#Inputtextq{
  width: 400px;
   height: calc(var(--variable-height));
  margin-top: -181px;
   margin-left: 400px;
  border-bottom-style: solid;
    border-Bottom-Width: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.shadow-lg1{
  margin-top:1%;
  width:400px;
  margin-left:29%;
  height:5%;}

  .Qnavmove{
    margin-top: 68px;
  }

  .color1{ border-top-left-radius: 5px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color: #87CEFA;
    border-Bottom-Color: #87CEEB;
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
font-size: 15px; }

.bi.bi-quote2{
  border-style: solid;
  border-radius: 50%;
  background-color: #87CEEB;
  border-color: #87CEEB;

}
  .color2{font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#D8BFD8;
    border-Bottom-Color:#DDA0DD; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote3{
    border-style: solid;
    border-radius: 50%;
    background-color: #DDA0DD;
    border-color: #DDA0DD;
  }

  

  .color3{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#FFC72C;
    border-Bottom-Color:#FFD700; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote4{
    border-style: solid;
    border-radius: 50%;
    background-color: #FFD700;
    border-color: #FFD700;
  
  }

  .color4{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#90EE90;
    border-Bottom-Color:#50C878; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }
  .bi.bi-quote5{
    border-style: solid;
    border-radius: 50%;
    background-color: #50C878;
    border-color: #50C878;
  
  }
  .color5{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:#ED2939;
    border-Bottom-Color:#e41c38; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote6{
    border-style: solid;
    border-radius: 50%;
    background-color: #e41c38;
    border-color: #e41c38;
  
  }

  .color6{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: -10px;
    background-color:pink;
    border-Bottom-Color:lightpink; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .bi.bi-quote7{
    border-style: solid;
    border-radius: 50%;
    background-color: lightpink;
    border-color: lightpink;
  
  }

  .color7{
    font-size: 15px;
    height: 25px; 
    width: 40px;
    margin-top: 0px;
    background-color:#ff4f00;
    border-Bottom-Color:#ff4500; 
    border-Bottom-Width: 13px;
 border-left: none;
border-right: none;
border-top: none;
  }

  .quo{
    margin-top: -14px;
  margin-left: 170px;
  border-style: solid;
  border-radius: 50%;
  background-color: grey;
    border-color: grey;}

    

  .cont {
    width: 250px;
    border-bottom: grey;
    margin-top: 50px;
    margin-left: 150px;
    background: darkgrey;
  }

  
 
  .cont2 {
    background-color: #87CEFA;
   border-bottom-style: solid;
  border-bottom-color:#62bfe4;
  border-width: 60px;
  border-left: none;
  border-right: none;
  border-top: none;
  }
.quo2{
      border-style: solid;
      border-radius: 50%;
      background-color: #62bfe4;
      border-color: #62bfe4;

    }
  .cont3 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #D8BFD8;
    border-bottom-style: solid;
    border-bottom-color:#DDA0DD;
    
  }
  .quo3{
    border-style: solid;
    border-radius: 50%;
    background-color: #DDA0DD;
    border-color: #DDA0DD;

  }
  .cont4 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #FFC72C;
    border-bottom-style: solid;
    border-bottom-color:#FFD700;
  }

  .quo4{
    border-style: solid;
    border-radius: 50%;
    background-color: #FFD700;
    border-color: #FFD700;

  }

  .cont5 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #90EE90;
    border-bottom-style: solid;
    border-bottom-color:#50C878;
  }
  .quo5{
    border-style: solid;
    border-radius: 50%;
    background-color: #50C878;
    border-color: #50C878;

  }
  .cont6 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color: #ED2939;
    border-bottom-style: solid;
    border-bottom-color:#e41c38;
  }
  .quo6{
    border-style: solid;
    border-radius: 50%;
    background-color: #e41c38;
    border-color: #e41c38;

  }
  .cont7 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color:pink;
    border-bottom-style: solid;
    border-bottom-color:lightpink;
  }
  .quo7{
    border-style: solid;
    border-radius: 50%;
    background-color: lightpink;
    border-color: lightpink;

  }
  .cont8 {
    width: 250px;
    margin-top: 50px;
    margin-left: 150px;
    background-color:#ff4f00;
    border-bottom-style: solid;
    border-bottom-color:#ff4500;
  }
  .quo8{
    border-style: solid;
    border-radius: 50%;
    background-color: #ff4500;
    border-color: #ff4500;

  }
  .bi.bi-quote8{
    border-style: solid;
    border-radius: 50%;
    background-color: #ff4500;
    border-color: #ff4500;
  
  }

  .rainbow{
    width: 10px;
    margin-left: 360px;
    margin-top: 30px;
    
  }

  
@media (max-width:1265px){

  
  .color1{ border-top-left-radius: 0px;}

  .itemQ{margin-left: -30px;
    margin-top: -60px;}
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .bi.bi-trash3{
    color: black;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
 
}

@media (max-width:900px){

  
  .color1{ border-top-left-radius: 0px;}

  .bi.bi-trash3{
    color: black;
   
  }
 .but2{
   padding: -10px;
  
 }
  .card2{
    width: 230px;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
   
  .Move2{
    margin-left: 110px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

}

@media (max-width:700px){

  
  .color1{ border-top-left-radius: 0px;}

  .bi.bi-trash3{
    color: black;
  }

  .card2{
    width: 190px;
  }

  .rainbow{
    width: 10px;
    margin-left: 430px;
    margin-top: 30px;
    
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 400px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  /* .bi.bi-quote{
    margin-left: 150px;
  } */

}
 
@media (max-width:665px){

  
  .color1{ border-top-left-radius: 0px;}
  
  .bi.bi-trash3{
    color: black;
  }

  .card2{
    display: none;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 320px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .rainbow{
    width: 10px;
    margin-left: 340px;
    margin-top: 40px;
    
  }
}



@media (max-width:400px){

  .bi.bi-trash3{
    color: black;
  }

  .card2{
    display: none;
  }
  
  .Inputtextq{
    margin-left: 10px;
    width: 320px;
     border-top-right-radius: 0px;
  border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
   
  .Move2{
    margin-left: 140px;
  }
  
  .shadow-lg{
    width: 320px;
    margin-left: 3%;
  }
  
  .bi.bi-quote{
    margin-left: 150px;
  }

  .bi.bi-trash3{
    color: black;
  }

  .rainbow{
    width: 10px;
    margin-left: 330px;
    margin-top: 30px;
    
  }

  .color1{ border-top-left-radius: 0px;}
 
}




@media (max-width:370px){


.card2{
  display: none;
}

#Inputtextq{
  margin-left: 10px;
  width: 290px;
  border-top-right-radius: 0px;
  border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
 
.Move2{
  margin-left: 140px;
}

.shadow-lg{
  width: 320px;
  margin-left: 3%;
}

.bi.bi-quote{
  margin-left: 150px;
}



.bi.bi-trash3{
  color: black;
}


.rainbow{
  width: 10px;
  margin-left: 300px;
  margin-top: 30px;
  
}

.color1{ border-top-left-radius: 0px;}

}


 @media (max-width:1024px){
.bi-chat-quote-fill{
  margin:50px 50px 50px 50px;

}
  
 }



 
