
.headf{
    font-size: 40px;
}
.fotgotcontent{margin-top:-400px;
     margin-left: 700px;}
.hrf{
    width: 400px;
}
     #forgotor{
        margin-left: 200px;
     }

     #emailF{
        width: 400px;
     }

     .logf{
        margin-left: 100px;
     }
     #forgotbut{
        margin-left: 100px;
     }
@media (max-width:1210px){
    .Forgotimg{
        display: none;
    }
    .fotgotcontent{
        margin-left: 100px;
         margin-top:20px;
    }

    #forgotor{
        margin-left: 200px;
     }
   
    }

    @media (max-width:529px){
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 200px;
         }
       
        }

        
    @media (max-width:436px){
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 200px;
         }

   
    }

    @media (max-width:429px){
        .headf{font-size: 32px;}
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 170px;
         }

   #emailF{
    width: 345px;
   }

   #forgotbut{
    margin-left: 70px;
 }

 .logf{
    margin-left: 70px;
 }


 .hrf{
    width: 345px;
}
   
    }
 
  

    
        
    @media (max-width:350px){
        .headf{font-size: 26px;}
        .Forgotimg{
            display: none;
        }
        .fotgotcontent{
            margin-left: 2px;
             margin-top:20px;
        }
    
        #forgotor{
            margin-left: 130px;
         }

   #emailF{
    width: 270px;
   }

   #forgotbut{
    margin-left: 40px;
 }

 .logf{
    margin-left: 40px;
 }


 .hrf{
    width: 270px;
}
   
    }
 
  