
.row{
    margin-top: 5%;
    overflow-x: hidden;

}
.Movetile{
margin-left: 4%;
font-size: 15px;

}
.rowPosters{
    display: flex;
    margin-top:-6%;
    overflow-y:hidden;
    overflow-x: scroll;
    padding: 20px;
    padding-top: 100px;
}


.rowPoster{
    width: 100px;
    height: 150px;
    margin-right: 10px;
    transition: transform 450ms;
}

.rowPoster:hover {
    transform: scale(1.09);
}
@media screen and (max-width: 1024px) {
    .row{
        margin-top: 20%;
        overflow-y:hidden;

    }
    .rowPosters{
  
        padding-top: 22px;
    }
}