/* wrapper */
.HIW-body{
    max-width: 95%;
    margin: 4% 2%;
}
/* welcome page */
.HIW_welcome_page{
    margin-top: 10%;
    text-align: center;
  }
.HIW_welcome_page > h1{
    text-shadow: #242424;
    text-transform: uppercase;
    color: #ef4343;
    font-size: 70px;
    font-weight: bolder;
  }
  /* HIW components */
.intro_comp .HIW-nav-btn→{
    margin-left: 90%;
}
.home_comp{    
    text-align: left;
}
.home_comp > h1{
    text-transform: uppercase;
    color: #ef4343;
    font-size: 60px;
    font-weight: 800;
}
.nav_comp > h3,.intro_comp > h3,
.write_comp > h3,.browse_comp > h3,
.feature_comp > h3,.mother_comp > h3,
.home_comp > h3{
    font-weight: 700;
    color: black;
    text-align: left;
    margin-left: 5%;
}

#signImg{
    margin-left: 5%;
    width: 90%;
    height: 70vh;
    box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
    border-radius: 4px;
    margin-bottom: 1%;
}
#signImgb{
    margin-bottom: 1%;
    margin-left: 5%;
    height: 70vh;
    width: 30%;
    box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
    border-radius: 4px;
}
/* HIW Containers */
.HIW-heading{
    width: 100%;
    height: 50vh;
    text-align: center;
    padding: 4% ;
    margin: 1% 4;
    border-radius: 2px;
    
}
.HIW-content{
    overflow: scroll;
    width: 96%;
    height: 80vh;
    padding: 2%;
    box-shadow:0px 4px #ef4343;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2%;
    margin-left: 2%;
}
.HIW-content > h2{
    text-align: center;
    margin-left: 10%;
}
#HIW_list{
    display: list-item;
    list-style-type: none;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
}
.HIW-contentList #HIW-link{
    
    text-decoration: none;
}
#steps{
    margin-left: 5%;
    color:#ef4343;
    font-weight: 700;
}
/* Naviagtion buttons */
#next{
    color: #ef4343;
    text-decoration: none;
}
#back{
    color: #ef4343;
    text-decoration: none;
}
.HIW-nav-btn{
    position: fixed;
    bottom: -1%;
    margin-top: 2%;
    margin-bottom: 5.5%;
    display: flex;
    flex: 100%;
}
.HIW-nav-btn← {
    font-size:20px;
    position: fixed;
    left: 10%;
    border-color: #ef4343;
    color:#ef4343;
    border-radius: 20%;
    padding: 0 1%;
}
.HIW-nav-btn→ {
    font-size: 20px;
    position: fixed;
    right: 10%;
    border-color: #ef4343;
    border-radius: 20%;
    color:#ef4343;
    padding: 0 1%;
}
/* Links */
#HIW_links{
    color: #ef4343;
    text-decoration: none;
    font-size: 25px;
    
}
#HIW_links:hover{
    transform: scale(1.2);
    color: #ef4343;
}
/* =================================Media Screens===================================== */
@media screen and (max-width:1080px) {
    #HIW-mobile{
        display: none;
      }
      .HIW-content{
        
        width: 96%;
        height: 80vh;
      }

    .nav_comp > h3,.intro_comp > h3,
    .write_comp > h3,.browse_comp > h3,
    .feature_comp > h3,.mother_comp > h3,
    .home_comp > h3,#steps{
        font-size: 20px;
        margin-bottom: -1%;
    }
    /* popup welcome page */
    .HIW_welcome_page #h1{
        font-size: 25px;
    }
    .HIW_welcome_page #HIW_links{
        font-size: 20px;
    }

      .HIW-nav-btn{
        position: fixed;
        bottom: 8%;
        margin-top: 2%;
        display: flex;
        flex: 100%;
    }
    #signImgb{
        text-align: center;
        margin-left: 5%;
        width: 85%;
        height: 90vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
    #signImg{
        display: none;
    }
    .intro_comp .HIW-nav-btn→{
        margin-left: 80%;
    }
    .HIW_welcome_page > h1{
        text-shadow: #242424;
        text-transform: uppercase;
        color: #ef4343;
        font-size: 40px;
        font-weight: bolder;
      }
    .HIW-nav-btn→{
        right: 18%;
    }
    .HIW-nav-btn←{
        left: 18%;
    }

    .HIW-nav-btn→,.HIW-nav-btn← {
        font-size: 15px;
    }
  
}
@media screen and (max-width:720px) {
    #HIW-mobile{
        display: none;
    }
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 85%;
        height: 90vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:650px) {
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:480px) {
    #signImg{
        display: none;
    }
    #signImgb{
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }
}
@media screen and (max-width:360px) {
    #signImg{
        display: none;
    } 
    #signImgb{
        align-content: center;
        margin-left: 5%;
        width: 90%;
        height: 80vh;
        box-shadow: 1px 2px 6px 1px rgb(48, 45, 45);
        border-radius: 4px;
        margin-bottom: 1%;
    }  
}