/*----------------------------------------------------
@File: Default Styles
@Author: 
@URL: 
Author E-mail: 

This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
---------------------------------------------------- */
/*=====================================================================
@Template Name: 
@Author: 
@Developed By: 
@Developer URL: 
Author E-mail: 

@Default Styles

Table of Content:
01/ Variables
02/ predefine
03/ header
04/ banner
05/ about
06/ portfolio
07/ team
08/ blog
09/ service
10/ blog
11/ contact 
12/ footer 


=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/


/*Mixins*/
/* Placeholder Mixins*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}
.welcome{
  font-size: 70px;
  background-image: linear-gradient(
    -230deg,
    #fe3f09 0%,
    #fe3f09 29%,
    #ffcec1 67%,
    #f7d9d0 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 7s linear infinite;
 
      
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}

.become{
  font-size: 70px;
  margin-left: 30px;
  margin-top: -20px;
}
.become::first-letter{
  font-size: 100px;
  letter-spacing: -3px;

}

body {
  height: 100%;
  font-family: "Lato", sans-serif;
  color: white;
}

:focus {
  outline: none;
}

button:focus {
  outline: none;
}


.logwr{margin-left: 150px;}

.bawr{margin-left: 150px;}
.custom_height {
  height: 100%;
}

.content_right{
  margin-left:615px;
}

#passwr{
  margin-left: 100px;
  width: 350px;
}

.imgwr{margin-left: -115px;}

@media (max-width:1219px){
  .imgwr{Width: 600px;}

  .content_right{
    margin-left:420px;
    margin-top: -30px;
    width: 360px;
  }
  
.welcome{
  font-size: 55px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 110px;}

.bawr{margin-left: 110px;}
#passwr{
  margin-left: 60px;
}

}

@media (max-width:1024px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 55px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 110px;}

.bawr{margin-left: 110px;}
#passwr{
  margin-left: 60px;
}

}
@media (max-width:500px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 30px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: 30px;}

.bawr{margin-left: 30px;}
#passwr{
  margin-left: -20px;
}

}

@media (max-width:359px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 24px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: -10px;}

.bawr{margin-left: -10px;}
#passwr{
  margin-left: -20px;
  width: 310px
}

}

@media (max-width:300px){
  .imgwr{display:none;}

  .content_right{
    margin-left:0px;
    margin-top: -50px;
    width: 360px;
  }
  
.welcome{
  font-size: 20px;
}

.welcome::first-letter{
  font-size: 100px;
  letter-spacing: -18px;

}
.logwr{margin-left: -30px;}

.bawr{margin-left: -30px;}
#passwr{
  margin-left: -20px;
  width: 288px
}

}