.notcard{box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
         padding-left: 30px;
         padding-right: 30px;
         padding-bottom: 0.1px;
        margin-bottom: 15px;
         border-left-style: solid;
         border-left-width: 7px; 
         border-left-color: #ef4343;
         border-radius: 2px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;}

        .notmove{
            padding-left: 20px;
        }

.fields{
    margin-top: 5px;
}
.followBar{
    margin-top: -40px;
}
.AboutUserin {
    width: 50%;
    margin-left: 28%;
}

.FollowB.works{
    background-color: red;
    border-color: red;
    padding-top: 20px;
}

.FollowB.reading{
    background-color: black; 
    padding-top: 20px;
}

.FollowB.followers{
    background-color: green;
    border-color: green;
    padding-top: 20px;
}

.text-center{color:white;}

.FollowB{
    background-color: white; 
    border-style: solid;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    width: 200px;
    border-radius: 4px;
    border-color: black;

  }

  
.BoxMessage{
        
        margin-top: -15%;
        border-radius: 20px;
}
.MessageB{
 
    border-radius: 10px;
    height: 160px;
    padding-top: 0px; 
    /* background-color: red; */
}
.textMessage{
    margin-left: 12%;
}
.Fbutton{
    width: 5px;
}
.MessageBox{
    box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
    inset 0.1rem 0.1rem 0.3rem #868686;
    border-radius: 10px;
    height: 80px;
    padding-top: 20px;
}
.sendB{
  font-size: 22px;  
  margin-left: 10px;
  cursor: pointer;
}
.InputMessageBox{
 border-radius: 30px;
 width: 400px;
 margin-left: 12%;
 margin-top: 5%;
 height: 40px;
 box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
              inset 0.1rem 0.1rem 0.3rem #868686;
 
}
/* Avator styling  */
.avatar{

    border-width: 1px;
    border-color: gray;
    border-style: outset;
    margin-left: 45%;
    margin-top: -70px;

    }
    .avatarM{

        border-width: 1px;
        border-color: gray;
        border-style: outset;
        margin-left: 2%;
        margin-top: -8%;
    
        }    
    
.profileCover{   
    background-color: #f6f6f6;
background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
height: 150px;
}
label.filebutton {
    overflow:hidden;
    position:relative;
    margin-left: 55.4%;
  margin-top: 6%;
  z-index: 1000;


}

label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding:0;
}
.fa-camera{

    font-size: 40px;
   
}
.uploadImg{
 margin-left: 90%;
 margin-top: -300px;

}
.uploadBooks{
    margin-left: 90%;
    margin-top: -5px;
   cursor: pointer;
   }
.userN{
    margin-top: 30px;
    margin-left: 47%;
}

.btn.btn-primary.uploadImg{color:black;
                           background: white;
                            border-color: black;}

.followers:hover{
    cursor: pointer;
}

.customModal{
    box-shadow: 0px 5px 10px 0px rgb(82 80 80);
    border:0px;
    background: #121217
}

@media screen and (max-width: 1000px) {
    /* Avator styling  */
.avatar{
    margin-left: 30%;

    }
    .uploadImg{
        
        margin-left: 68%;
        width: 30%;
        margin-top: -60%;
        
       }
      


       .fa-camera{

        font-size: 30px;
    }
    .userN{
        margin-top: 30px;
        margin-left: 40%;
      
    }
    label.filebutton {
        overflow:hidden;
        position:relative;
        margin-left: 69.4%;
      margin-top: 23%;
     
    
    }
}




@media screen and (max-width: 1024px) {
    /* Avator styling  */
.avatar{
    margin-left: 23%;

    }
    .AboutUserin {
        width: 95%;
        margin-left: 2%;
    }
    .InputMessageBox{
        border-radius: 30px;
        width: 65%;
        margin-left: 21%;
        margin-top: 5%;
        height: 35px;
        box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
                     inset 0.1rem 0.1rem 0.3rem #868686;
        
       }
       .sendB{
        font-size: 17px;  
        margin-left: 3px;
        cursor: pointer;
      }
       .avatarM{

        border-width: 1px;
        border-color: gray;
        border-style: outset;
        margin-left: 2%;
        margin-top: -13%;
    
        }    
    
    .BoxMessage{
            width: 135%;
            margin-left: -15%;
            box-shadow: inset -0.1rem -0.1rem 0.3rem #fff,
            inset 0.1rem 0.1rem 0.3rem #868686;

    }

  
    .uploadImg{
        
        margin-left: 68%;
        width: 30%;
        margin-top: -48%;
        
       }
       .profileCover{
        background: rgb(2,0,36);
        background: grey;
        height: 150px;
    }
       .fa-camera{
           

        font-size: 30px;
    }
    .userN{
        margin-top: 10px;
        margin-left: 40%;
      
    }
    label.filebutton{
        position:relative;
        margin-left: 63.4%;
      margin-top: 23%;
     
    
    }
    .notstyle{
        width: 700px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-left:10px;
        padding-top: 10px;
    }
    .MessageBox{
        height: 120px;
        width: 115%;
        margin-left: -10%;

    }
    .textMessage{
        margin-left: 25%;
    }
    
}

@media only screen and (max-width: 1000px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 640px;
    }

    .notcard{
       width: 680px; 
    }
}

@media only screen and (max-width: 750px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 500px;
    }

    .notcard{
       width: 600px; 
    }
}
@media only screen and (max-width: 600px){
    #notes{
       margin-top: 10px;
       height: calc(var(--variable-height));
       width: 400px;
    }

    .notcard{
       width: 500px; 
    }
}

@media only screen and (max-width: 490px){
    #notes{
       margin-top: 10px;
       height: 130px;
       width: 300px;
    }

    .notcard{
       width: 400px; 
    }
}
   

@media only screen and (max-width: 360px){
    #notes{
       margin-top: 10px;
       height: 130px;
       width: 270px;
    }

    .notcard{
       width: 350px; 
    }
}
           

                   /* Extra small devices (phones, 320px and down) */
@media only screen and (min-width: 320px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
                     
        .notstyle{
            width: 315px; 
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            margin-left: 2px;
            padding-left:10px;
            padding-top: 10px;
}

.text-center{margin-left:-30px;
font-size: 14px;
margin-top:-5px;
padding-left: 5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 70px;
border-radius: 4px;
border-color: black;
}}

@media only screen and (min-width: 360px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
                     
    .notstyle{
        width: 355px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: 2px;
        padding-left:10px;
        padding-top: 10px;
}

.text-center{margin-left:-30px;
font-size: 14px;
margin-top:-5px;
padding-left: 5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 85px;
border-radius: 4px;
border-color: black;
}}

@media only screen and (min-width: 400px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }
    .notstyle{
        width: 390px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-left: 2px;
        padding-left:10px;
        padding-top: 10px;
}

.text-center{margin-left:-20px;
font-size: 14px;
margin-top:-5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 170px;
border-radius: 4px;
border-color: black;
}}


@media only screen and (device-width: 1024px) {

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }

    .uploadImg{
            margin-top: -265px;}

    .avatar{
        margin-left: 375px;
        margin-top: -250px;
        }
   


.text-center{margin-left:-20px;
font-size: 18px;
margin-top:-5px;
padding-top: -20px;}
.FollowB{
background-color: white; 
border-style: solid;
color: black;
text-decoration: none;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
height: 65px;
width: 280px;
border-radius: 4px;
border-color: black;
}}


@media only screen and (min-width:1280px){

    .profileCover{   
        background-color: #f6f6f6;
    background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%);
    height: 150px;
    }

    .notstyle{
        margin-left: 50px;
        width: 700px; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-left:10px;
        padding-top: 10px;
}
}