/*Cards-web*/
*{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.cards {
  text-decoration: none;
  text-align: center;
  list-style-type:none;
  align-self: center;
} 
.movebutton{
margin-left: 40%;
}
.herobuttonB{
  background: #ef4343;
  color: #fff;
  width: 130px;
  height: 40px;
  text-align: center;
  border-radius: 25px;
  border: 2px solid #ffff;
  margin:0 auto 60px;
  display: inline-flex;
  font-size: 13px;
  padding:9px 10px;
  box-shadow: 1px 2px 6px 1px grey;

  
}
.herobuttonB:hover{
 text-decoration: none;
 
}
.cards-box{
  
  box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
  padding: 40px;
  margin-top: 20px;
  width: 300x;
}
.userW{
  margin-left: 4%;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}
.premb{
  display: none;
}
@media screen and (max-width: 1024px) {
.premb{
  margin-top: 60px;
  display: flex;
  margin-left: 36%;
  background-color: #c7e9fb;
  background-image: #ef4343;  
  border-radius:20px;
}
}
.fontStyle{
  font-family: "Times New Roman", Times, serif;
 
 
}
h1{
  color: #ef4343;
  padding-top: 0px;
 
}
 li{
  display: inline-block;
  position: relative;
  text-align: center;


}

 ul {
  text-align: center;
	}

.cards__item__img{
  box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
  transition: 0.3s;
  max-width: 200px;
  border-radius: 8px;
  transition: all 0.2s linear;
  z-index: 999;
}
.cards__item__imgad{
  box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
  transition: 0.3s;
  max-width: 200px;
  border-radius: 8px;
  margin-right: 50%;
  margin-top: -9%;
  transition: all 0.2s linear;
  z-index: 999;
}
.cards__itemad {
  margin-top: -6%;
  margin-left: -10%;
  width: 120%;

}
.boxcoad{
  height: 300px;
  background-color: transparent;
  box-shadow: 1px 2px 6px 1px rgb(83, 82, 82);
}
.cards__item{
  padding: 100px;

}

label{
  color:#020202;
}
 /*check this out by nick t*/
.cards{
  color:#020202;
}
.cards__item__img:hover{
  transform: scale(1.1);
}
.data-category{
  text-decoration-style:none;
  margin: 0px 20px 0px 20px;
  border-radius: 18px;
  background-color: rgb(44, 37, 37);
  color: #ef4343;
  padding: 5px;
  margin-top: 10px;
}
.cards__item__info{
  justify-content: center;
  align-items: center;
 padding: 20px;
}
.cards__item__info .data-category:hover{
  text-decoration:none;
   background-color: #ef4343;
   color: ivory;
   transform:scale(1.1) ;
}

.cards__wrapper{
  align-content: center;
  display: block;
}
.AllPage{
  width: 100vw;
  overflow-x: hidden;
}


/*Cards*/
@media only screen and (max-width: 650px) {

   .cards {
    text-decoration: none;
    text-align: center;
    list-style-type:none;
    align-self: center;
    background: #fff;
  } 

  .movebutton{
    margin-left: 8%;
    }
   h1{
    padding-top: 0px;
    font-family:-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
  }
  
  .tab {
    padding: 30px 50px 30px 50px;
    position:static; 
    width: 1000px;
    border-radius: 10px;
    box-shadow: 0 8px 15px 0 #242222;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tab td{
    padding:5px ;
    border-style: none;
    text-decoration: none;
  }
  
  .cards__item__img{
    box-shadow: 0 8px 15px 0 rgb(15, 15, 15);
    transition: 0.3s;
    max-width: 200px;
    border-radius: 8px;
    transition: all 0.2s linear;
  }
  .cards__item{
    padding: 5px;
  }
  .cards__item__img:hover{
    transform: scale(1.1);
  }
  .data-category{
    text-decoration-style:none;
    margin: 0px 20px 0px 20px;
    border-radius: 18px;
    background-color: rgb(44, 37, 37);
    color: #ef4343;
    padding: 5px;
    margin-top: 10px;
  }
  .cards__item__info .data-category:hover{
    text-decoration:none;
     background-color: #ef4343;
     color: ivory;
     transform:scale(1.1) ;
  }
  
  .cards__wrapper{
    align-content: center;
  }
}

@media only screen and (max-width: 1024px) {

  .cards__itemad {
    margin-top: -95%;
  }
}