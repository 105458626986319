.CID-img{
    width: 100%;
    height: 60vh;
    /* margin-top: 5%; */
}
.CID-container{

    margin:0% 5%;
}
.fa {
    position: relative;
    font-size: 40px;
    margin-top:5% ;
}

.fa-chevron-circle-left{
    align-content: left;
    margin-left:-54.5% ;
    background:#ef4343;
    border-radius: 50px;
}
.fa-chevron-circle-left:hover{
    color: #fff;
    cursor: pointer;
    background:#ef4343;
    border-radius: 50px;

}
.fa-chevron-circle-right{
   margin-left: 51% ;
   background:#ef4343;
   border-radius: 50px;
}
.fa-chevron-circle-right:hover{
    color: #fff;
    cursor: pointer;
    background:#ef4343;
    border-radius: 50px;

}
/* media screens */
/* ===================================================================================================== */
@media screen and (max-width: 720px) {

    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
    }

    .fa {
        display: none;
}
}
@media screen and (max-width:650px) {
    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
    }

    .fa {
        display: none;
    }
}
/* ========================================================================================= */
@media screen and (max-width:480px) {
    .CID-img{
        width: 100%;
        height: 40vh;
        margin-top: 10%;
        padding: 2% 5%;
    }

    .fa {
        display: none;
    }
}
/* =========================================================================================== */
@media screen and (max-width:360px) {
    .CID-img{
        width: 100%;
        height: 30vh;
        margin-top: 20%;
    }

    .fa {
        display: none;
    }
}