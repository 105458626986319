


.button-5 {
  align-items: center;
  background-clip: padding-box;
  background-image: linear-gradient(315deg, #e9e9e9 0%, #f6f6f6 74%);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  border-style: solid;
  border-color: rgb(199, 197, 197);
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}


    .button {
        display: block;
        font-weight: 400;
        line-height: 1.5;
        color: black;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        background-color: rgb(223, 215, 215);
        border-style:solid;
        border-color: rgb(223, 215, 215);
        border-radius: 50%;
        padding: 0.2rem 0.4rem;
        margin-left: 900px;
        margin-top: 10px;
    }
    
  /* .bi-trash3{background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    margin-top:-250px;
     margin-left:-45px;
  } */

  .Move{
      margin-top:-180px;
     
      
    }

    .TextQuote{
   
      margin-left: 50px;
      margin-top: -300px;

    }
.card1{
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    float:right;
    margin-top: -55px;
width: 300px;
display: table-cell;
border-color: rgb(190, 190, 190);
  border-style: solid;
  border-top: none;
border-width: 1px;
}

small{
  font-weight: 5px;

}

 .Qnav{background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9  74%);
width: 1066px;
height: 110px;
border-bottom-color: rgb(190, 190, 190);
  border-bottom-style: solid;
border-bottom-width: 1px;}
  
.Qnavmove{
  display: inline-block;
  
}

.nav-link{Color: black;

}

.ItemQ{
  padding-left: 20px;
}

.figure-caption{position:absolute;
                margin-left: 314px;
                margin-top: -195px;
              text-shadow: 1px 1px black;
              
            }

           #txt{ font-size: 20px;
             color: white;
             word-wrap: break-word;
              max-width:500px;}
              

.imagx{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin-left:275px;
  margin-top: 15px;
  border-radius: 2px;
  border-color: red;
  width: 600px;
  height: 500px;
}

.footer1{
  margin-top: -5px;
  margin-left: 567px;
  background:rgba(0, 0, 0, .06);
   opacity:50%;
    width: 300px;
   color:white;
   
}
.btn.btn-dark.mt-2{margin-left:20%;}


@media (max-width:1265px){
 


  
  .figure{
    margin-left: -20.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}


  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
 
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
    margin-bottom: 5%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:700px){
 

  .figure{
    margin-left: -40.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
 

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
  }
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
    margin-bottom: 5%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:460px){
 

  .figure{
    margin-left: -61.5%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
 

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
  }
  #uploadbutton{
    margin-left: 9%;
    width: 200px;
  }
  #textinput{
    left: -20%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}


@media (max-width:400px){
 

  .imagx{width:340px;}
  .figure{
    margin-left: -71%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -5%;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}




@media (max-width:370px){


  .imagx{width:340px;}
  .figure{
    margin-left: -78%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:345px;
  text-align: center;
}

moment{align-items: center;}
  

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -15px;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
    height:30px;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
  }
  .imagx{
    width:345px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:354px){

  .figure{
    margin-left: -82%;

  }

.footer1{
  margin-left:288.5px;
  margin-top: 30px;
  width:310px;
  text-align: center;
}

moment{align-items: center;}

  .Move {
    margin-left: -33%;
    z-index: 10;
    margin-top: -15%;
  
    font-size: 10%;
    /* background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px; */
  }
  .card1{
    display: none;
  }
  .bi-hand-thumbs-up-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .icon {
    margin-left: -25%;
    margin-top: -15px;
  }
  #uploadbutton{
    margin-left: 9%;
    width:200px
  }
  #textinput{
    left: -20%;
    height:30px;
  }

  #txt{margin-top:-250px;
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
    word-wrap: break-word;
    text-align: center;
  }
  #text{
    left:70%;
   margin:-50%;
    height: 15%;
    margin-top: 15%;
  }
  #quote{
    display: none;
  }
  #pic{
 padding-top: 15px;
  margin: 1%;
  padding-left:4%;
  }
  .bi-hand-thumbs-down-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .bi-chat-quote-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
    display: none;
  }
  
  .bi-arrow-up-right-circle-fill{
    background-color: white;
    border-style:solid;
    border-color: white;
    border-radius: 50%;
    padding: 1px;
  }
  .button{
    z-index: 10;
    margin-top: -10px;
    margin-left: 10%;
  }
  .imagx{
    width:310px;
    height:290px;
    margin-left: 288.5px;
  }
 
}

@media (max-width:1024px) {
  #textinput{
   
    height: 120%;
}

#upload-btn{
  width:30%;
}
.icon {
  margin-left: 1%;
  margin-top: -1%;
}
.title {
  display: none;
}
/* #cancel{
  display: none;
} */
.figure-caption{
max-width: 200px;
margin-top:-270px;
left: -8%;
}
.bi-trash3{
  color:white;
}
.likes{
  color:yellow;
  margin:5px;
  font-size: 16px;

}
#uploadbtn{
 min-width: 40%;

}
#selected{
  margin-top: -10%;
  
}
#photoinput{
  height: 5%;
}
.label{
  display: none;
}
#sendbutton{
  margin-top: -5%;
  margin:3%;
}
.figure{
  margin-left: -136%;
}
.button{
  margin-left: 90%;
  
}
.Move{
  margin-top: -17%;
}

}
