/* Content container  */
.item-content{
    margin: 10% 2%;
    display:grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 15px;
    align-content: center;
    margin-left: 1%;
}
/* item box */
.item-content-box{
    width: 80%;
}
.content-img{
    width: 90%;
    margin:auto 5%;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 12px 14px 0px rgb(46, 43, 43);
}
.item-content-data{
    
    width: 120%;
    margin-bottom: 30px;
}
.btn-container{
    width: 100%;
}
.btn-container > button{
    background: #ef4343;
    width: 300px;
    padding: 10px 0;
    font-weight: 700;
}

/* Media Screen 1080px rbs */
@media screen and (max-width:1080px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: 10%;
    }
    .item-content-data{
    
        width: 200px;
        margin-bottom: 30px;
    }
    
}
/* Media Screen 720px  */
@media screen and (max-width:720px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: -10%;
    }
    .content-img{
        width: 80%;
        margin:auto 5%;
        border-radius: 8px;
        margin-bottom: 20px;
        box-shadow: 0px 12px 14px 0px rgb(46, 43, 43);
    }
    .item-content-data{
    
        width: 200px;
        margin-bottom: 30px;

    }
    
}
/* Media Screen 650 */
@media screen and (max-width:650px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: 15px;
        align-content: center;
        margin-left: -7%;
    }
    .item-content-data{
    
        width: 250px;
        margin-bottom: 30px;

    }
    
}
/* Media Screen 480px */
@media screen and (max-width:480px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        align-content: center;
        margin-left: 10%;
    }
    
}
/* Media Screen 360px */
@media screen and (max-width:360px){
    .item-content{
        display:grid;
        grid-template-columns: repeat(1,1fr);
        margin-top: 4%;
        align-content: center;
        margin-left: 6%;
    }
    
}