

.navp{
    transition: all 0.1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
   margin-top: 17%;
    z-index: 2000;
    box-shadow: 0px 5px 10px 0px rgb(82, 80, 80);
    width: 100%;
  }
  .AboutUser{
      margin-left: 20%;
      width: 60%;
  }
  .scrollNav {
    transition: all 0.5s ease-in;
    z-index: 2000;
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #dddddd;
  }
  .styl {
    padding-top: 80px;
  }
  .btn--outline{
    margin-top: -25px;
  }
  
  .prem{
    background-color: #c7e9fb;
    background-image: linear-gradient(315deg, #c7e9fb 0%, #ef4343 74%);  
    border-radius:20px;
  }
  /* Avator styling  */
  .avator{
  vertical-align: middle;
  width: 35px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-color: gray;
  border-style: outset;
  }
  #mobileNav{
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  }
  
  /* Avator styling  */
  .avator2{
    vertical-align: middle;
    width: 35px;
    height: 30px;
    border-radius: 50%;
    border-width: 2px;
    border-color: gray;
    border-style: outset;
    z-index: -999;
    }
    
  
  a.nav-links-mobile.sign{
    color:#020202;
  }
  .fa-bars{
    color: #020202;
  
   
  }
  .navbar-containerp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    max-width: auto;
    
  }
  
  
  .navbar-logo {
     color: #fff; 
    justify-self: start;
    margin-left: 20px;
  
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-itemp{
    font-size: 15px;
  
  }
  .nav-userp{
    font-size: 15px;
    margin-left: -20px;
    display: none;
  
  }
  .nav-userdp{
    font-size: 17px;
    margin-right: 200px;
    position:absolute;
    color: #ef4343;

   
  
  }
  .nav-logoutp{
    font-size: 17px;
    right: 20px;
    position:absolute;
    color: #ef4343;
   
  
  }
  a.nav-linksp{
    text-decoration: none;
    text-decoration-line:none;
    color:black;
  }
  .nav-linksp:hover  {
    text-decoration: none;
    color: #ef4343;
  
    
  }
  
  .nav-linksp:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0%;
    background: #ef4343;
    border-radius: 12px;
    transition: all 0.4s ease;
  }
  .nav-linksp:hover:before{
    width: 80%;
  }
  .nav-linksp:before{
    left: 10%;
    transform: translateX(-50%);
  }
  .nav-linksp:before{
    width: 100%;
    bottom: -5px;
    opacity: 0;
  }
  .nav-linksp:hover:before{
    bottom: 0px;
    opacity: 1;
  }
  .nav-linksp:before{
    width: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease;
  }
  .nav-linksp:hover:before{
    transform: scaleX(1);
    transform-origin: left;
  }
  .nav-menup {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 1rem;
  }
  
  .nav-linksp {
    color: #ef4343;
    font-weight:bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 135%;
  }
  
  .menu-iconp {
    display: none;
  }
  
  .nav-linksp{
    text-decoration: none;
    transition: all 0.2s ease-out;
  }
  svg.sc-bdvvtL.cmlAWX.feather.feather-moon {
    margin-top: -6.5px;
    height: 25px;
    width:25px;
  
  }
  
  svg.sc-bdvvtL.cmlAWX.feather.feather-sun {
    margin-top: -6.5px;
    height: 25px;
    width:25px;
   
  }
  
  
  .nav-links-mobilep{
    display: none;
    
  }
  
  
  .menu-iconp{
    display: none;
  }
  /* tesla fixed the movement of the side menu wen screens are changed in size */
 
  @media screen and (max-width: 1024px) {
    .navp {
      width: 100%;
      margin-top: 44%;

   
    
  }
  .nav-user{
    font-size: 15px;
    margin-inline: 10;
    margin-left: -20px;
    display: inline-block;
  }
  .nav-menu {
    display: inline-table;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    position: absolute;
    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .prem{
    display: none;
    background-color: #c7e9fb;
    background-image: linear-gradient(315deg, #c7e9fb 0%, #ef4343 74%);  
    border-radius:20px;
  }
  }
  
  
  /* tesla fixed the movement of the side menu wen screens are changed in size */
  @media screen and (max-width: 1080px) {
    .navp {
      width: 100%;
      margin-top: 64%;

    
  }
  
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: inline-table;
      flex-direction: column;
      width: 50%;
      height: 80vh;
      position: absolute;
      top: 0%;
      margin: 0;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #ffffff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
     
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      
    }
  
  
    
  
    .navbar-logo {
      position: absolute;
      top: 0;
      margin-top: -25px;
      margin-right: 80%;
      transform: translate(25%, 50%);
    }
  
    
    .menu-icon {
      display: block;
      position: absolute;
      top: -0px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      margin-top: -19px;
    }
  
    .fa-times {
       color: #fff; 
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display:ruby-base;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
    .nav-user{
      font-size: 15px;
      margin-inline: 10;
      margin-left: -20px;
      display: inline-block;
    }
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .nav-logout{
  display: none;
     
    
    }
    .AboutUser{
        margin-left: 0%;
    }
    
    @media only screen and (device-width: 1024px) {
        .navp{margin-top:200px;}}
  }
  
  