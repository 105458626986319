/* ALL ABOUT CSS */
/* ABOUT HEADING */
.about-main > h1{
    text-align: center;
    font-weight: 700;
    padding: 10% 0;
}
.about-container{
    margin: 0 5%;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 45%;
    width: 700px;
}
.A_para {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 20px;
    margin-left: 2%;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    /* color: rgb(247, 10, 128); */
}

/* IMAGES */
#img1{
    margin-top: -40%;
    margin-left: 1%;
    height: 40%;
    width: 35%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 40%;
    height: 20%;
    margin-left: 55%;
    margin-top: -25%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: rotate(-10deg);
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 40%;
    padding: 30px;
    margin-left: 1%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    font-family:Arial, Helvetica, sans-serif;
    line-height: 25px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    margin-top: 10%;
    margin-bottom: -15%;
}
.about-footer2{
    margin-left: 15%;
    display: flex;
    text-align: center;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 16%;
}

.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}

/* MEDIA SCREENS */
@media screen and (max-width:1080px)
{
   /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    margin-top: -2%;
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 25px;
    margin: 0 1%;
    font-size: 20px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 35px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    margin-left: 18%;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 12%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}
@media screen and (max-width:720px)
{
     /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    margin-top: -3%;
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 17px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    text-align: center;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 10%;
}
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}  
}
@media screen and (max-width:650px)
{
    /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    font-family:Arial, Helvetica, sans-serif;
    background-color: transparent;
    text-align: justify;
    word-spacing: 0px;
    line-height: 25px;
    font-size: 18px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 18px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{

    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 10%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}
@media screen and (max-width:480px) {
     /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 25px;
    margin: 0 2%;
    font-size: 18px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 25px;
    font-size: 18px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    margin-left: 0%;
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left:12%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
    
}
@media screen and (max-width:360px)
{
    /* ALL ABOUT CSS */
.about-container{
    font-family: inherit;
}
/* ABOUT HEADING */
.about-header > h1{
    text-align: center;
    background-color:#ef4343;
    font-weight: 700;
    padding: 10% 0;
}

.about-paragraph{
    text-overflow: ellipsis;
    margin-left: 0%;
    width: 100%;
}
.about-paragraph > p {
    text-align: justify;
    line-height: 35px;
    margin: 0 2%;
    font-size: 20px;
}
.about-paragraph > h3{
    padding: 2%;
    font-weight: 800;
    color: #ef4343;
}

/* IMAGES */
#img1{
    margin-top: 5%;
    margin-left: 2%;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
    border-radius: 8px;

}
#img2{
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 8px;
    transform: none;
    box-shadow: 0px 4px 8px 0px rgb(65, 59, 59);
}
/* GOALS BOX */
.about-goals{
    width: 95%;
    padding: 30px;
    margin-left: 2%;
    /* box-shadow: 0px 4px 8px 0px rgb(65, 59, 59); */
}
.about-goals > h3{
    font-weight: 800;
}
.goals-list > p{
    display: grid;
    line-height: 35px;
    font-size: 20px;
   
}
.about-footer{
    text-align: center;
    height: fit-content;
    width: 100%;
    background-color:#ef4343;
    margin-bottom: 0;
}
.about-footer2{
    display: flex;
}
.about-footer2 > #link{
    padding: 1% 0;
    margin-left: 16%;
}
.about-links{
    margin:0px 40px;
     
 } 
.about-footer > h6{
    padding-bottom: 1%;
    position: absolute;
    text-align: center;
    font-size: 13px;
}
}

