/* image upload box */
.imgUpd-box{
    text-align: center;
    margin: 0% 2%;
    width: 95%;
    padding: 2%;
    margin-top: 8%;
    border-radius: 4px;
}
/* close button */

/* upload button*/
#upload-btn{
    color: #fff;
    padding: 0% 3%;
    border-radius: 8px;
}
/* file container */
#file-label{
    background: #ef4343;
    overflow:hidden;
    position:relative;
    margin-top: 2%;
    padding: 1%;
    color: #fff;
    border-radius: 8px;
}
#file-label:hover{
    cursor: pointer;
    background-color: #fff;
    color: #000;
}
/* image icon */
.fa-image{
    font-size: 50px;
}
/* ordinary file input */
#file{
    display: none;
}
