
.pop{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pop-inner{
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 95%;
    height: 90vh;
    padding-bottom: 5%;
    border-radius: 8px;
    background-color: #fff ;
}
.pop-inner .closebtn{
    position:absolute;
    background-color: transparent;
    color: #9f1f4a;
    border: none;
    font-size:35px;
    top: -0.5%;
    right: 1%;
}

.pop-inner > img{
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.1;

}

/* ======Media Screen===== */
@media screen and (max-width:1080px) {
  .pop{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pop-inner{
    align-items: center;
    position: relative;
    width: 90%;
    height: 90vh;
    margin-bottom: 6%;
    border-radius: 8px;
    background-color: #fff ;
}

}
@media screen and (max-width:720px) {
  
}
@media screen and (max-width:650px) {
  
}
@media screen and (max-width:480px) {
  
}