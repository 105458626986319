.signup{
    margin-top: -15px;
     font-weight: bold;
      font-size: 22px;
       border-color: #EF4343;
        background: #EF4343; 
        height: 50px;
         width:393px;
          border-radius: 50px;
           margin-left: 25px;
}
  
  
  @media (max-width:991px){
    .img10{display:none;}
    .signup{
        width:330px;
        margin-left: 45px;
    }
 
  }
  
  