.dropMt {
  background: white;
    width: 400px;
    height: 140px;
    position: absolute;
    top: 60px;
   margin-left: -100px;
    list-style: none;
    border-radius: 15px;
    border-style: solid;
    border-color: #ef4343;
  
  }
  
  .dropMt li {
    width: 170px;
    top:25px;
    cursor: pointer;
    display: inline;
    
    
  
  
  }
/*   
.spacing{
margin: 10px;
} */
  
  .dropMt.clicked {
    display: none;
  }
  
  .dropLt {
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    padding: 16px;
    font-size: 16px;
  }
  .dropLt:hover {
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    padding: 16px;
    text-decoration: none;
    color: black;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
  