.dropM {
  width: 170px;
  position: absolute;
  top: 60px;
 margin-left: -70px;
  list-style: none;


}

.dropM li {
  background: white;
  width: 170px;
  cursor: pointer;
  text-align: left;
  border-style: solid;
  border-color: #ef4343;
  


}

.dropM li:hover {
  background: #ef4343;
  

}

.dropM.clicked {
  display: none;
}

.dropL {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: black;
  padding: 16px;
  font-size: 12px;
}
.dropL:hover {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
  text-decoration: none;
  font-size: 18px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
