.style2{
    margin-left: 750px;
    max-width: 410px;
     margin-top: 80px; 
}

#inter{
    width: 100px;

}

.nxt{
    width: 200px;
    margin-left: 100px;
}

 @media (max-width:1150px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 200px;
    }

    
  
 
  }

  @media (max-width:411px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 100px;
        margin-left: 0px;
    }

    
  
 
  }
  @media (max-width:397px){
    .img11{display:none;}

    .style2{
        margin-left:10px ;
    }

    .nxt{
        width: 100px;
        margin-left: 0px;
    }

  
 
  }
  
  