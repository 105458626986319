#map {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  @media (min-width: 768px) {
    .news-input {
      width: 50%;
    }
  }