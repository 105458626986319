.afriInv-container{
    margin: 0 20%;
    padding: 0 2%;
    margin-top: -5%;
}
.form-box{
    padding: 4% 4%;
    box-shadow: 1px 3px 6px 1px rgb(66, 65, 65);
}
.form-box h1{
    font-size: 50px;
    text-align: center;
}
.inv-btn{
    background-image: linear-gradient(45deg, #dd541e 0%, #ef4343 50%, #b21818 100%);
    color: #fff;
}
.inv-btn:hover{
    background-image: linear-gradient(45deg, #f88d63 0%, #fd6969 50%, #f70c0c 100%);
    color: #fff;
}

/* =================media Screens=================== */
@media screen and (max-width:1080px) {
    .afriInv-container{
        margin: 0 20%;
        padding: 0 2%;
        margin-top: -5%;
    }
    .form-box{
        padding: 4% 4%;
        box-shadow: 1px 3px 6px 1px rgb(66, 65, 65);
    }
    .form-box h1{
        font-size: 50px;
        text-align: center;
    }
}
@media screen and (max-width:820px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 40px;
    }
}
@media screen and (max-width:720px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 35px;
    }
}
@media screen and (max-width:650px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 35px;
    }
}
@media screen and (max-width:480px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 30px;
    }
}
@media screen and (max-width:360px) {
    .afriInv-container{
        margin: 0;
        padding: 0 2%;
        margin-top: 5%;
    }
    .form-box{
        padding:4% 0%;
        box-shadow: 0;
    }
    .form-box h1{
        font-size: 30px;
    }
}