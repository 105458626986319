
.phonead{
   margin-left: -70%;
}
.phonefeature{
    width: 70%;
    margin-left: 30%;
    margin-top: -40%;
    height: 500px;
}
.iconB{
    font-size: 50px;
}
.item-list{
    display: inline-block;
    padding:40px;
}
@media screen and (max-width: 1080px) {
    .phonead{
        margin-left: 0%;
     }
     .phonefeature{
        width: 100%;
        margin-left: 0%;
        height: 80%;
        margin-top: 10%;
    }
}