.writerform{
    width: 100%;
}
.container{
    margin-top: 50px;
}
.HideMe{
    display: none;

}

.DisplayMe{
    display: block;

}
.buttonMe {
    background:  #ef4343;  
   border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
     cursor: pointer;
     border-radius: 11px;
  }
  .buttonMe:hover {

    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }